/////////////////////
// Index Page
/////////////////////

// Basic Imports
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Design Imports
import "./styles/global.css";
import customTheme from "./styles/theme";

// Layout and Section Imports

// Data Imports

import { persistor, store } from "./app/store";

// Custom Hooks and Services
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { ChakraProvider } from "@chakra-ui/react";
import { ProjectProvider } from "context/project/ProjectProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from "redux-persist/integration/react";
import { ReactQueryDevtools } from "react-query/devtools";

// Interfaces

// Functions
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider theme={customTheme}>
              <AuthProvider>
                <ProjectProvider>
                  <Routes>
                    <Route path="*" element={<App />} />
                  </Routes>
                </ProjectProvider>
              </AuthProvider>
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

import { useQuery } from "react-query";
import { getAllOrders } from "services/orderService";
import useAuth from "./useAuth";
import { Order, OrderStatus } from "types/order.types";
import _ from "lodash";

type OrdersProps = { status?: OrderStatus };
const useOrders = (props?: OrdersProps) => {
  const { auth }: any = useAuth();
  const accessToken = auth.accessToken;
  const orderKeys = props?.status
    ? ["users", "orders", props.status]
    : ["users", "orders"];

  const ordersQuery = useQuery<Order[]>(
    orderKeys,
    () => getAllOrders({ accessToken, status: props?.status }),
    {
      select: (data) => _.orderBy(data, "createdAt", "desc"),
    }
  );

  return ordersQuery;
};

export default useOrders;

import { useQuery } from "react-query";
import useAuth from "./useAuth";
import { getOfferByProfileId } from "services/profilesService";

const useProfileOffer = ({ profileId }: { profileId: string }) => {
  const { auth }: any = useAuth();
  const accessToken = auth.accessToken;

  const profileOfferQuery = useQuery(
    ["profiles", profileId, "offers"],
    () => getOfferByProfileId(profileId, accessToken),
    {
      enabled: !!profileId,
    }
  );

  return profileOfferQuery;
};

export default useProfileOffer;

import {
  Center,
  Container,
  Flex,
  HStack,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { INotificationDataState } from "hooks/useSocket";
import { FC } from "react";
import AppLogo from "./AppLogo";
import { MobileHamburgerMenu } from "./MobileHamburgerMenu";
import { NavMenu } from "./NavMenu";
import NotificationMenu from "./NotificationMenu";
import { ProfileDropdown } from "./ProfileDropdown";
import { useMobileMenuState } from "./useMobileMenuState";
interface Props {
  onDeleteNotification: (id: string | string[]) => void;
  notifications: {
    notificationData: INotificationDataState;
    isConnected: boolean;
  };
}

const Header: FC<Props> = ({ notifications, onDeleteNotification }) => {
  return (
    <Center
      bg="blue.600"
      color={mode("gray.100", "gray.800")}
      px="6"
      minH="16"
      my={0}
    >
      <Flex
        display={{ base: "inherit", lg: "none" }}
        justify="space-between"
        w="full"
      >
        <MobileHeader
          onDeleteNotification={onDeleteNotification}
          notifications={notifications}
        />
      </Flex>
      <Container display={{ base: "none", lg: "inherit" }} maxW="7xl">
        <DesktopHeader
          onDeleteNotification={onDeleteNotification}
          notifications={notifications}
        />
      </Container>
    </Center>
  );
};

function DesktopHeader({ notifications, onDeleteNotification }) {
  return (
    <Flex w="full" justify="space-between">
      <HStack spacing={4} alignSelf="center">
        <AppLogo isBetaVersion />
        <NavMenu.Desktop />
      </HStack>

      <HStack spacing={{ base: 3, md: 6 }}>
        <NotificationMenu
          onDeleteNotification={onDeleteNotification}
          notifications={notifications}
        />
        <ProfileDropdown />
      </HStack>
    </Flex>
  );
}

function MobileHeader({ notifications, onDeleteNotification }) {
  const { isMenuOpen, toggle, onCloseModal } = useMobileMenuState();

  return (
    <>
      <>
        <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} onClickNavItem={onCloseModal} />
      </>

      <Flex alignSelf="center">
        <AppLogo isBetaVersion />
      </Flex>

      <HStack spacing={{ base: 3, md: 6 }}>
        <NotificationMenu
          onDeleteNotification={onDeleteNotification}
          notifications={notifications}
        />
        <ProfileDropdown />
      </HStack>
    </>
  );
}

export default Header;

import { FC } from "react";
import _ from "lodash";
import {
  Box,
  Flex,
  HStack,
  Progress,
  Skeleton,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import Ratings from "components/ui/common/Ratings";

interface RatingDistribution {
  value: number;
  count: number;
}
interface Props {
  isLoading: boolean;
  reviewsCount: number;
  averageRating: number;
  ratingDistributions: RatingDistribution[];
}

const RatingsProgressbar: FC<Props> = ({
  reviewsCount,
  isLoading,
  averageRating,
  ratingDistributions,
}) => {
  return (
    <Stack w="full" spacing={4}>
      <Wrap spacing={6}>
        <Skeleton isLoaded={!isLoading} h="24px">
          <Text fontWeight="medium">
            Total {reviewsCount} reviews for this Gig
          </Text>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} h="22px" alignItems="start">
          <Ratings
            rating={Number(averageRating.toFixed(1))}
            showDigits
            size="xs"
          />{" "}
        </Skeleton>
      </Wrap>
      <Flex gap={10} flexDir={{ base: "column", lg: "row" }}>
        <Stack w="full">
          {_.orderBy(ratingDistributions, "value", "desc").map((rating) => {
            const percentage = (rating.count / reviewsCount) * 100;
            return (
              <HStack key={rating.value} spacing={3}>
                <HStack width="10%">
                  <Text>{rating.value}</Text>
                  <Text>Stars</Text>
                </HStack>
                <Flex flex={1} align="center" gap={2}>
                  <Box w="full">
                    <Skeleton isLoaded={!isLoading}>
                      <Progress
                        rounded="md"
                        colorScheme="blue"
                        value={percentage}
                      />
                    </Skeleton>
                  </Box>
                  <Skeleton width="2.5rem" isLoaded={!isLoading}>
                    <Text fontSize="sm">{`(${rating.count})`}</Text>
                  </Skeleton>
                </Flex>
              </HStack>
            );
          })}
        </Stack>

        {/* <RatingsBreakdown /> */}
      </Flex>
    </Stack>
  );
};

export default RatingsProgressbar;

import { Box, Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";

export const CardBadge = (props: FlexProps) => {
  const { children, ...flexProps } = props;
  return (
    <Flex
      bg={useColorModeValue("blue.500", "blue.200")}
      position="absolute"
      right={-16}
      top={"14"}
      width="300px"
      transform="rotate(35deg)"
      py={1}
      justifyContent="center"
      alignItems="center"
      {...flexProps}
    >
      <Box
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color={useColorModeValue("white", "gray.800")}
      >
        {children}
      </Box>
    </Flex>
  );
};

import { FC } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInputField from "components/ui/common/TextInputField";
import TextareaField from "components/ui/common/TextareaField";
import useAccessToken from "hooks/useAccessToken";
import useOrderDeliveries from "hooks/useOrderDeliveries";
import CheckIcon from "icons/CheckIcon";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { createRevision } from "services/revisionService";
import * as yup from "yup";
import { orderKeys } from "settings/constants/queryKeys";
import useCustomToast from "hooks/useCustomToast";
import { Dropzone, FilePreview } from "hr-design-system";
import PlusIcon from "icons/PlusIcon";
import useGalleryForm from "hooks/useGalleryForm";

const REVISION_DESCRIPTION_LENGTH = 500;
const REVISION_TITLE_LENGTH = 100;

const orderDeliverySchema = yup.object({
  title: yup.string().required().label("Title"),
  deliveryId: yup.string().required().label("Delivery"),
  description: yup
    .string()
    .min(20)
    .max(REVISION_DESCRIPTION_LENGTH)
    .required()
    .label("Description"),
});
interface FormProps {
  title: string;
  description: string;
  deliveryId: string;
}
interface Props {
  orderId: string;
}
const RevisionForm: FC<Props> = ({ orderId }) => {
  const token = useAccessToken();
  const queryClient = useQueryClient();
  const { data: orderDeliveries } = useOrderDeliveries(orderId);
  const { errorToast } = useCustomToast();
  const {
    uploadedFiles,
    prevUploadedFiles,
    dropzoneDisclosure,
    handleFileUpload,
    handleSave: handleUploadAttachment,
    handleRemoveAttachment,
    isUploading,
  } = useGalleryForm({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormProps>({
    resolver: yupResolver(orderDeliverySchema),
  });
  const createRevisionMutation = useMutation({
    mutationFn: async (data: FormProps) => {
      const { finalAttachments } = await handleUploadAttachment();
      return createRevision(
        {
          orderId,
          deliveryId: data.deliveryId,
          title: data.title,
          customerRequest: {
            details: data.description,
            attachments: finalAttachments,
          },
        },
        token
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) }),
    onError: () => {
      errorToast({
        title: "Failed to mark order as complete.",
        description:
          "Something went wrong, please try again or contact support",
      });
    },
  });

  return (
    <Stack
      spacing={4}
      as="form"
      onSubmit={handleSubmit((data) => createRevisionMutation.mutate(data))}
    >
      <Stack spacing={0}>
        <TextInputField
          {...register("title")}
          label="Title"
          errorMessage={errors.title?.message}
          maxLength={REVISION_TITLE_LENGTH}
          currLength={watch("title")?.length}
        />
        <TextareaField
          {...register("description")}
          label="Description"
          errorMessage={errors.description?.message}
          maxLength={REVISION_DESCRIPTION_LENGTH}
          currLength={watch("description")?.length}
          minH={{ base: "15rem", md: "8rem" }}
        />
        <Stack alignItems="start" spacing={0} w="full" h="full">
          <FormLabel>Attachments</FormLabel>
          <SimpleGrid columns={{ base: 2, sm: 3, lg: 4 }} spacing={2} w="full">
            {[...prevUploadedFiles, ...uploadedFiles].map((file) => (
              <FilePreview
                key={file.id}
                styles={{
                  width: "full",
                  height: "32",
                }}
                fileName={file.name}
                allowClickToEnlarge={true}
                fileURL={file.url}
                fileType={file.format}
                allowRemove={true}
                allowDownload={false}
                onRemove={() => handleRemoveAttachment(file.id)}
              />
            ))}

            <Center
              gap={2}
              w="full"
              height="32"
              rounded="md"
              p={2}
              border="1px dashed #DADBDD"
              justifyContent="center"
              onClick={dropzoneDisclosure.onOpen}
            >
              <IconButton
                as={PlusIcon}
                aria-label="Upload media"
                colorScheme="blue"
                rounded="full"
              />
            </Center>
          </SimpleGrid>
        </Stack>
        <FormControl>
          <FormLabel>Delivery</FormLabel>
          <Select {...register("deliveryId")}>
            {orderDeliveries.map((delivery) => (
              <option key={delivery._id} value={delivery._id}>
                {delivery.title}
              </option>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Button
        variant="outline"
        colorScheme="red"
        rightIcon={<CheckIcon />}
        size="sm"
        w="max-content"
        alignSelf="flex-end"
        isLoading={isUploading || createRevisionMutation.isLoading}
        type="submit"
      >
        Submit revision
      </Button>

      {dropzoneDisclosure.isOpen && (
        <Dropzone
          wrapInModal={true}
          allowMultiple
          onUpload={() => {}}
          onClose={dropzoneDisclosure.onClose}
          onFileUpload={handleFileUpload}
        />
      )}
    </Stack>
  );
};

export default RevisionForm;

/////////////////////
// ListItem UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  Circle,
  Flex,
  Heading,
  HStack,
  Image,
  SquareProps,
  Stack,
  StackProps,
  Tag,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
export interface ListItemProps extends StackProps {
  title: string;
  titleTag?: string;
  subTitle: string;
  icon?: React.ReactElement;
  isLastItem?: boolean;
  circleStyle?: SquareProps;
  imgUrl?: string;
  iconVsImg?: "icon" | "img";
  // chatPerson?: "me" | "2d renderer";
}

// Functions
export const ListItem = (props: ListItemProps) => {
  const {
    title,
    titleTag,
    subTitle,
    icon,
    isLastItem,
    children,
    circleStyle,
    imgUrl,
    iconVsImg,
    ...stackProps
    // chatPerson = "me",
  } = props;

  return (
    <Stack as="li" direction="row" spacing="4" {...stackProps}>
      <Flex direction="column" alignItems="center" aria-hidden="true">
        <Circle
          size="14"
          borderWidth="4px"
          borderColor={mode("white", "gray.800")}
          bg={mode("blue.500", "blue.300")}
          color={mode("white", "black")}
          overflow="hidden"
          objectFit="cover"
          {...circleStyle}
        >
          {iconVsImg === "img" ? (
            <Image src={imgUrl} w="full" h="full" objectFit="cover" />
          ) : (
            icon
          )}
        </Circle>
        {!isLastItem && <Flex flex="1" borderRightWidth="2px" mb="-12" />}
      </Flex>
      <Stack spacing="4" pt="1" flex="1">
        <Flex direction="column">
          <HStack>
            <Heading fontSize="md" fontWeight="semibold">
              {" "}
              {title}{" "}
            </Heading>
            {titleTag && (
              <Tag
                as="span"
                size="sm"
                textTransform="uppercase"
                colorScheme={titleTag !== "me" ? "green" : "gray"}
              >
                {titleTag}
              </Tag>
            )}
          </HStack>
          {/* </Heading> */}
          <Text fontSize="sm" color={mode("gray.500", "gray.600")}>
            {subTitle}
          </Text>
        </Flex>
        <Flex>{children}</Flex>
      </Stack>
    </Stack>
  );
};

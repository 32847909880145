import { HStack, Skeleton, SkeletonCircle, Stack } from "@chakra-ui/react";

const MessagesLoading = () => {
  return (
    <Stack spacing={{ base: 6, md: 8 }}>
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
      <MessageItemSkeleton />
    </Stack>
  );
};

const MessageItemSkeleton = () => {
  return (
    <HStack align="start">
      <SkeletonCircle size="14" />
      <Skeleton w="full" h="6rem" />
    </HStack>
  );
};

export default MessagesLoading;

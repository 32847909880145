/////////////////////
// Verify Page
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Design Imports
import { Container, Progress, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";

import FormHeader from "components/forms/AuthForm/FormHeader";
import { verification } from "services/authService";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import Alert from "components/features/Alert";
import { ROUTES } from "settings/constants/routes";
import useCustomToast from "hooks/useCustomToast";

// Data Imports

// Interfaces

// Custom Hooks and Services

// Functions
const VerifyPage = () => {
  const { token } = useParams();
  const [verifyState, setVerifyState] = useState({
    isLoading: false,
    isUserVerified: false,
  });
  const { successToast } = useCustomToast();
  const navigate = useNavigate();

  useEffect(() => {
    getAsyncData();
    async function getAsyncData() {
      setVerifyState({ ...verifyState, isLoading: true });

      const { response, error } = await verification({ token });

      if (!error && !!response) {
        setTimeout(() => {
          setVerifyState({
            ...verifyState,
            isLoading: false,
            isUserVerified: true,
          });
          successToast({
            title: SUCCESS.ACCOUNT_VERIFIED,
          });

          navigate(ROUTES.LOGIN_IN, { replace: true });
        }, 3000);

        return;
      }

      setVerifyState({ ...verifyState, isLoading: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!!verifyState.isLoading)
    return (
      <AuthLayout>
        <Stack>
          <FormHeader
            title="Verifying your account"
            description="Redirecting..."
          />
          <Progress size="xs" isIndeterminate />
        </Stack>
      </AuthLayout>
    );

  return (
    <AuthLayout>
      <Container>
        <Alert
          status={"error"}
          title={ERRORS.GENERIC}
          showCloseIcon={false}
          link={{
            label: "Try again ?",
            href: ROUTES.ACCOUNT_VERIFY,
          }}
        />
      </Container>
    </AuthLayout>
  );
};

export default VerifyPage;

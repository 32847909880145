import { Tooltip, TooltipProps } from "@chakra-ui/react";
import { FC } from "react";

interface Props extends TooltipProps {}
const ButtonTooltip: FC<Props> = ({ children, ...restProps }) => {
  return (
    <Tooltip gutter={16} hasArrow placement="top" {...restProps}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default ButtonTooltip;

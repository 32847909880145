/////////////////////
// Login Page
/////////////////////

// Layout and Section Imports
import { LoginForm } from "../../../components/forms/AuthForm/LoginForm";
import AuthLayout from "components/layouts/AuthLayout";

const LoginPage = () => {
  return (
    <AuthLayout>
      <LoginForm
        // px={{ base: "4", md: "8" }}
        py={{ base: "12", md: "0" }}
        width="full"
        maxW="md"
      />
    </AuthLayout>
  );
};

export default LoginPage;

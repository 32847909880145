interface IReactQueryKeys {
  ALL_PROJECTS: string;
  DRAFT_PROJECTS: string;
  ACTIVE_PROJECTS: string;
  COMPLETED_PROJECTS: string;
  DELETED_PROJECTS: string;
  PAUSED_PROJECTS: string;
}
export const REACT_QUERY_KEYS: IReactQueryKeys = {
  ALL_PROJECTS: "allProjects",
  DRAFT_PROJECTS: "draftProjects",
  ACTIVE_PROJECTS: "activeProjects",
  COMPLETED_PROJECTS: "completedProjects",
  DELETED_PROJECTS: "deletedProjects",
  PAUSED_PROJECTS: "pausedProjects",
};

// useOrder
export const orderKeys = (id: string) => ["orders", id];

// useOrderMessages
export const orderMessagesKeys = (orderId: string) => [
  "orders",
  orderId,
  "messages",
];

// for custom hook useOfferAttachments
export const offerAttachmentsKey = (offerId: string) => [
  "attachments",
  "offer",
  offerId,
];

// useOrderDeliveries
export const orderDeliveryKeys = (id: string) => ["orders", id, "deliveries"];

// useOrderRatings
export const orderRatings = (orderId: string) => ["orders", orderId, "ratings"];

// useAttachmentByIds
export const attachmentKeys = (ids: string[]) => ["attachments", ids];

// useOrderRevisions
export const orderRevisions = (orderId: string) => [
  "orders",
  orderId,
  "revisions",
];

// useOfferReviews
export const offerReviewsKeys = (offerId: string) => [
  "offers",
  offerId,
  "reviews",
];

// useOfferRequirements
export const offerRequirementsKeys = (offerId: string) => [
  "offer",
  offerId,
  "requirements",
];

// useOfferFaqs
export const offerFaqsKeys = (offerId: string) => ["offers", offerId, "faqs"];

// useCategories
export const categoriesKeys = () => ["categories"];

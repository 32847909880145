import { Box } from "@chakra-ui/react";
import React, { ForwardRefRenderFunction } from "react";
interface MessagesWrapperProps {
  handleScroll: () => void;
  children: React.ReactNode;
}

const MessagesWrapper: ForwardRefRenderFunction<
  HTMLDivElement,
  MessagesWrapperProps
> = (props, ref) => {
  return (
    <Box position="relative" height="70vh">
      <Box
        maxHeight="70vh"
        overflowY="auto"
        onScroll={props.handleScroll}
        ref={ref}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default React.forwardRef(MessagesWrapper);

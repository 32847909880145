import axios from "api/axios";
import { API_URL } from "settings/site-credentials";
import { Revision } from "types/revision.types";

export async function createRevision(
  body: Partial<Revision>,
  accessToken: string
): Promise<Revision> {
  const response = await axios.post(`${API_URL}/revisions`, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.revision;
}

export async function getOrderRevisions(
  orderId: string,
  accessToken: string
): Promise<Revision[]> {
  const response = await axios.get(`${API_URL}/orders/${orderId}/revisions`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.revisions;
}

import { FC, ReactNode } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import Alert from "components/features/Alert";
import useOfferFaqs from "hooks/useOfferFaqs";

interface Props {
  offerId: string;
}

const FAQSection: FC<Props> = ({ offerId }) => {
  const { data: faqs, isLoading } = useOfferFaqs(offerId);

  if (!!isLoading)
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  if (!faqs)
    return (
      <Layout>
        <Alert
          status="error"
          description="Something went wrong, please reload the page or contact support"
        />
      </Layout>
    );
  if (faqs?.length === 0)
    return (
      <Layout>
        <Alert status="warning" description="This offer doesn't have any FAQ" />
      </Layout>
    );
  return (
    <Layout>
      <Accordion allowMultiple>
        <Stack spacing={1}>
          {faqs?.map((faq) => (
            <AccordionItem key={faq.id}>
              <h2>
                <AccordionButton>
                  <Box fontWeight="medium" as="span" flex="1" textAlign="left">
                    {faq.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>{faq.answer}</AccordionPanel>
            </AccordionItem>
          ))}
        </Stack>
      </Accordion>
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack as="section">
      <Text as="h2" fontWeight="bold" fontSize="xl">
        FAQ
      </Text>

      {children}
    </Stack>
  );
};

const Loading = () => {
  return (
    <Stack spacing={1} w="full">
      <Skeleton w="full" h="2.5rem" />
      <Skeleton w="full" h="2.5rem" />
      <Skeleton w="full" h="2.5rem" />
      <Skeleton w="full" h="2.5rem" />
    </Stack>
  );
};

export default FAQSection;

import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FC } from "react";
import { IoSearch } from "react-icons/io5";
import { MdClose } from "react-icons/md";

interface Props {
  value: string;
  onChangeValue: (value: string) => void;
  onClearValue: () => void;
}
const SearchInputField: FC<Props> = ({
  value,
  onClearValue,
  onChangeValue,
}) => {
  return (
    <InputGroup w="full">
      <InputLeftElement pointerEvents="none">
        <IoSearch size="18" />
      </InputLeftElement>
      <Input
        placeholder="Search offers"
        type="text"
        value={value}
        onChange={(e) => onChangeValue(e.currentTarget.value)}
      />
      <InputRightElement cursor="pointer">
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="Clear search text"
          icon={<MdClose size="20" />}
          onClick={onClearValue}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInputField;

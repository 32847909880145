/////////////////////
// AuthProvider Page
/////////////////////

// Basic Imports
import { createContext, useState } from "react";

// Design Imports

// Layout and Section Imports

// Data Imports
import {
  getCustomerTokenFromLocalStorage,
  getCustomerEmailIntoLocalStorage,
} from "services/authService";

// Custom Hooks
import useFetchAuthUser from "hooks/useFetchAuthUser";

// Interfaces

// Functions
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState<{ accessToken?: string }>({
    accessToken: getCustomerTokenFromLocalStorage()
      ? getCustomerTokenFromLocalStorage()
      : "",
  });
  const [email, setEmail] = useState<{ userEmail?: string }>({
    userEmail: getCustomerEmailIntoLocalStorage()
      ? getCustomerEmailIntoLocalStorage()
      : "",
  });

  const { user, isLoading, isError, setUser } = useFetchAuthUser(
    auth.accessToken
  );

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        userLoading: isLoading,
        userError: isError,
        email,
        setEmail,
        token: auth.accessToken,
        userEmail: email.userEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

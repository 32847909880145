/////////////////////
// Verify Page
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";

// Design Imports
import { Container, Progress, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";

import FormHeader from "components/forms/AuthForm/FormHeader";
import useAuthForm from "components/forms/AuthForm/useAuthForm";
import Alert from "components/features/Alert";
import { ERRORS } from "settings/constants/toastMessages";

// Data Imports

// Interfaces

// Custom Hooks and Services

// Functions
const SendVerificationEmail = () => {
  const { handleSendVerificationEmail } = useAuthForm();
  const [status, setStatus] = useState(null);

  const resetState = () => setStatus(null);

  useEffect(() => {
    getAsyncData();
    async function getAsyncData() {
      const { status } = await handleSendVerificationEmail();
      setStatus(status);
    }

    return () => {
      resetState();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAlert = (status) => {
    if (status === 200)
      return {
        status: "success",
        title: "Email has been sent successfully",
        link: {},
      };

    return {
      status: "error",
      title: ERRORS.GENERIC,
      link: {
        label: "Try login",
        href: "/login",
      },
    };
  };

  if (!!status)
    return (
      <AuthLayout>
        <Container>
          <Alert
            status={renderAlert(status).status as any}
            title={renderAlert(status).title as any}
            showCloseIcon={false}
            link={renderAlert(status).link as any}
          />
        </Container>
      </AuthLayout>
    );

  return (
    <AuthLayout>
      <Stack>
        <FormHeader
          title="You will receive link in your registered email"
          description="Sending email..."
        />
        <Progress size="xs" isIndeterminate />
      </Stack>
    </AuthLayout>
  );
};

export default SendVerificationEmail;

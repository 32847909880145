import { Category } from "types/gigCategory";

/**
 * Separates the "Other" category and ensures it's placed at the end of the list.
 * @param {Category[]} categories - The list of categories to process.
 * @returns {Category[]} - The formatted list of categories.
 */
export const formatCategoriesWithOtherAtEnd = (categories: Category[]) => {
  const otherCategory = categories.find(
    (category) => category.name === "Other"
  );
  const filteredCategories = categories.filter(
    (category) => category.name !== "Other"
  );

  return otherCategory
    ? [...filteredCategories, otherCategory]
    : filteredCategories;
};

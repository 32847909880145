import { Skeleton } from "@chakra-ui/react";

const PricingDetailsLoading = () => {
  return (
    <>
      <Skeleton w="full" h="2rem" />
      <Skeleton w="full" h="4.5rem" />
      <Skeleton w="full" h="2rem" />
      <Skeleton w="full" h="4rem" />
      <Skeleton w="full" h="3rem" />
    </>
  );
};

export default PricingDetailsLoading;

/////////////////////
// Require Auth Layout
// Anything with this layout require login.
/////////////////////

// Basic Imports
import useAuth from "hooks/useAuth";

import { useLocation, Navigate, Outlet } from "react-router-dom";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const RequireAuthLayout = () => {
  // @ts-ignore
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth?.accessToken)
    return <Navigate to="/login" state={{ from: location }} replace />;
  return <Outlet />;
};

export default RequireAuthLayout;

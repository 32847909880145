import { useQuery } from "react-query";
import { getMultipleAttachmentsByIds } from "services/attachmentsService";
import { attachmentKeys } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useAttachmentsByIds = (ids: string[]) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: attachmentKeys(ids),
    queryFn: () => getMultipleAttachmentsByIds(ids, accessToken),
    enabled: !!accessToken,
  });
};

export default useAttachmentsByIds;

import { FC, useState } from "react";
import { Center, Flex, Icon, Text } from "@chakra-ui/react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

interface Props {
  rating: number;
  direction?: "horizontal" | "vertical";
  showDigits?: boolean;
  totalCount?: number;
  onRatingChange?: (rating: number) => void;
  size?: "xs" | "sm" | "md" | "lg"; // Size options
  readOnly?: boolean;
}

const sizeMapping = {
  xs: "16px",
  sm: "20px",
  md: "24px",
  lg: "32px",
};

const Ratings: FC<Props> = ({
  rating: initialRating,
  direction = "horizontal",
  showDigits,
  totalCount,
  onRatingChange,
  size = "sm", // Default size value
  readOnly = true,
}) => {
  const [rating, setRating] = useState<number>(initialRating);
  const [hoverRating, setHoverRating] = useState<number | null>(null);
  const totalStars = 5;

  const handleClick = (index: number) => {
    setRating(index + 1);
    if (onRatingChange) {
      onRatingChange(index + 1);
    }
  };

  const handleMouseEnter = (index: number) => {
    setHoverRating(index + 1);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const renderStar = (index: number) => {
    const currentRating = hoverRating || rating;
    const boxSize = sizeMapping[size];
    if (currentRating >= index + 1) {
      return (
        <Icon
          as={FaStar}
          boxSize={boxSize}
          color="orange.500"
          justifySelf={"center"}
        />
      );
    } else if (currentRating > index && currentRating < index + 1) {
      return <Icon as={FaStarHalfAlt} boxSize={boxSize} color="orange.500" />;
    } else {
      return <Icon as={FaRegStar} boxSize={boxSize} color="orange.500" />;
    }
  };

  return (
    <Flex
      direction={direction === "horizontal" ? "row" : "column"}
      align="center"
      gap={0}
      userSelect="none"
    >
      {Array.from({ length: totalStars }, (_, index) => (
        <Center
          key={index}
          onClick={() => !readOnly && handleClick(index)}
          onMouseEnter={() => !readOnly && handleMouseEnter(index)}
          onMouseLeave={() => !readOnly && handleMouseLeave()}
          style={{ cursor: readOnly ? "inherit" : "pointer" }}
        >
          {renderStar(index)}
        </Center>
      ))}
      {(showDigits || totalCount) && (
        <Flex
          ml="2"
          fontSize="sm"
          gap={2}
          align="center"
          justifyContent="center"
        >
          {showDigits && (
            <Text
              fontSize={sizeMapping[size]}
              fontWeight="bold"
              color="orange.500"
            >
              {rating.toFixed(1)}
            </Text>
          )}
          {totalCount && <Text>{`(${totalCount})`}</Text>}
        </Flex>
      )}
    </Flex>
  );
};

export default Ratings;

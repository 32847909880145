import { Card, CardBody, Stack } from "@chakra-ui/react";
import { FC } from "react";
import ListMessages from "./ListMessages";
import SendMessageSection from "./SendMessageSection";

interface Props {
  orderId: string;
}
const ChatSection: FC<Props> = ({ orderId }) => {
  return (
    <Card variant="outline" w="full">
      <CardBody>
        <Stack spacing={{ base: 2, md: 4 }}>
          <ListMessages orderId={orderId} />

          <SendMessageSection orderId={orderId} />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ChatSection;

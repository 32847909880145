/////////////////////
// Project Info Card
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  BoxProps,
  Divider,
  Text,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Textarea,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports

// Interfaces
interface IProjectInfoCard {
  data: any;
  styles: BoxProps;
  projectId?: string;
  readOnly?: boolean;
}

// Functions
const ProjectInfoCard: FC<IProjectInfoCard> = ({
  data,
  styles,
  projectId,
  readOnly = false,
}) => {
  return (
    <Card id={projectId} {...styles}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <CardContent>
          <CardHeader title="Project Information" action={<></>} />
          <Stack rounded="lg" w="full" mt={4} spacing={4}>
            <InputGroup size="sm">
              <InputLeftAddon children="Project:" minW="150px" />
              <Input
                name="Name"
                autoComplete="off"
                placeholder="i.e. 2D Floorplan"
                bg={mode("white", "gray.700")}
                roundedBottom="0"
                disabled={readOnly}
                value={data.name || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon
                children="Description:"
                minH="75px"
                minW="150px"
              />
              <Textarea
                bg={mode("white", "gray.700")}
                minH="75px"
                placeholder="Anything you want to tell us about the project. (i.e. 2 story single family home)"
                disabled={readOnly}
                value={data.description || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon
                children="Emails:"
                minH="75px"
                minW="150px"
              />
              <Textarea
                bg={mode("white", "gray.700")}
                minH="75px"
                placeholder="Other email(s) that you want to email for notification aside from default account email."
                disabled={readOnly}
                value={data.emails || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="Address Line 1:" minW="150px" />
              <Input
                name="ProjectAddress1"
                placeholder=""
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                disabled={readOnly}
                value={data.addressLine1 || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="Address Line 2:" minW="150px" />
              <Input
                name="ProjectAddress2"
                autoComplete="off"
                placeholder="i.e. Unit 1234"
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                disabled={readOnly}
                value={data.addressLine2 || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="Country:" minW="150px" />
              <Input
                name="Country"
                autoComplete="off"
                placeholder="i.e. USA"
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                disabled={readOnly}
                value={data.country || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="City:" minW="150px" />
              <Input
                name="City"
                autoComplete="off"
                placeholder={
                  data.country === "US" ? "i.e. Washington" : "i.e. Vancouver"
                }
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                disabled={readOnly}
                value={data.city || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="State or Province" minW="150px" />
              <Input
                name="ProvinceState"
                autoComplete="off"
                placeholder={data.country === "US" ? "i.e. D.C" : "i.e. BC"}
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                disabled={readOnly}
                value={data.state || ""}
              />
            </InputGroup>
            <InputGroup size="sm">
              <InputLeftAddon children="ZIP or Postal Code" minW="150px" />
              <Input
                name="PostalZipCode"
                autoComplete="off"
                placeholder={
                  data.country === "US" ? "i.e. 12345" : "i.e. V6B 3Y8"
                }
                bg={mode("white", "gray.700")}
                fontSize="md"
                roundedBottom="0"
                type="number"
                disabled={readOnly}
                value={data.postalCode || ""}
              />
            </InputGroup>
          </Stack>
          <Divider m={5} />
          <HStack>
            <Stack>
              <Text>Bedrooms</Text>
              <Input
                step={1}
                min={0}
                bg={mode("white", "gray.700")}
                disabled={readOnly}
                value={data.bedrooms || ""}
              />
            </Stack>
            <Stack>
              <Text>Bathrooms</Text>
              <Input
                step={1}
                min={0}
                bg={mode("white", "gray.700")}
                disabled={readOnly}
                value={data.bathrooms || ""}
              />
            </Stack>
            <Stack>
              <Text>Stories</Text>
              <Input
                type="number"
                min={0}
                bg={mode("white", "gray.700")}
                disabled={readOnly}
                value={data.stories || ""}
              />
            </Stack>
            <Stack>
              <Text>Size (in sq ft) </Text>
              <Input
                type="number"
                step="any"
                name="SizeSqFt"
                bg={mode("white", "gray.700")}
                fontSize="md"
                disabled={readOnly}
                value={data.size || ""}
              />
            </Stack>
            <Stack>
              <Text>Width (in ft) </Text>
              <Input
                type="number"
                step="any"
                name="WidthInFt"
                bg={mode("white", "gray.700")}
                fontSize="md"
                disabled={readOnly}
                value={data.with || ""}
              />
            </Stack>
            <Stack>
              <Text>Depth (in ft) </Text>
              <Input
                type="number"
                step="any"
                name="DepthInFt"
                bg={mode("white", "gray.700")}
                fontSize="md"
                disabled={readOnly}
                value={data.depth || ""}
              />
            </Stack>
          </HStack>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default ProjectInfoCard;

import { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";

interface Props {
  title?: string;
}
const OfferCardTitle: FC<Props> = ({ title }) => {
  if (!title) return null;
  return (
    <Flex gap="4" align="start">
      <Box
        as="h2"
        noOfLines={2}
        h="3rem"
        fontWeight="500"
        color="black"
        fontSize="md"
      >
        {title}
      </Box>
    </Flex>
  );
};

export default OfferCardTitle;

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightElement,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { ForwardRefRenderFunction, ReactNode } from "react";
import { IconType } from "react-icons/lib";

interface Props extends InputProps {
  label: string;
  name: string;
  errorMessage?: string;
  currLength?: number;
  isDisabled?: boolean;
  hidden?: boolean;
  LeftAddon?: IconType | ReactNode;
  RightAddon?: IconType | ReactNode;
  LeftElement?: React.ReactNode;
  RightElement?: React.ReactNode;
}
const TextInputField: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    label,
    name,
    errorMessage,
    currLength,
    isDisabled,
    hidden = false,
    LeftAddon,
    RightAddon,
    LeftElement,
    RightElement,
    size,
    ...restProps
  },
  ref
) => {
  return (
    <FormControl
      hidden={hidden}
      isInvalid={!!errorMessage}
      isDisabled={isDisabled}
    >
      {label && <FormLabel fontSize={size}>{label}</FormLabel>}
      <InputGroup size={size}>
        {LeftAddon && (
          <InputLeftAddon>
            {typeof LeftAddon === "function" ? (
              <Icon as={LeftAddon} />
            ) : (
              LeftAddon
            )}
          </InputLeftAddon>
        )}
        {LeftElement && (
          <InputLeftElement pointerEvents="none">
            {LeftElement}
          </InputLeftElement>
        )}
        <Input
          ref={ref}
          name={name}
          size={size}
          {...restProps}
          autoComplete="off"
        />
        {RightAddon && (
          <InputRightAddon>
            {typeof RightAddon === "function" ? (
              <Icon as={RightAddon} />
            ) : (
              RightAddon
            )}
          </InputRightAddon>
        )}
        {RightElement && (
          <InputRightElement pointerEvents="none">
            {RightElement}
          </InputRightElement>
        )}
      </InputGroup>

      <SimpleGrid
        columns={{ base: 1, md: restProps.maxLength ? 2 : 1 }}
        spacing={{ base: 1, md: 2 }}
        fontSize={size}
      >
        <GridItem colSpan={1}>
          <FormErrorMessage w="full">{errorMessage}</FormErrorMessage>
        </GridItem>
        {restProps.maxLength && (
          <GridItem colSpan={1} display="flex" justifyContent="flex-end">
            <FormHelperText justifySelf="flex-end">
              ({currLength ? `${currLength}/` : ""}
              {restProps.maxLength}) characters max.
            </FormHelperText>
          </GridItem>
        )}
      </SimpleGrid>
    </FormControl>
  );
};
export default React.forwardRef(TextInputField);

/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { useState, useEffect, FC } from "react";

// Design Imports
import { HStack, Icon, Text } from "@chakra-ui/react";

import { FaFileDownload } from "react-icons/fa";

// Layout and Section Imports
// import ButtonCheckboxApp from "components/ui/ButtonCheckbox";
import ButtonCheckboxApp from "components/ui/ButtonCheckbox";

interface Props {
  title: string;
  data?: any;
  onSelect?: (data?: any) => void;
  defaultValues?: any[];
  readOnly?: boolean;
}
const RenderOutputFile: FC<Props> = ({
  title,
  data,
  onSelect,
  defaultValues,
  readOnly,
}) => {
  const [selected, setSelected] = useState([]);

  /**
   * 1. It will not go ahead if it is readOnly
   * 2. If not readOnly, it will pass value into onSelect and update project wizard.
   */
  useEffect(() => {
    if (!!readOnly) return;
    onSelect(selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <HStack>
      <Icon as={FaFileDownload} color="gray.500" />
      <Text fontWeight="semibold">{title}</Text>
      <ButtonCheckboxApp
        readOnly={readOnly}
        options={data}
        defaultValue={defaultValues}
        onChange={(value: string[]) => setSelected(value)}
      />
    </HStack>
  );
};

export default RenderOutputFile;

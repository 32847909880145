import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchAssetsByProjectId } from "services/assetService";
import useAuth from "./useAuth";

const useFetchAssets = () => {
  const { token }: any = useAuth();
  const { id: projectId } = useParams();

  const { data, ...restQueryResponse } = useQuery(["assets", projectId], () =>
    fetchAssetsByProjectId(projectId, token)
  );

  return {
    assets: data?.assets || [],
    ...restQueryResponse,
  };
};

export default useFetchAssets;

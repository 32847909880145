import React, { FC } from "react";
import { Box, Stack } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}
const PricingCardLayout: FC<Props> = ({ children }) => {
  return (
    <Box
      w={{ md: "24rem", sm: "full" }}
      h="max"
      border={"2px"}
      borderColor="gray.200"
      rounded="md"
      p={4}
    >
      <Stack spacing={6}>{children}</Stack>
    </Box>
  );
};

export default PricingCardLayout;

import { FC } from "react";
import { Circle, Icon } from "@chakra-ui/react";
import {
  FaCheck,
  FaClipboardCheck,
  FaQuestion,
  FaRegFile,
  FaRepeat,
  FaRocket,
  FaStar,
} from "react-icons/fa6";
import { GrDeliver } from "react-icons/gr";
import { OrderActivityAction } from "types/order.types";

interface Props {
  action: OrderActivityAction;
}
const ActivityIcons: FC<Props> = ({ action }) => {
  if (action === "order_placed")
    return (
      <Circle bg="blue.100" p={3}>
        <Icon color="blue.700" bg="blue.100" as={FaRegFile} />
      </Circle>
    );

  if (action === "order_started")
    return (
      <Circle bg="green.100" p={3}>
        <Icon color="green.700" as={FaRocket} />
      </Circle>
    );

  if (action === "delivery_updated")
    return (
      <Circle bg="pink.100" p={3}>
        <Icon color="pink.700" as={GrDeliver} />
      </Circle>
    );

  if (action === "delivery_created")
    return (
      <Circle bg="pink.100" p={3}>
        <Icon color="pink.700" as={GrDeliver} />
      </Circle>
    );

  if (action === "order_completed")
    return (
      <Circle bg="purple.100" p={3}>
        <Icon color="purple.700" as={FaClipboardCheck} />
      </Circle>
    );

  if (action === "talent_ratings" || action === "customer_ratings")
    return (
      <Circle bg="orange.100" p={3}>
        <Icon color="orange.700" as={FaStar} />
      </Circle>
    );

  if (action === "revision_requested")
    return (
      <Circle bg="red.200" p={3}>
        <Icon color="red.900" as={FaRepeat} />
      </Circle>
    );

  if (action === "revision_approved")
    return (
      <Circle bg="red.200" p={3}>
        <Icon color="red.900" as={FaCheck} />
      </Circle>
    );

  return (
    <Circle bg="red.50" p={3}>
      <Icon color="red.700" as={FaQuestion} />
    </Circle>
  );
};

export default ActivityIcons;

/////////////////////
// User Profile and Authentication Services
/////////////////////

// Basic Imports
import axios from "api/axios";
import {
  API_URL,
  CUSTOMER_EMAIL,
  CUSTOMER_HC,
  CUSTOMER_TOKEN_NAME,
  PERSIST_ROOT,
  PROJECT_WIZARD_DATA,
} from "settings/site-credentials";
import store from "store-js";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export async function getUser(token: string) {
  try {
    const { data } = await axios.get(`/users/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { user: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function resetPassword({
  token,
  newPassword,
  confirmPassword,
}: {
  token: string;
  newPassword: string;
  confirmPassword: string;
}) {
  try {
    const { data } = await axios.post(`/auth/password/reset/${token}`, {
      newPassword,
      confirmPassword,
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function authUserServices(user) {
  const { email, password } = user;
  try {
    const { data } = await axios.post(`/auth/login`, {
      email,
      password,
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function logoutUserServices() {
  store.remove(CUSTOMER_EMAIL);
  store.remove(PROJECT_WIZARD_DATA);
  store.remove(CUSTOMER_HC);
  store.remove(CUSTOMER_TOKEN_NAME);
  store.remove(PERSIST_ROOT);
}

export async function signupUserServices(user) {
  const { firstName, lastName, email, password, confirmPassword } = user;

  try {
    const { data } = await axios.post("/auth/signup", {
      fullName: `${firstName} ${lastName}`,
      email,
      password,
      confirmPassword: confirmPassword,
    });
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function updateUserProfile(body, token) {
  const reqBody = {
    firstName: body.firstName,
    lastName: body.lastName,
    // email: body.email,
    profile: {
      company: body.company,
      city: body.city,
      avatar: body.avatar,
      country: body.country,
      province: body.province,
      postalCode: body.postalCode,
      addressLine1: body.addressLine1,
      addressLine2: body.addressLine2,
    },
  };

  try {
    const response = await axios.patch("/users/account", reqBody, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data: response.data, status: response.data.success };
  } catch (err) {
    return { error: "Something went wrong..." };
  }
}

export async function updateCommunicationService(token, { projects }) {
  try {
    const response = await axios.patch(
      "/users/account",
      {
        preferences: {
          notifications: {
            projects,
          },
        },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data: response.data, status: response.data.success };
  } catch (err) {
    console.log({ err });
    return { error: "Something went wrong..." };
  }
}

export async function updateEmailService(
  { currEmail, newEmail, password },
  token
) {
  try {
    const { data } = await axios.post(
      `/users/email/change`,
      {
        currentEmail: currEmail,
        newEmail,
        password,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export async function forgotPasswordUserServices(user) {
  const { email } = user;

  try {
    const { data } = await axios.post("/auth/password/reset", { email });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function editProfile(user) {
  try {
    const { data } = await axios.put(
      `${API_URL}/users/account`,
      {
        fullName: user.fullName,
        phone: user.phone,
        addressLine1: user.addressLine1,
        addressLine2: user.addressLine2,
        city: user.city,
        province: user.province,
        postalCode: user.postalCode,
        country: user.country,
        company: user.company,
      },
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

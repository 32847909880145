/////////////////////
// Stage Cards (Dashboard)
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { SimpleGrid, Text } from "@chakra-ui/react";

import { StageCard } from "components/ui/common/StageCard";
import {
  FaGlasses,
  FaHourglassHalf,
  FaPause,
  FaRegCheckSquare,
} from "react-icons/fa";
import { IconType } from "react-icons";
import { getProjectLink } from "utils/getProjectLink";
import Alert from "components/features/Alert";
import LinkableText from "components/features/LinkableText";
import { ROUTES } from "settings/constants/routes";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  reviewProjects: any[];
  activeProjects: any[];
  completedProjects: any[];
  pausedProjects: any[];
  isLoading: boolean;
}

// Functions

const DashboardStageCards: FC<Props> = ({
  isLoading,
  reviewProjects,
  activeProjects,
  completedProjects,
  pausedProjects,
}) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="6">
      <RenderStageCard
        icon={FaGlasses}
        accentColor="red.500"
        label="Requires Your Review"
        isLoading={isLoading}
        data={reviewProjects}
        additionalProjectsLink={ROUTES.PROJECTS_PAUSED}
      />
      <RenderStageCard
        icon={FaHourglassHalf}
        accentColor="blue.500"
        label="Work In Progress"
        isLoading={isLoading}
        data={activeProjects}
        additionalProjectsLink={ROUTES.PROJECTS_ACTIVE}
      />
      <RenderStageCard
        icon={FaRegCheckSquare}
        accentColor="green.500"
        label="Completed Projects"
        isLoading={isLoading}
        data={completedProjects}
        additionalProjectsLink={ROUTES.PROJECTS_COMPLETED}
      />
      <RenderStageCard
        icon={FaPause}
        accentColor="gray.500"
        label="Paused Projects"
        isLoading={isLoading}
        data={pausedProjects}
        additionalProjectsLink={ROUTES.PROJECTS_PAUSED}
      />
    </SimpleGrid>
  );
};

interface RenderStageCardProps {
  isLoading: boolean;
  data: any[];
  accentColor: string;
  icon: IconType;
  label: string;
  additionalProjectsLink?: string;
}
function RenderStageCard({
  isLoading,
  data,
  accentColor,
  icon,
  label,
  additionalProjectsLink,
}: RenderStageCardProps) {
  return (
    <StageCard
      icon={icon}
      accentColor={accentColor}
      label={label}
      isLoading={isLoading}
      additionalProjectsLink={additionalProjectsLink}
    >
      {data.length === 0 && !isLoading ? (
        <Alert
          showCloseIcon={false}
          my="0"
          status="warning"
          title="Nothing to show!"
          size="sm"
        />
      ) : (
        <>
          {data.map((project) => (
            <LinkableText
              key={project.id}
              to={getProjectLink(project.state, project.id)}
            >
              <Text fontSize={{ base: "sm", md: "md" }} noOfLines={1}>
                {project.name}
              </Text>
            </LinkableText>
          ))}
        </>
      )}
    </StageCard>
  );
}

export default DashboardStageCards;

import { FC } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { OrderActivity } from "types/order.types";
import ActivityAccordion from "../RenderActivity/ActivityAccordion";
import useOrderRevisions from "hooks/useOrderRevisions";
import Alert from "components/features/Alert";
import ActivityAttachments from "../DeliveryActivity/ActivityAttachments";

interface Props {
  activity: OrderActivity;
  orderId: string;
}
const RevisionActivity: FC<Props> = ({ activity, orderId }) => {
  return (
    <ActivityAccordion activity={activity}>
      <ActivityContent orderId={orderId} resourceId={activity.resourceId} />
    </ActivityAccordion>
  );
};

interface ActivityContentProps extends Pick<OrderActivity, "resourceId"> {
  orderId: string;
}
const ActivityContent = ({ orderId, resourceId }: ActivityContentProps) => {
  const { data: orderRevisions, isLoading } = useOrderRevisions(orderId);
  const revision = orderRevisions?.find(
    (revision) => revision._id === resourceId
  );

  if (!!isLoading) return <Skeleton w="full" h="40" />;
  if (!revision)
    return (
      <Alert
        status="error"
        description="Revision not found! Try hard reload or contact support"
      />
    );
  return (
    <Stack>
      <Card variant="outline" size="sm" overflow="hidden">
        <CardHeader bg="gray.100" py={2}>
          <Text fontWeight="bold">{revision.title}</Text>
        </CardHeader>
        <CardBody>
          <Stack align="start" spacing={4}>
            <Stack spacing={2}>
              <Text>{revision.customerRequest.details}</Text>
            </Stack>
            <ActivityAttachments
              attachmentIds={revision.customerRequest.attachments}
            />
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default RevisionActivity;

// Basic Imports

// Design Imports
import {
  Box,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  // Wrap,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface Props {
  title: string;
  Button?: ReactNode;
  children: ReactNode;
}

// Functions
const SectionWrapper: FC<Props> = ({ title, Button, children }) => {
  return (
    <Box
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      padding="10"
      borderRadius="lg"
      border="1px solid"
      borderColor="#e2e8f0"
      p={{ base: "4", md: "6" }}
    >
      <Stack spacing="5" divider={<StackDivider />}>
        <Stack
          justify="space-between"
          direction={{ base: "column", sm: "row" }}
          spacing="5"
        >
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              {title}
            </Text>
          </Stack>
        </Stack>
        {children}

        {Button && Button}
      </Stack>
    </Box>
  );
};

export default SectionWrapper;

/////////////////////
// PricingCard Component
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  BoxProps,
  Flex,
  AccordionItem,
  ListItem,
  Accordion,
  Text,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  ListIcon,
  List,
  useBreakpointValue,
  useColorModeValue as mode,
  Badge,
} from "@chakra-ui/react";
import _ from "lodash";

// Layout and Section Imports

// Data Imports

// Services Imports
import { FaCheckCircle } from "react-icons/fa";
import { LIVE_FEATURES } from "settings/site-credentials";

// Interfaces
interface PricingCardProps extends BoxProps {
  data: {
    name: string;
    description: string;
    price: number;
    amountSaved?: number;
    duration: "monthly" | "yearly";
    benefits: string[];
    popular: boolean;
    save: number;
  };
}

// Functions
// const BillingBadge = (props: BoxProps) => (
//   <Box
//     rounded="full"
//     fontSize="sm"
//     bg="white"
//     color="gray.900"
//     px="3"
//     py="1"
//     pos="absolute"
//     top="4"
//     right="4"
//     fontWeight="bold"
//     {...props}
//   />
// );

// Functions

const PopularBadge = (props: BoxProps) => (
  <Box
    whiteSpace="nowrap"
    top="-5"
    left="52%"
    transform="translateX(-50%)"
    pos="absolute"
    rounded="lg"
    fontWeight="bold"
    fontSize="sm"
    px="4"
    py="1"
    textTransform="uppercase"
    bg="blue.600"
    color="white"
    {...props}
  />
);
const SaveBadge = (props: BoxProps) => (
  <Box
    whiteSpace="nowrap"
    top="0px"
    left="15%"
    transform="translateX(-50%)"
    pos="absolute"
    rounded="lg"
    fontWeight="bold"
    fontSize="sm"
    px="4"
    py="1"
    textTransform="uppercase"
    color="white"
    {...props}
  />
);

export const PricingCard = (props: PricingCardProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { data, ...rest } = props;
  const { name, save, popular, description, price, duration, benefits } = data;
  const isFree = price === 0;
  const isSaving = save !== 0;

  console.log({ isSaving });

  return (
    <Flex
      direction="column"
      px={isMobile ? "1" : "6"}
      py={isMobile ? "1" : "8"}
      rounded="lg"
      pos="relative"
      maxW="xl"
      mx="auto"
      minH={{ lg: "250px", base: "200px" }}
      {...rest}
    >
      {popular && <PopularBadge>Most Popular</PopularBadge>}
      <Box
        flex="1"
        className="kk"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {isSaving && (
          <SaveBadge>
            <Badge colorScheme="green">Save {save}%</Badge>
          </SaveBadge>
        )}
        <Text align="center" fontSize="2xl" fontWeight="bold">
          {_.startCase(name)}
        </Text>
        {!LIVE_FEATURES && (
          <Text
            align="center"
            mt="2"
            color={mode("gray.600", "gray.400")}
            maxW="16rem"
            mx="auto"
          >
            {description}
          </Text>
        )}
        <Flex align="center" mt="4" mb="3" justify="center">
          <Text
            fontSize={{ base: "2xl", xl: "3xl" }}
            fontWeight="extrabold"
            lineHeight="1"
            color="gray.600"
            flexShrink={0}
            textAlign="center"
          >
            {price}
          </Text>
          {!isFree && LIVE_FEATURES && (
            <>
              <Box mx={isMobile ? "0" : "4"}>
                <Box w="2px" h="12" opacity={0.5} transform="rotate(15deg)" />
              </Box>
              <Text
                fontSize={{ base: "xm", xl: "lg" }}
                lineHeight="1.2"
                color="gray.600"
              >
                / {_.replace(duration, "ly", "")}
              </Text>
            </>
          )}
        </Flex>
        <Box>{isFree ? <>&nbsp;</> : null}</Box>
        {LIVE_FEATURES && (
          <Box mt={isMobile ? "2" : "6"}>
            <Text
              fontSize={{ base: "sm", xl: "xl" }}
              fontWeight="semibold"
              mb="6"
              color="gray.600"
            >
              {description}
            </Text>
            <Accordion allowMultiple borderColor="white">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Plan Features
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel pb={4} pl={0} pr={0}>
                  <List
                    spacing={3}
                    textAlign="start"
                    fontSize={{ base: "sm", xl: "md" }}
                    wordBreak={{ base: "break-word", xl: "unset" }}
                  >
                    {benefits.map((item, index) => (
                      <ListItem key={index} display="flex" alignItems="start">
                        <ListIcon mt={1} as={FaCheckCircle} color="green.500" />
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

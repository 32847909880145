import {
  VStack,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  Image,
  Stack,
} from "@chakra-ui/react";
import { FC } from "react";
import { RiGalleryFill } from "react-icons/ri";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Attachment } from "types/attachments.types";

const GallerySection: FC<{ gallery: Attachment[] }> = ({ gallery }) => (
  <Stack spacing={5} py={10}>
    {gallery.length > 0 ? (
      <>
        <Text fontSize="2xl" fontWeight="bold" color="black">
          My gallery
        </Text>

        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="20px">
            {gallery.map((item, idx) => (
              <Box
                key={item.cdnUrl}
                borderRadius="lg"
                overflow="hidden"
                pos="relative"
              >
                <Image
                  src={item.cdnUrl}
                  alt={item.name}
                  width="100%"
                  borderRadius="lg"
                />
              </Box>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </>
    ) : (
      <Alert
        status="info"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        bg="gray.200"
        rounded="md"
      >
        <VStack>
          <RiGalleryFill fontSize="40px" />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            No gallery found
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            There is no gallery in this profile
          </AlertDescription>
        </VStack>
      </Alert>
    )}
  </Stack>
);

export default GallerySection;

import { VStack, Text, HStack, Tag } from "@chakra-ui/react";
import { FC } from "react";
import { Profile } from "types/profile.types";

const Expertise: FC<{ defaultProfile: Profile }> = ({ defaultProfile }) => (
  <VStack align="start">
    <Text fontWeight="bold" color="gray.500" fontSize="sm">
      Expertise
    </Text>
    <HStack spacing={4}>
      {defaultProfile.expertise.map((label) => (
        <Tag
          size="md"
          key={label.name}
          variant="solid"
          bg="blue.100"
          color="blue.500"
          fontWeight="bold"
          px={5}
        >
          {label.name}
        </Tag>
      ))}
    </HStack>
  </VStack>
);

export default Expertise;

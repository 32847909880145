import { useQuery } from "react-query";
import { GigPricesResponse, getGigPrices } from "services/gigsService";
import useAuth from "./useAuth";

const useGigPrices = (gigId: string) => {
  const { auth }: any = useAuth();
  const accessToken = auth.accessToken;
  const pricesQuery = useQuery<GigPricesResponse>(
    ["gig", gigId, "prices"],
    () => getGigPrices(gigId, accessToken),
    {
      enabled: !!gigId,
    }
  );

  return pricesQuery;
};

export default useGigPrices;

export const discounts = {
  monthly: 0,
  yearly: 20,
};

export const pricingDataServices = [
  {
    isPopular: false,
    name: "Starter Plan",
    description:
      "Need a little help? Outsource your 2D design and marketing assets",
    price: {
      monthly: 499,
      yearly: 0,
    },
    duration: "monthly",
    features: [
      "2D Floor Plans",
      "Blueprint Edits",
      "Drafting Services",
      "Landscaping Plans",
      "Marketing Brochures",
      "Feature Sheets",
    ],
  },
  {
    isPopular: true,
    name: "Professional Plan",
    description:
      "2D and 3D design to cover Single Family Residential real estate projects.",
    price: {
      monthly: 999,
      yearly: 0,
    },
    duration: "monthly",
    features: [
      "Everything from Starter Plan",
      "3D Floor Plans",
      "3D Interior Renderings",
      "3D Exterior Renderings",
      "3D Modelling",
      "Virtual Staging",
    ],
  },
  {
    isPopular: false,
    name: "Business Plan",
    description:
      "Supplement your business, we offer more with ability to do bigger real estate projects.",
    price: {
      monthly: 1999,
      yearly: 0,
    },
    duration: "monthly",
    features: [
      "Everything from Pro Plan",
      "Multifamily Buildings",
      "Commercial",
      "Land Development",
    ],
  },
];

export const pricingFeatures = [
  "3D Floor Plans",
  "3D Interior Renderings",
  "3D Exterior Renderings",
  "3D Modeling",
  "Virtual Staging",
  "2D Floor Plans",
  "Blueprint Edits",
  "Drafting Services",
  "Landscaping Plans",
  "Brochures",
  "Feature Sheets",
];

export const pricingDescription = [
  "Need a little help? Outsource your 2D design and marketing assets",
  "2D and 3D design to cover Single Family Residential real estate projects.",
  "Supplement your business, we offer more with ability to do bigger real estate projects.",
];

export const priceSaving = [0, 8.4, 16.6];

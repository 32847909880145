import { useQuery } from "react-query";
import { getOfferFaqs } from "services/offerService";
import { offerFaqsKeys } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useOfferFaqs = (offerId: string) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: offerFaqsKeys(offerId),
    queryFn: () => getOfferFaqs(offerId, accessToken),
    refetchOnWindowFocus: false,
  });
};

export default useOfferFaqs;

import { MotionBox, MotionBoxProps } from "./MotionBox";

export const Circle = (props: MotionBoxProps) => (
  <MotionBox
    bg="white"
    boxShadow="md"
    width="18px"
    height="18px"
    rounded="full"
    {...props}
  />
);

import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { Offer } from "types/offer.types";
import { Profile } from "types/profile.types";

export type AllProfilesResponse = { success: boolean; profiles: Profile[] };
export async function getAllProfiles(
  accessToken: string,
  searchText: string = ""
): Promise<AllProfilesResponse> {
  try {
    const url = searchText
      ? `${API_URL}/profiles?search=${encodeURIComponent(searchText)}`
      : `${API_URL}/profiles`;

    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, profiles: data.data.profiles };
  } catch (error) {
    console.log("Error while fetching gigs :: ", { error });
    return { success: false, profiles: [] };
  }
}

export type ProfileByIdResponse = { success: boolean; profile: Profile | null };
export async function getProfileById(
  id: string,
  accessToken: string
): Promise<ProfileByIdResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/profiles/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, profile: data.data.profile };
  } catch (error) {
    return { success: false, profile: null };
  }
}

//Get offers by profileId
export async function getOfferByProfileId(
  id: string,
  accessToken: string
): Promise<Offer[]> {
  const { data } = await axios.get(`${API_URL}/profiles/${id}/offers`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return data.data.offers;
}

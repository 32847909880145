/////////////////////
// useFetchAuthUser Hook
/////////////////////

// Basic Imports
// import { useEffect, useState } from "react";
// import { useState } from "react";

// Design Imports

// Layout and Section Imports

// Data Imports
import { useQuery, useQueryClient } from "react-query";
import { handleLogout } from "services/authService";
import { getUser } from "services/userProfileService";
import convertHoursIntoMilliSeconds from "utils/covertHoursIntoMilliSeconds";
import useCustomToast from "./useCustomToast";

// Custom Hooks and Services

// Interfaces

// Functions
const useFetchAuthUser = (authToken: string) => {
  const { errorToast } = useCustomToast();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, isError } = useQuery(
    ["authUser", authToken],
    async () => {
      const { user, error } = await getUser(authToken || "");
      if (!!error) throw new Error("auth_error");

      return user;
    },
    {
      // keepPreviousData: true,
      initialData: null,
      enabled: !!authToken,
      refetchOnWindowFocus: false,
      refetchInterval: convertHoursIntoMilliSeconds(24),
      onSuccess: () => {
        // successToast({ title: "Reauthorized successfully!" });
      },
      onError: async (err: Error) => {
        if (err.message === "auth_error") {
          errorToast({
            title: "Authorization failed.",
            description: "Failed to fetch your account details.",
          });

          await handleLogout();
        }
      },
    }
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    user: data?.user || null,
    setUser: (data) =>
      queryClient.setQueryData(["authUser", authToken], () => {
        return { user: data };
      }),
  };
};

export default useFetchAuthUser;

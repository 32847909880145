///////////////////////////////
// Billing Page
// Rev history
//  2022.04.25 - Updated file comment template
///////////////////////////////

// Basic Imports
import { useState, useEffect, useMemo } from "react";
import uniqid from "uniqid";

// Design Imports
import { Box, Button, Stack } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import CurrentPlanInfo from "./CurrentPlanInfo";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import SectionWrapper from "./SectionWrapper";
import useAuth from "hooks/useAuth";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import {
  changePlanPrice,
  changePlanType,
  setSelectedPlanId,
} from "app/planReducer";

// import { useDispatch } from "react-redux";

// Eric: Hiding currency switch
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { CurrencySwitch } from "pages/PlanPage/CurrencySwitch";

import { RadioPricePage } from "pages/PlanPage/RadioPricePage";
import { storeHcIntoLocalStorage } from "services/authService";
import { useLocation, useSearchParams } from "react-router-dom";
import SuccessModal from "./SuccessModal";
import Invoices from "./Invoices";
// import AdvertisementCards from "./AdvertisementCards";
// import Invoices from "./Invoices";
// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

// Interfaces

// Functions

const BillingPage = () => {
  const axios = useAxiosPrivate();
  const { user, userLoading }: any = useAuth();
  const [showPlanCards, setShowPLanCards] = useState(false);
  const dispatch = useDispatch();
  const [, setCancelModal] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const query = useQuery().get("status");
  const [successModal, setSuccessModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const manageBilling = async () => {
    const response = await axios.post("/subscriptions/create-portal-session ");

    if (response.data.success) {
      window.location.replace(response.data.data.url);
    }
  };

  // Eric: Hiding currency switch
  const currencyType = useSelector(
    (state: RootStateOrAny) => state.currency.currencyType
  );

  useEffect(() => {
    if (query && query === "success") {
      storeHcIntoLocalStorage(uniqid());
      setSuccessModal(true);
      queryParams.delete("status");
      queryParams.delete("session_id");
      setSearchParams(queryParams);
    } else if (query && query === "cancel") {
      setCancelModal(true);
      storeHcIntoLocalStorage(uniqid());
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user || Object.keys(user).length === 0 || !!userLoading)
    return (
      <Wrapper>
        <SpinnerLoading />
      </Wrapper>
    );

  const isAlreadySubscribed = user?.subscription?.plan !== "none";
  const hadSubscribed =
    user?.subscription?.canceledAt && user?.subscription?.plan === "none";

  // const getPlanType = (type: string) => {
  //   switch (type) {
  //     case "renderings":
  //       return "Home Render";
  //     case "floorplans":
  //       return "Home Floor Plan";
  //     case "selections":
  //       return "Home Selections";
  //     default:
  //       return "None";
  //   }
  // };
  return (
    <>
      <Wrapper>
        <Stack spacing={6}>
          {/* <AdvertisementCards /> */}

          <SectionWrapper
            title="Subscription details"
            Button={
              !!isAlreadySubscribed || !!hadSubscribed ? (
                <Button
                  onClick={() => manageBilling()}
                  colorScheme="blue"
                  w="max"
                >
                  Manage Billing
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowPLanCards(!showPlanCards);
                    dispatch(changePlanPrice(null));
                    dispatch(setSelectedPlanId(null));
                    dispatch(changePlanType(null));
                  }}
                  colorScheme="blue"
                  w="max"
                >
                  {!!showPlanCards ? "Close" : "Select Plans"}
                </Button>
              )
            }
          >
            <Stack spacing={showPlanCards ? 6 : 0}>
              <CurrentPlanInfo
                amount={user?.subscription?.amount}
                currPlan={user?.subscription?.plan || ""}
                subscription={user?.subscription}
              />
              <Stack spacing={4}>
                {showPlanCards ? (
                  <CurrencySwitch defaultValue={currencyType} />
                ) : null}
                {showPlanCards && <RadioPricePage />}
              </Stack>
            </Stack>
          </SectionWrapper>

          {!!user?.subscription?.customerId && (
            <Invoices customerId={user?.subscription?.customerId} />
          )}
        </Stack>
      </Wrapper>
      <SuccessModal
        isOpen={successModal}
        onClose={() => setSuccessModal(false)}
      />
    </>
  );
};

function Wrapper({ children }) {
  return (
    <Box as="section" p="6" border="1px solid" borderColor="#e2e8f0">
      {children}
    </Box>
  );
}

export default BillingPage;

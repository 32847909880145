/////////////////////
// Desired Assets data used in New Project Wizard
/////////////////////

// ANY CHANGES TO THIS FILE REQUIRE NOTIFICATION TO BACKEND (IMPORTANT!)

// Basic Imports
import { BiArea, BiCylinder, BiLandscape } from "react-icons/bi";
import { FiBox } from "react-icons/fi";
import {
  FaCouch,
  FaRegFileImage,
  FaRegQuestionCircle,
  FaTree,
} from "react-icons/fa";
import { AiOutlineProfile } from "react-icons/ai";
import { IconType } from "react-icons";
import {
  brochureOptions,
  floorplanOptions,
  floorplan3DOptions,
  render3DOptions,
  model3DOptions,
  landscapeOptions,
  specSheetOptions,
  virtualStagingOptions,
  StyleCardProps,
} from "./styleCardData";
import {
  RENDERING,
  FLOORPLAN,
  SELECTIONS,
} from "settings/constants/billingPlans";

// Interfaces
export interface LocationProps {
  value: string;
  label: string;
}

export interface ActionDataProps {
  initialData?: string[];
  outputFileType: string[];
  imageSize: string[];
  sourceFile: string[];
  cameraCaptureLocations?: any;
}

export interface AssetProps {
  id: string;
  title: string;
  value: string;
  icon: IconType;
  description: string;
  actionData: ActionDataProps;
  stylesData: StyleCardProps[];
  plan: string;
}

// Data
export const locations: LocationProps[] = [
  { value: "bar", label: "Bar" },
  { value: "bathroom", label: "Bathroom" },
  { value: "breakfastnook", label: "Breakfast Nook" },
  { value: "bedroom", label: "Bedroom" },
  { value: "diningroom", label: "Dining Room" },
  { value: "entrance", label: "Entrance" },
  { value: "familyroom", label: "Family Room" },
  { value: "greatroom", label: "Great Room" },
  { value: "kitchen", label: "Kitchen" },
  { value: "livingroom", label: "Living Room" },
  { value: "mainbathroom", label: "Main Bathroom" },
  { value: "masterbedroom", label: "Master Bedroom" },
  { value: "masterbedroomensuite", label: "Master Bedroom Ensuite" },
  { value: "mainbathroom", label: "Main Bathroom" },
  { value: "Office", label: "Office" },
  { value: "frontelevation", label: "Front Elevation" },
  { value: "leftelevation", label: "Left Elevation" },
  { value: "rightelevation", label: "Right Elevation" },
  { value: "backelevation", label: "Back Elevation" },
  { value: "northelevation", label: "North Elevation" },
  { value: "eastelevation", label: "East Elevation" },
  { value: "southelevation", label: "South Elevation" },
  { value: "westelevation", label: "West Elevation" },
  { value: "frontperspective", label: "Front Perspective" },
  { value: "frontyard", label: "Front Yard" },
  { value: "backyard", label: "Back Yard" },
  { value: "pool", label: "Pool" },
  { value: "deck", label: "Deck" },
  { value: "patio", label: "Patio" },
  { value: "roof", label: "Roof" },
  { value: "rearperspective", label: "Rear Perspective" },
  { value: "leftsideperspective", label: "Left Side Perspective" },
  { value: "rightsideperspective", label: "Right Side Perspective" },
  { value: "topperspective", label: "Top Perspective" },
  { value: "topelevation", label: "Top Elevation" },
  { value: "study", label: "Study" },
  { value: "den", label: "Den" },
  { value: "gameroom", label: "Game Room" },
  { value: "lanai", label: "Lanai" },
  { value: "porch", label: "Porch" },
  { value: "cellar", label: "Cellar" },
  { value: "gym", label: "Gym" },
  { value: "walkincloset", label: "Walk In Closet" },
  { value: "playroom", label: "Playroom" },
  { value: "sunroom", label: "Sun Room" },
  { value: "mudroom", label: "Mud Room" },
  { value: "laundry", label: "Laundry" },
];

export const assets: AssetProps[] = [
  {
    id: "1",
    title: "2D Floor Plan",
    value: "2DFloorPlan",
    // plan: STARTER_PLAN,
    plan: FLOORPLAN,
    icon: BiArea,
    description:
      "A drawing that displays the positions of rooms, fixtures/attachments, appliances, and furniture viewed from above. Most commonly used for real estate marketing.",
    actionData: {
      initialData: [
        "One floor per image",
        "One room per image",
        "Fit all floors to only one image",
      ],
      outputFileType: ["png", "jpg", "pdf", "svg", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["dwg", "ai"],
    },
    stylesData: floorplanOptions,
  },
  {
    id: "6",
    title: "Landscape Plan",
    value: "LandscapePlan",
    // plan: SELECTIONS,
    plan: FLOORPLAN,
    icon: FaTree,
    description:
      "With an extensive catalog of grasses, flowers, shrubs, and trees to sheds, benches and tables, including fountains and ponds to create designs.",
    actionData: {
      outputFileType: ["pdf", "jpg", "png", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["dwg", "ai"],
    },
    stylesData: landscapeOptions,
  },
  {
    id: "7",
    title: "Marketing Brochure",
    value: "MarketingBrochure",
    plan: SELECTIONS,
    icon: FaRegFileImage,
    description:
      "We have an assortment of customizable Marketing Brochures templates, including space for images, personalized logos, QR codes, and more.",
    actionData: {
      outputFileType: ["pdf", "eps", "html", "jpg", "png"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["ai", "indd"],
    },
    stylesData: brochureOptions,
  },
  {
    id: "8",
    title: "Specification / Feature Sheet",
    value: "SpecSheet",
    plan: SELECTIONS,
    icon: AiOutlineProfile,
    description:
      "Whether you want a detailed list of all the specifications within a home or one that highlights the just major features, provide your client with the information they want to know.",
    actionData: {
      outputFileType: ["pdf", "eps", "html", "jpg", "png"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["ai", "indd"],
    },
    stylesData: specSheetOptions,
  },
  {
    id: "3",
    title: "3D Render",
    value: "3DRender",
    // plan: FLOORPLAN,
    plan: RENDERING,
    icon: BiLandscape,
    description:
      "Producing a photo-realistic 2D image from a 3D model of a single family residence. Especially great for presales, under construction, or when a place is not available to show.",
    actionData: {
      outputFileType: ["jpg", "png", "pdf", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["blend", "skp"],
      cameraCaptureLocations: locations,
    },
    stylesData: render3DOptions,
  },
  {
    id: "2",
    title: "3D Floor Plan",
    value: "3DFloorPlan",
    // plan: FLOORPLAN,
    plan: RENDERING,
    icon: FiBox,
    description:
      "A floor plan of a single family residence in 3D. 3D provides a better sense of dimension and space than 2D drawings. Makes for a wonderful addition to your portfolio.",
    actionData: {
      initialData: [
        "One floor per image",
        "One room per image",
        "Fit all floors to only one image",
      ],
      outputFileType: ["jpg", "png", "pdf", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["blend", "skp"],
    },
    stylesData: floorplan3DOptions,
  },
  {
    id: "4",
    title: "3D Model",
    value: "3DModel",
    plan: RENDERING,
    icon: BiCylinder,
    description:
      "Creating a digital object for use within your own company. Often this is great for visualizing parts of your project, such as cabinet design for quick estimations.",
    actionData: {
      outputFileType: ["jpg", "png", "pdf", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["blend", "skp"],
    },
    stylesData: model3DOptions,
  },
  {
    id: "5",
    title: "Virtual Staging",
    value: "VirtualStaging",
    plan: RENDERING,
    icon: FaCouch,
    description:
      "Have your empty room photos virtually staged with photo-realistic 3D furniture. Rooms include your living room, dining room, bedrooms, office, and patio/balcony.",
    actionData: {
      outputFileType: ["jpg", "png", "pdf", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["blend", "skp"],
      cameraCaptureLocations: locations,
    },
    stylesData: virtualStagingOptions,
  },

  {
    id: "10",
    title: "3D Commercial / Multifamily / Land Development",
    value: "3dCommercial",
    plan: RENDERING,
    icon: BiArea,
    description:
      "3D floor plan or renderings for commercial, multifamily, and land development projects. Showcase your project in the best possible light to stakeholders, city officials, and the general public.",
    actionData: {
      outputFileType: ["jpg", "png", "pdf", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["blend", "skp"],
      cameraCaptureLocations: locations,
    },
    stylesData: render3DOptions,
  },
  {
    id: "9",
    title: "Other",
    value: "Other",
    plan: RENDERING,
    icon: FaRegQuestionCircle,
    description:
      "Don't see what you're looking for? If it's related to real estate and construction, let us know in Asset Description, and we'll be more than happy to help you out with the request.",
    actionData: {
      outputFileType: ["pdf", "eps", "html", "jpg", "png", "gif"],
      imageSize: ["small", "medium", "large"],
      sourceFile: ["depends"],
      cameraCaptureLocations: locations,
    },
    stylesData: [],
  },
];

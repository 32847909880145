/////////////////////
// Active Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";
import { REACT_QUERY_KEYS } from "settings/constants/queryKeys";

// Data Imports
import { fetchActiveProjectsAxiosRequest } from "services/projectsService";
import useFetchProjects from "hooks/useFetchProjects";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { TABLE_NAME } from "settings/constants/QueryTable";

// Functions
const ActiveProjects: FC = () => {
  const { auth, userLoading: isAuthLoading }: any = useAuth();
  const { projects, isLoading, setProjects, onChangePriorities } =
    useFetchProjects(REACT_QUERY_KEYS.ACTIVE_PROJECTS, () =>
      fetchActiveProjectsAxiosRequest(auth?.accessToken || "")
    );

  if (!!isLoading || !!isAuthLoading) return <SkeletonLoading />;
  return (
    <Fragment>
      <DragDropTable
        columns={columns({ tableName: TABLE_NAME.ACTIVE_TABLE })}
        data={projects}
        setData={setProjects}
        updateDataOnPriority={onChangePriorities}
      />
    </Fragment>
  );
};

export default ActiveProjects;

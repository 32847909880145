import axios from "axios";
import jwtDecode from "jwt-decode";
import { API_URL } from "settings/site-credentials";
import { Order, OrderDelivery, OrderStatus } from "types/order.types";

export async function getAllOrders({
  accessToken,
  status,
}: {
  accessToken: string;
  status: OrderStatus;
}) {
  const decodedToken: any = jwtDecode(accessToken);
  const userId = decodedToken._id;

  let url = `${API_URL}/users/${userId}/orders`;
  if (!!status) {
    url = `${url}?status=${status}`;
  }
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data.data.orders;
}

export async function getOrderById(
  id: string,
  accessToken: string
): Promise<Order> {
  const response = await axios.get(`${API_URL}/orders/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data.data.order;
}

export async function getOrderDeliveries(
  id: string,
  accessToken: string
): Promise<OrderDelivery[]> {
  const response = await axios.get(`${API_URL}/orders/${id}/deliveries`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.deliveries;
}

export async function onUpdateOrderStatus(
  id: string,
  status: OrderStatus,
  accessToken: string
) {
  const response = await axios.put(
    `${API_URL}/orders/${id}/status`,
    { status },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response.data.data.order;
}

export async function createOrderDelivery(
  data: Partial<OrderDelivery>,
  accessToken: string
): Promise<OrderDelivery> {
  const response = await axios.post(`${API_URL}/deliveries`, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.delivery;
}

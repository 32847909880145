import { FC } from "react";
import { Tag } from "@chakra-ui/react";
import { OrderStatus } from "types/order.types";
import { ORDER_STATUS } from "../../constants";

interface Props {
  status: OrderStatus;
}
const RenderOrderStatus: FC<Props> = ({ status }) => {
  const colorSchemes: Record<OrderStatus, string> = {
    in_progress: "blue",
    pending: "orange",
    delivered: "orange",
    completed: "green",
    cancelled: "red",
    revision_requested: "red",
    in_revision: "red",
  };

  return (
    <Tag w="max" colorScheme={colorSchemes[status]} textTransform="capitalize">
      {ORDER_STATUS[status]}
    </Tag>
  );
};

export default RenderOrderStatus;

import { FC, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Circle,
  Divider,
  HStack,
  Icon,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import useCustomToast from "hooks/useCustomToast";
import { createOrderRating } from "services/ratingService";
import useAccessToken from "hooks/useAccessToken";
import { orderKeys } from "settings/constants/queryKeys";
import Ratings from "components/ui/common/Ratings";
import TextareaField from "components/ui/common/TextareaField";
import CheckIcon from "icons/CheckIcon";
import MinimizeIconButton from "components/ui/IconButtons/MinimizeIconButton";

interface Props {
  orderId: string;
}
const OrderReviewForm: FC<Props> = ({ orderId }) => {
  const [isMinimized, setIsMinimized] = useState(true);
  const accessToken = useAccessToken();
  const [reviewText, setReviewText] = useState("");
  const queryClient = useQueryClient();
  const { errorToast } = useCustomToast();
  const [ratingFactors, setRatingFactors] = useState({
    sellerCommunication: 0,
    workQuality: 0,
    timelines: 0,
  });

  const createReviewMutation = useMutation({
    mutationFn: () =>
      createOrderRating(
        {
          orderId,
          text: reviewText,
          ratingFactors: [
            // TODO: This should be dynamic
            {
              label: "Seller Communication",
              value: ratingFactors.sellerCommunication,
            },
            {
              label: "Quality of Work",
              value: ratingFactors.workQuality,
            },
            {
              label: "Timeliness",
              value: ratingFactors.timelines,
            },
          ],
        },
        accessToken
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) }),
    onError: () =>
      errorToast({
        title: "Failed to submit review!",
        description:
          "Something went wrong! Try hard reloading or contact support",
      }),
  });

  return (
    <Card variant="outline">
      <CardHeader>
        <HStack justify="space-between" align="center">
          <HStack>
            <Circle bg="orange.100" p={3}>
              <Icon color="orange.700" as={FaStar} />
            </Circle>
            <Text fontWeight="semibold">
              To proceed with the financial transaction, a review must be
              submitted.
            </Text>
          </HStack>
          <MinimizeIconButton
            aria-label="Minimize Delivery"
            isMinimized={!isMinimized}
            onClick={() => setIsMinimized(!isMinimized)}
          />
        </HStack>
      </CardHeader>

      {!isMinimized && (
        <SlideFade in={true} offsetY="-20px">
          <Divider color="gray.300" />
          <CardBody>
            <Stack
              as="form"
              spacing={4}
              onSubmit={(e) => {
                e.preventDefault();
                createReviewMutation.mutate();
              }}
            >
              <Stack bg="gray.100" w="full" rounded="md" p={{ base: 2, md: 4 }}>
                <HStack justify="space-between">
                  <Text>Seller Communication</Text>
                  <Ratings
                    size="sm"
                    readOnly={false}
                    rating={ratingFactors.sellerCommunication}
                    onRatingChange={(rating) =>
                      setRatingFactors((prevState) => ({
                        ...prevState,
                        sellerCommunication: rating,
                      }))
                    }
                  />
                </HStack>
                <HStack justify="space-between">
                  <Text>Work Quality</Text>
                  <Ratings
                    size="sm"
                    readOnly={false}
                    rating={ratingFactors.workQuality}
                    onRatingChange={(rating) =>
                      setRatingFactors((prevState) => ({
                        ...prevState,
                        workQuality: rating,
                      }))
                    }
                  />
                </HStack>
                <HStack justify="space-between">
                  <Text>Timelines</Text>
                  <Ratings
                    size="sm"
                    readOnly={false}
                    rating={ratingFactors.timelines}
                    onRatingChange={(rating) =>
                      setRatingFactors((prevState) => ({
                        ...prevState,
                        timelines: rating,
                      }))
                    }
                  />
                </HStack>
              </Stack>
              <TextareaField
                label="Your review"
                name="review"
                onChange={(e) => setReviewText(e.currentTarget.value)}
              />
              <Button
                rightIcon={<CheckIcon />}
                size="sm"
                w="max-content"
                alignSelf="flex-end"
                type="submit"
                isLoading={createReviewMutation.isLoading}
              >
                Save
              </Button>
            </Stack>
          </CardBody>
        </SlideFade>
      )}
    </Card>
  );
};

export default OrderReviewForm;

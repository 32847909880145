import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

interface FormInputFieldProps extends InputProps {
  name: string;
  label?: string;
  placeholder?: string;
}

const FormInputField = forwardRef<HTMLDivElement, FormInputFieldProps>(
  ({ name, label, placeholder, type, ...restInputProps }, ref) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const { isOpen, onToggle } = useDisclosure(); // Toggle for showing and hiding password

    const errorMessage = (_.get(errors, name)?.message || "") as string;

    return (
      <FormControl ref={ref} id={name}>
        {!!label ? <FormLabel>{label}</FormLabel> : null}
        <Stack spacing={0}>
          <InputGroup size="md">
            <Input
              type={isOpen ? "text" : type} // if isOpen is true, it means we have to show password so convert into plain text
              {...register(name as any)}
              placeholder={placeholder}
              variant={errorMessage ? "error" : "outline"}
              {...restInputProps}
            />
            {type === "password" ? (
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={onToggle}>
                  {isOpen ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            ) : null}
          </InputGroup>

          {!!errorMessage ? (
            <Text color="red" fontSize="sm" fontStyle="italic">
              {errorMessage}
            </Text>
          ) : null}
        </Stack>
      </FormControl>
    );
  }
);

export default FormInputField;

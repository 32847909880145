import { FC, ReactNode } from "react";
import { Alert, AlertIcon, Flex, Text } from "@chakra-ui/react";
import { formatCurrency } from "utils/currency";

interface Props {
  startingPrice: number;
  startingDelivery: number;
}
const OfferCardPricing: FC<Props> = ({ startingPrice, startingDelivery }) => {
  if (!startingPrice)
    return (
      <Layout>
        <Alert status="warning" rounded="md" py={2}>
          <AlertIcon />
          No pricing
        </Alert>
      </Layout>
    );
  return (
    <Layout>
      <Flex align="center">
        <Text as="span" fontSize="sm">
          Starting at {formatCurrency(startingPrice || 0, "USD", true)},
          delivered {startingDelivery} {startingDelivery > 1 ? "days" : "day"}
        </Text>
      </Flex>
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex justifyContent="space-between" gap={4}>
      {children}
    </Flex>
  );
};

export default OfferCardPricing;

import { FC } from "react";
import { Alert, Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { User } from "types/profile.types";
import StarIcon from "icons/StartIcon";

interface Props {
  talent?: User;
  ratings?: any[];
}
const OfferCardProfile: FC<Props> = ({ talent, ratings }) => {
  return (
    <Flex gap="5">
      {talent ? (
        <Flex gap="2" align="center">
          <Box rounded="full" border="2px solid" borderColor="gray.200">
            <Avatar boxSize="8" src={talent?.avatar || ""} />
          </Box>
          <Text as="span" color="black">
            {talent?.fullName}
          </Text>
        </Flex>
      ) : (
        <Alert bg="gray.200" p={2} rounded="md">
          Profile not linked
        </Alert>
      )}
      {ratings && ratings.length > 0 && (
        <Flex align="center" gap="2">
          <Box color="ratingColor" fontSize="xl">
            <StarIcon />
          </Box>
          <Box as="span" color="black" fontSize="xs">
            4.9
          </Box>
          <Box as="span" fontSize="xs" fontWeight="600">
            {ratings?.length}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default OfferCardProfile;

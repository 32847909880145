///////////////////////////////
// Individual Project Page
///////////////////////////////

// Basic Imports
import { useParams } from "react-router-dom";
import _ from "lodash";
// import { MyCounter } from "hr-design-system";

// Design Imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  // Input,
  Stack,
  // useDisclosure,
} from "@chakra-ui/react";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";

// Layout and Section Imports
import Chat from "components/sections/ProjectsSection/Chat";
import ProjectsContainer from "components/sections/ProjectsSection/ProjectsContainer";
import OutputCard from "components/sections/ProjectsSection/Cards/COutput/OutputCard";
import ProjectInfoCard from "components/sections/ProjectsSection/Cards/AProjectInfo/ProjectInfoCard";
import DesiredAssetsCard from "components/sections/ProjectsSection/Cards/BDesiredAssets/DesiredAssetsCard";

// Data Imports
import { assets } from "data/projectWizardData";
import { fetchProjectByIdService } from "services/projectsService";
import {
  render_db_project__project_info,
  render_project_wizard_schema_from_db_project,
} from "schemas/project_wizard_schema";

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import ProjectPageHeader from "./ProjectPageHeader";
import ProjectPageFooter from "./ProjectPageFooter";
import { useEffect, useState } from "react";
import { useProject } from "context/project/ProjectProvider";
import SupportingDocumentsStep from "components/sections/ProjectsSection/Steps/ESupportingDocuments/SupportingDocumentsStep";
import StyleCardsReadOnly from "components/sections/ProjectsSection/Cards/StyleCardsReadOnly";
import AccordionItemCustom from "components/ui/common/AccordionItemCustom";

// Interfaces

// Functions
const ProjectPage = () => {
  const projectContext = useProject();
  const { id: projectId } = useParams();
  const { auth }: any = useAuth();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getAsyncData();
    async function getAsyncData() {
      const { data, error } = await fetchProjectByIdService(
        projectId,
        auth.accessToken
      );

      if (!!error) {
        setIsLoading(false);
        setIsError(true);
        return;
      }

      const project = data.data.project;

      const projectWizard =
        render_project_wizard_schema_from_db_project(project);
      projectContext.onUpdateProjectWizard({
        ...projectContext.projectWizard,
        hasProjectInitialized: true,
        ...projectWizard,
      });

      setProject(project);
      setIsLoading(false);
    }

    // DOC: It will remove initialized project from project wizard.
    return () => {
      projectContext.onCloseProjectWizard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (!!isLoading || !projectContext.projectWizard.id)
    return <SpinnerLoading />;
  if (!!isError)
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  if (projectContext.projectWizard.id === "") return null;

  return (
    <ProjectsContainer>
      <ProjectPageHeader project={project} />
      {projectContext.projectWizard.selectedAssets.length > 0 ? (
        <Chat my={5} />
      ) : null}

      <Accordion rounded="md" allowToggle>
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              _focus={{ boxShadow: "none" }}
              border="2px"
              borderColor="gray.200"
              rounded="md"
              _expanded={{ bg: "gray.200" }}
            >
              <Box flex="1" textAlign="left" fontSize="22" fontWeight="bold">
                Summary
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion allowToggle defaultIndex={[0]}>
              <Stack>
                <AccordionItemCustom title="Project Info">
                  <ProjectInfoCard
                    styles={{ my: 5 }}
                    data={render_db_project__project_info(project)}
                    projectId={project.id}
                    readOnly={true}
                  />
                </AccordionItemCustom>
                {project.selectedStyles.length > 0 && (
                  <AccordionItemCustom title="Assets">
                    <DesiredAssetsCard
                      styles={{ my: 5 }}
                      assets={_.intersectionBy(
                        assets,
                        project.selectedStyles,
                        "title"
                      )}
                      projectId={project.id}
                      readOnly={true}
                    />
                  </AccordionItemCustom>
                )}
                {project.selectedOutputs.length > 0 && (
                  <AccordionItemCustom title="Outputs">
                    <OutputCard
                      styles={{ my: 5 }}
                      data={project.selectedOutputs}
                      projectId={project.id}
                      readOnly={true}
                    />
                  </AccordionItemCustom>
                )}

                {projectContext.selectedStyles.length > 0 && (
                  <AccordionItemCustom title="Styles">
                    <StyleCardsReadOnly />
                  </AccordionItemCustom>
                )}

                {projectContext.supportingDocuments.length > 0 && (
                  <AccordionItemCustom title="Supporting Documents">
                    <SupportingDocumentsStep
                      showHeader
                      showUploadingForm={false}
                      showDeleteButton={false}
                    />
                  </AccordionItemCustom>
                )}
              </Stack>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <ProjectPageFooter style={{ mt: 8 }} project={project} />
    </ProjectsContainer>
  );
};

export default ProjectPage;

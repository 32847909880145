import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { OrderRating } from "types/rating.types";

export async function getOrderRatings(
  orderId: string,
  accessToken: string
): Promise<OrderRating[]> {
  const response = await axios.get(`${API_URL}/orders/${orderId}/ratings`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.ratings;
}

export async function createOrderRating(
  data: Partial<OrderRating>,
  accessToken: string
): Promise<OrderRating> {
  const response = await axios.post(`${API_URL}/ratings`, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.rating;
}

import { useQuery } from "react-query";
import { getCategories } from "services/categoryService";
import { categoriesKeys } from "settings/constants/queryKeys";
import { formatCategoriesWithOtherAtEnd } from "utils/category.utils";
import useAccessToken from "./useAccessToken";

const useCategories = () => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: categoriesKeys(),
    queryFn: () => getCategories(accessToken),
    select: formatCategoriesWithOtherAtEnd,
  });
};

export default useCategories;

import { useQuery } from "react-query";
import { getOfferReviews } from "services/offerService";
import { offerReviewsKeys } from "settings/constants/queryKeys";
import { Review } from "types/review.types";
import useAccessToken from "./useAccessToken";

const useOfferReviews = (offerId: string) => {
  const accessToken = useAccessToken();
  return useQuery<Review>({
    queryKey: offerReviewsKeys(offerId),
    queryFn: () => getOfferReviews(offerId, accessToken),
    enabled: !!offerId && !!accessToken,
  });
};

export default useOfferReviews;

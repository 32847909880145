import { useQuery } from "react-query";
import { getOfferAttachments } from "services/attachmentsService";
import { offerAttachmentsKey } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useOfferAttachments = (offerId: string) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: offerAttachmentsKey(offerId),
    queryFn: () => getOfferAttachments(offerId, accessToken),
    enabled: !!offerId,
  });
};

export default useOfferAttachments;

import { Container, Heading, Stack } from "@chakra-ui/react";
import Alert from "components/features/Alert";
import RenderOffers from "./RenderOffers";
import useOffers from "hooks/useOffers";
import SearchInputField from "components/ui/common/SearchInputField";
import CategoriesFilters from "./CategoriesFilters";

const OffersPage = () => {
  const { data: offers, isLoading, error, onSearch, searchText } = useOffers();

  const onClearSearch = () => {
    onSearch("");
  };

  if (!!error)
    return (
      <Container maxW="container.xl" centerContent>
        <Alert
          showCloseIcon={false}
          status="error"
          title="Something went wrong while fetching gigs."
          w="max"
        />
      </Container>
    );
  return (
    <Container maxW="container.xl" centerContent pt={6}>
      <Stack
        justifyContent="center"
        spacing={{ base: 6, md: 10 }}
        alignItems="center"
        w="full"
      >
        <Stack
          w="full"
          align="center"
          spacing={6}
          py={6}
          bg="white"
          rounded="lg"
        >
          <Heading color="black" textTransform="capitalize" textAlign="left">
            Offers
          </Heading>
          <Stack w={{ base: "90%", md: "80%" }}>
            <SearchInputField
              value={searchText}
              onChangeValue={onSearch}
              onClearValue={onClearSearch}
            />
            <CategoriesFilters />
          </Stack>
        </Stack>
        <RenderOffers
          data={offers}
          isLoading={isLoading}
          error={error as string}
        />
      </Stack>
    </Container>
  );
};

export default OffersPage;

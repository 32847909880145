/////////////////////
// Site Credentials Settings
/////////////////////

// Handlers
const isFeaturesLive = (isLive: true | false) => isLive;

// Credentials
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_API_URL = (URL: string) => URL.replace("v1", ""); // This logic is just to remove "v1" from url
export const LIVE_PAYMENT = false;
/*

export const LIVE_PAYMENT = isPaymentLive(
  "TRUE" === process.env.REACT_APP_LIVE_STRIPE ? true : false
);
*/
export const CUSTOMER_TOKEN_NAME = "hr_customer_token";
export const CUSTOMER_EMAIL = "hr_customer_email";
export const CUSTOMER_HC = "hr_customer_hc";
export const PROJECT_WIZARD_DATA = "project_wizard";
export const PERSIST_ROOT = "persist:root";
export const AUTHORIZED_TOKEN = JSON.parse(
  localStorage.getItem(CUSTOMER_TOKEN_NAME)
);
export const AUTHORIZED_EMAIL = JSON.parse(
  localStorage.getItem(CUSTOMER_EMAIL)
);
export const AUTHORIZED_HC = JSON.parse(localStorage.getItem(CUSTOMER_HC));

export const STARTER_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_USD;
export const PROFESSIONAL_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_USD;
export const BUSINESS_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_USD;

// export const STARTER_PLAN_PRICE_ID_USD =
//   process.env.REACT_APP_STRIPE_SELECTIONS_MONTHLY;
// export const PROFESSIONAL_PLAN_PRICE_ID_USD =
//   process.env.REACT_APP_STRIPE_FLOORPLANS_MONTHLY;
// export const BUSINESS_PLAN_PRICE_ID_USD =
//   process.env.REACT_APP_STRIPE_RENDERINGS_MONTHLY;

export const REACT_APP_STRIPE_FINAL_PLAN = LIVE_PAYMENT
  ? process.env.REACT_APP_STRIPE_FINAL_PLAN_LIVE
  : process.env.REACT_APP_STRIPE_FINAL_PLAN_TEST;

export const STARTER_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_CAD;
export const PROFESSIONAL_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_CAD;
export const BUSINESS_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_CAD;

export const STARTER_PLAN_PRICE_ID_USD_TEST =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_USD_TEST;
export const PROFESSIONAL_PLAN_PRICE_ID_USD_TEST =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_USD_TEST;
export const BUSINESS_PLAN_PRICE_ID_USD_TEST =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_USD_TEST;

export const STARTER_PLAN_PRICE_ID_CAD_TEST =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_CAD_TEST;
export const PROFESSIONAL_PLAN_PRICE_ID_CAD_TEST =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_CAD_TEST;
export const BUSINESS_PLAN_PRICE_ID_CAD_TEST =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_CAD_TEST;

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SECRET_KEY = LIVE_PAYMENT
  ? process.env.REACT_APP_STRIPE_SECRET_KEY
  : process.env.REACT_APP_STRIPE_SECRET_TEST_KEY;

export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_UPLOAD_PRESET;

export const LIVE_FEATURES = isFeaturesLive(
  "TRUE" === process.env.REACT_APP_STRIPE_PRICING_FEATURE ? true : false
);

//Cloudflare and Security
export const CLOUDFLARE_SITEKEY = process.env.REACT_APP_CLOUDFLARE_SITE_KEY;
export const UNDER_ATTACK =
  process.env.REACT_APP_ISHACKED === "true" ? true : false;

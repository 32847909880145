import { useEffect, useState } from "react";
import { API_URL, SOCKET_API_URL } from "settings/site-credentials";
import io from "socket.io-client";
import useAuth from "./useAuth";
import _ from "lodash";

// Interfaces
export interface INotificationDataState {
  notifications: any[];
  unread: number;
}

const useSocket = () => {
  const { token }: any = useAuth();
  const [socket, setSocket] = useState<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [notificationData, setNotificationData] =
    useState<INotificationDataState>({
      notifications: [],
      unread: 0,
    });

  useEffect(() => {
    if (!token) {
      return;
    }

    const socketInstance = io(SOCKET_API_URL(API_URL), {
      withCredentials: true,
      autoConnect: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 20000,
      requestTimeout: 10000,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [token]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    setIsConnected(socket.connected);

    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [socket]);

  // BETA: Experimental piece of code
  useEffect(() => {
    if (!socket) {
      return;
    }

    const handleAllNotifications = (data: INotificationDataState) => {
      setNotificationData(data);
    };

    socket.on("all_notifications", handleAllNotifications);

    return () => {
      socket.off("all_notifications", handleAllNotifications);
    };
  }, [socket]);

  return {
    notificationData,
    isConnected,
    onDeleteNotification: (id: string | string[]) => {
      const allNotifications = notificationData.notifications;

      const filteredNotifications =
        typeof id === "string"
          ? allNotifications.filter((n) => n._id !== id)
          : _.differenceBy(
              allNotifications,
              id.map((id) => ({ _id: id })),
              "_id"
            );

      setNotificationData({
        ...notificationData,
        unread: filteredNotifications.length,
        notifications: filteredNotifications,
      });
    },
  };
};

export default useSocket;

import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import ChevronUpIcon from "../Icons/ChevronUpIcon";
import ChevronDownIcon from "../Icons/ChevronDownIcon";

interface Props extends IconButtonProps {
  isMinimized: boolean;
}
const MinimizeIconButton: FC<Props> = ({ isMinimized, ...props }) => {
  return (
    <IconButton
      variant="ghost"
      icon={isMinimized ? <ChevronUpIcon /> : <ChevronDownIcon />}
      {...props}
    />
  );
};

export default MinimizeIconButton;

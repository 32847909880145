/////////////////////
// Project Services
/////////////////////

// Basic Imports
import axios from "axios";
import jwt_decode from "jwt-decode";

// Design Imports

// Layout and Section Imports

// Data Imports
import { API_URL } from "settings/site-credentials";
import { render_project_info__db_project } from "schemas/project_wizard_schema";

// Interfaces

// Custom Hooks and Services

// Functions
export async function initializeProjectService(
  projectWizard: any,
  token: string,
  projectStatus?: string,
  shouldTrackActivity?: boolean
) {
  const { id, projectInfo, selectedOutputs, selectedStyles } = projectWizard;

  const project = {
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus || "draft",
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project, token);
  } else {
    return updateCurrentProject(
      id,
      project,
      token,
      shouldTrackActivity || false
    );
  }
}

export async function submitProjectService(
  projectWizard: any,
  token: string,
  shouldTrackActivity: boolean
) {
  const { id, projectInfo, selectedOutputs, selectedStyles, projectStatus } =
    projectWizard;

  const project = {
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus,
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project, token);
  } else {
    return updateCurrentProject(id, project, token, shouldTrackActivity);
  }
}

export async function deleteProjectService(id: string | number, token: string) {
  try {
    const { data } = await axios.delete(`${API_URL}/projects/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchProjectsService(token: string) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    // TODO: Add user who logged in
    const { data } = await axios.get(`${API_URL}/projects`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function fetchProjectByIdService(
  id: string | number,
  token: string
) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    // TODO: Add user who logged in
    const { data } = await axios.get(`${API_URL}/projects/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export const updateProjectByStatus = async (
  id: string,
  statusData: any,
  token: string
) => {
  try {
    const { data } = await axios.patch(
      `${API_URL}/projects/${id}`,
      statusData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
};

// Simple axios request without handling errors
export const fetchProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchCompletedProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects?status=completed`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchDraftProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects?status=draft`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchPausedProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects?status=paused`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchActiveProjectsAxiosRequest = (token: string) => {
  const decodedToken: { _id: string } = jwt_decode(token);

  return axios.get(
    `${API_URL}/users/${decodedToken._id}/projects?status=active`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const fetchDeletedProjectsAxiosRequest = (token: string) =>
  axios.get(`${API_URL}/projects?status=deleted`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchProjectByIdAxiosRequest = (id: string, token: string) => {
  return axios.get(`${API_URL}/projects/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const fetchProjectAttachmentsAxiosRequest = (
  projectId: string,
  token: string
) =>
  axios.get(`${API_URL}/projects/${projectId}/attachments`, {
    headers: { Authorization: `Bearer ${token}` },
  });

// Handlers
async function createNewProject(project: any, token: string) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data, status } = await axios.post(`${API_URL}/projects`, project, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

async function updateCurrentProject(
  id: string,
  project: any,
  token: string,
  shouldTrackActivity: boolean
) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data, status } = await axios.patch(
      `${API_URL}/projects/${id}?trackActivity=${shouldTrackActivity ? 1 : 0}`,
      project,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function updateProjectsOrder(
  projectsIDs: any[],
  { token }: { token: string }
) {
  if (!API_URL) {
    throw new Error("Invalid environmental variable.");
  }

  try {
    const { data } = await axios.put(
      `${API_URL}/projects/orders/`,
      { projectIds: projectsIDs },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

import { FC } from "react";
import ActivityAccordion from "./RenderActivity/ActivityAccordion";
import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { OrderActivity } from "types/order.types";
import useOrderRatings from "hooks/useOrderRatings";
import Alert from "components/features/Alert";
import Ratings from "components/ui/common/Ratings";

interface Props {
  activity: OrderActivity;
  orderId: string;
}
const SellerReviewActivity: FC<Props> = ({ activity, orderId }) => {
  return (
    <ActivityAccordion activity={activity}>
      <ActivityContent orderId={orderId} />
    </ActivityAccordion>
  );
};

const ActivityContent = ({ orderId }: Pick<Props, "orderId">) => {
  const { data: orderRatings, isLoading } = useOrderRatings(orderId);
  const talentRating = orderRatings?.find(
    (rating) => rating.createdBy.role === "talent"
  );
  if (!!isLoading) return <Skeleton w="full" h="40" />;
  if (!talentRating)
    return (
      <Alert
        status="warning"
        title="Unable to find seller's review! Contact support"
      />
    );
  return (
    <Card variant="outline" size="sm" overflow="hidden">
      <CardHeader bg="gray.100" py={2}>
        <Text fontWeight="bold">Seller's Review</Text>
      </CardHeader>
      <CardBody>
        <Stack align="start" spacing={4}>
          <HStack>
            <Avatar
              src={talentRating.createdBy.avatar}
              name={talentRating.createdBy.fullName}
              mt={1}
            />
            <Stack spacing={1}>
              <Text>{talentRating?.createdBy.fullName}</Text>
              <Ratings rating={talentRating.average} showDigits size="xs" />
            </Stack>
          </HStack>
          <Stack w="full" p={2}>
            {talentRating.ratingFactors.map((factor) => (
              <HStack key={factor.value} justify="space-between">
                <Text>{factor.label}</Text>
                <Ratings rating={factor.value} showDigits size="xs" />
              </HStack>
            ))}
          </Stack>
          <Stack
            spacing={1}
            py={2}
            px={3}
            bg="gray.100"
            w="full"
            rounded="md"
            minH="5rem"
          >
            <Text>{talentRating.text}</Text>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default SellerReviewActivity;

/////////////////////
// Chat Section
/////////////////////

// Basic Imports
import { FC, useCallback, useRef, useState } from "react";

// Design Imports
import { Box, BoxProps, Stack, HStack } from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import CommentsSection from "./CommentsSection";
import useFetchComments from "hooks/useFetchComments";
import UploadSection from "./UploadSection";
import ChatGalleryWithSwiper from "./ChatGalleryWithSwiper";
import { useProject } from "context/project/ProjectProvider";
import { renderAssetTypeAsDB } from "schemas/assets_schema";

// Interfaces
interface ChatProps extends BoxProps {}

const Chat: FC<ChatProps> = ({ ...restProps }) => {
  const endMessageDiv = useRef<HTMLDivElement>();
  const { uploadLoading, onUploadComment, fetchCommentsLoading, comments } =
    useFetchComments();
  const { projectWizard } = useProject();
  const [fileToEdit, setFileToEdit] = useState<{
    format: string;
    name: string;
    secure_url: string;
  } | null>(null);

  const handleScrollToBottom = () => {
    if (!endMessageDiv.current) return;
    endMessageDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  const getDefaultSelectedAssets = useCallback(() => {
    const formattedTargetAssets = projectWizard.selectedOutputs.map(
      (assetOutput) => {
        const targetAsset = projectWizard.selectedAssets.find(
          (asset) => asset.title === assetOutput.title
        );

        return {
          id: assetOutput.id,
          alt: assetOutput.title || "-",
          quantity: assetOutput.quantity,
          icon: targetAsset?.icon,
          assetType: renderAssetTypeAsDB[targetAsset?.value],
          name: "",
          imgUrl: "",
          format: "",
          thumbnailUrl: "",
        };
      }
    );

    const spreadUponQuantity = [];
    formattedTargetAssets.forEach((asset) => {
      for (let i = 0; i < asset.quantity; i++) {
        spreadUponQuantity.push({
          ...asset,
          id: `${asset.id}-${i + 1}`,
          quantity: i + 1,
        });
      }
    });

    return spreadUponQuantity;
  }, [projectWizard]);

  return (
    <Stack>
      <Card {...restProps}>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "4", md: "10" }}
        >
          <CardContent>
            <HStack as="section" mb={5}>
              <CardHeader title="Assets" action="" />
            </HStack>

            <Box as="div" id="top">
              {" "}
            </Box>

            <ChatGalleryWithSwiper
              defaultSelectedAssets={getDefaultSelectedAssets()}
              onEdit={(file) => {
                setFileToEdit({
                  secure_url: file.imgUrl,
                  name: file.name,
                  format: file.format,
                });
                handleScrollToBottom();
              }}
            />
            <CommentsSection
              isLoading={fetchCommentsLoading}
              comments={comments}
              onEdit={(file) =>
                setFileToEdit({
                  secure_url: file.secure_url,
                  name: file.name,
                  format: file.format,
                })
              }
            />

            <Box as="div" id="bottom" ref={endMessageDiv}>
              {" "}
            </Box>

            <UploadSection
              uploadLoading={uploadLoading}
              onUploadComment={onUploadComment}
              fileToEdit={fileToEdit}
              onCloseEditing={() => setFileToEdit(null)}
            />
          </CardContent>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Chat;

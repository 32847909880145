import { useQuery } from "react-query";
import { getOrderDeliveries } from "services/orderService";
import { orderDeliveryKeys } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useOrderDeliveries = (orderId: string) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: orderDeliveryKeys(orderId),
    queryFn: () => getOrderDeliveries(orderId, accessToken),
    enabled: !!accessToken,
  });
};

export default useOrderDeliveries;

/////////////////////
// Card UI
/////////////////////

// Basic Imports

// Design Imports
import { Box, BoxProps, useColorModeValue as mode } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export const Card = (props: BoxProps) => {
  return (
    <Box
      bg={mode("white", "whiteAlpha.100")}
      borderWidth="2px"
      borderRadius="md"
      p={{ base: "6", md: "8" }}
      rounded={{ sm: "lg" }}
      {...props}
    />
  );
};

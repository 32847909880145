import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AllProfilesResponse, getAllProfiles } from "services/profilesService";
import { useLocation, useSearchParams } from "react-router-dom";
import useAccessToken from "./useAccessToken";
import useDebounce from "./useDebounce";

const useProfiles = () => {
  const accessToken = useAccessToken();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const [searchText, setSearchText] = useState("");
  const { debouncedValue, isLoading } = useDebounce(searchText, 500);

  // Pass searchText as a parameter to the query
  const gigsQuery = useQuery<AllProfilesResponse>(
    ["profiles", debouncedValue],
    () => getAllProfiles(accessToken, searchText),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const searchParam = queryParams.get("search");
    if (searchParam) {
      setSearchText(searchParam);
    }
  }, []);

  useEffect(() => {
    if (!!searchText) {
      queryParams.set("search", searchText);
    } else {
      queryParams.delete("search");
    }
    setSearchParams(queryParams);
  }, [searchText]);

  return {
    ...gigsQuery,
    searchText,
    searchLoading: isLoading,
    onSearch: (text: string) => setSearchText(text),
  };
};

export default useProfiles;

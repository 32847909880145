///////////////////////////////
// Project Table: Priority Column
///////////////////////////////

// Basic Imports

// Design Imports
import { Box, Icon, HStack } from "@chakra-ui/react";
import { MdLowPriority } from "react-icons/md";
import { FaBars } from "react-icons/fa";

const Header = () => {
  // Header is wrapper on column (e.g., you can change text color, background-color etc)
  return (
    <Box maxWidth={25}>
      <Icon as={MdLowPriority} />
    </Box>
  );
};

const Cell = (props) => {
  // Cell is wrapper on row (e.g., you can change text color, background-color etc)
  const { value, sortingAttributes, sortingListeners } = props;

  return (
    <HStack>
      <Icon
        cursor="grab"
        as={FaBars}
        {...sortingAttributes}
        {...sortingListeners}
        id={value}
      />
    </HStack>
  );
};

const exportObject = {
  Header,
  Cell,
};
export default exportObject;

///////////////////////////////
// Project Table: Completion Column
///////////////////////////////

// Basic Imports

// Design Imports
import { HStack, Icon, Progress, Text, Stack } from "@chakra-ui/react";
import { BiPieChart } from "react-icons/bi";

const Header = (props) => {
  return (
    <HStack>
      <Icon as={BiPieChart} />
      <Text>Information</Text>
    </HStack>
  );
};

const Cell = (props) => {
  const { value: completion } = props;

  return (
    <Stack>
      <Progress
        value={completion?.percent || "0"}
        colorScheme={completion?.color}
        size="xs"
        hasStripe
        bg="gray.300"
      />
    </Stack>
  );
};

const exportObject = {
  Header,
  Cell,
};
export default exportObject;

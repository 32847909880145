import { FC } from "react";
import { Avatar, Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";

interface Props {
  title?: string;
  avatar?: string;
}
const ProfileCardProfile: FC<Props> = ({ title, avatar }) => {
  return (
    <Stack>
      <Divider />
      <Flex gap="5">
        <Flex gap="2" align="center">
          <Box rounded="full" border="2px solid" borderColor="gray.200">
            <Avatar boxSize="8" src={avatar || ""} />
          </Box>
          <Text as="span" color="black">
            {title}
          </Text>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default ProfileCardProfile;

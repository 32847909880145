import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const tabs = [
  { label: "Activities", href: "activity" },
  { label: "Chat", href: "chat" },
];

const OrderPageTabs = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const activeTab = searchParams.get("tab");
  const activeIndex = tabs.findIndex((tab) => tab.href === activeTab);

  const generateHref = (tabHref: string) => {
    const params = new URLSearchParams(location.search);
    params.set("tab", tabHref);
    return `?${params.toString()}`;
  };

  return (
    <Tabs
      w="full"
      index={activeIndex}
      my={{ base: 4, md: 6 }}
      position="relative"
      variant="unstyled"
      fontWeight="semibold"
    >
      <TabList
        sx={{
          "& a:focus": {
            boxShadow: "none",
          },
        }}
      >
        {tabs.map((tab, i) => (
          <Tab
            key={tab.href}
            as={Link}
            to={generateHref(tab.href)}
            color={activeIndex === i ? "blue.500" : "inherit"}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
    </Tabs>
  );
};

export default OrderPageTabs;

/////////////////////
// Step Container
/////////////////////

// Basic Imports

// Design Imports
import { BsCheck } from "react-icons/bs";

import {
  Box,
  BoxProps,
  Circle,
  Collapse,
  Heading,
  HStack,
  Spinner,
  Tooltip,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Custom Hooks and Services
import { useStep } from "./useStep";

// Interfaces
interface StepProps extends BoxProps {
  title?: string;
  onClickStepHeader?: () => void;
  isLoading?: boolean;
  tooltip?: string;
}

// Functions
export const Step = ({
  title,
  children,
  onClickStepHeader,
  isLoading,
  tooltip = "",
  ...boxProps
}: StepProps) => {
  const { isActive, isCompleted, step } = useStep();

  const accentColor = mode("blue.500", "blue.300");
  const mutedColor = mode("gray.600", "whiteAlpha.800");
  const activeColor = mode("white", "black");

  return (
    <Box {...boxProps}>
      <HStack spacing="4" onClick={onClickStepHeader}>
        <Circle
          size="8"
          fontWeight="bold"
          color={
            isActive ? activeColor : isCompleted ? accentColor : mutedColor
          }
          bg={isActive ? accentColor : "transparent"}
          borderColor={isCompleted ? accentColor : "inherit"}
          borderWidth={isActive ? "0px" : "1px"}
        >
          {isLoading ? <Spinner /> : isCompleted ? <BsCheck size={25} /> : step}
        </Circle>
        <Tooltip placement="top" hasArrow label={tooltip}>
          <Heading
            fontSize="lg"
            fontWeight="semibold"
            color={isActive || isCompleted ? "inherit" : mutedColor}
            cursor="pointer"
            userSelect="none"
          >
            {title}
          </Heading>
        </Tooltip>
      </HStack>
      <Collapse in={isActive}>{children}</Collapse>
    </Box>
  );
};

import { useQuery } from "react-query";
import { getOrderRatings } from "services/ratingService";
import { orderRatings } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useOrderRatings = (orderId: string) => {
  const accessToken = useAccessToken();
  return useQuery({
    queryKey: orderRatings(orderId),
    queryFn: () => getOrderRatings(orderId, accessToken),
    enabled: !!orderId && !!accessToken,
  });
};

export default useOrderRatings;

///////////////////////////////
// Project Table: NextStep Column
///////////////////////////////

// Basic Imports

// Design Imports
import { Alert, AlertIcon, Box, HStack, Icon, Text } from "@chakra-ui/react";
import Task from "components/sections/ProjectsSection/TaskUI/Task";

import { MdDirectionsWalk } from "react-icons/md";

const Header = () => {
  return (
    <HStack>
      <Icon as={MdDirectionsWalk} />
      <Text>Progress</Text>
    </HStack>
  );
};

const Cell = ({ value: next_step }: any) => {
  if(next_step && next_step.length === 0 )
  return (
    <Alert minW="20rem" status={next_step.icon_status} shadow="base">
      <AlertIcon />
      Nothing to show...
    </Alert>
  );


  return (
    <Box overflow={'auto'} maxH={'60px'} >
    {next_step.map((task: any, index: number) => 
        <Task task={task} index={index}  />
      )}
    </Box>
  )
};

const exportObject = {
  Header,
  Cell,
};
export default exportObject;

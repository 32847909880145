/////////////////////
// Button Radio UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  Box,
  chakra,
  Image,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
  useColorModeValue as mode,
  useId,
  useCheckbox,
  CheckboxProps,
  RadioProps,
  Stack,
} from "@chakra-ui/react";

import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";
import StyleCardPopover from "components/ui/popover/StyleCardPopover";

// Layout and Section Imports

// Data Imports
const RadioBox = chakra("div", {
  baseStyle: {
    borderWidth: "3px",
    px: "3",
    pt: "3",
    pb: "3",
    borderRadius: "md",
    cursor: "pointer",
    transition: "all 0.2s",
    _focus: { shadow: "outline" },
  },
});

// Interfaces
interface ButtonRadioProps extends UseRadioProps {
  icon: React.ReactElement;
  label: string;
  description: string;
}

interface ButtonRadioImgProps extends UseRadioProps {
  image: string;
  label: string;
  description: string;
}

// Functions
interface RadioBoxIconProps extends RadioProps {
  checked: boolean;
}
const RadioboxIcon: React.FC<RadioBoxIconProps> = (props) => (
  <Box
    fontSize="4xl"
    color={
      props.checked
        ? props.isDisabled
          ? "blue.200"
          : "blue.600"
        : props.isDisabled
        ? mode("gray.100", "whiteAlpha.200")
        : mode("gray.300", "whiteAlpha.400")
    }
  >
    {props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
);

interface CheckboxIconProps extends CheckboxProps {
  checked: boolean;
}
const CheckboxIcon: React.FC<CheckboxIconProps> = (props) => (
  <Box
    fontSize="4xl"
    color={
      props.checked
        ? props.isDisabled
          ? "blue.200"
          : "blue.600"
        : props.isDisabled
        ? mode("gray.100", "whiteAlpha.200")
        : mode("gray.300", "whiteAlpha.400")
    }
  >
    {props.checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
  </Box>
);

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { label, icon, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <VStack spacing="4">
          <VStack textAlign="center">
            <Box
              aria-hidden
              fontSize="4xl"
              mb="3"
              color={state.isChecked ? "blue.600" : undefined}
            >
              {icon}
            </Box>
            <Text fontWeight="extrabold" fontSize="xl">
              {label}
            </Text>
            <Text fontSize="sm">{description}</Text>
          </VStack>
          <RadioboxIcon checked={state.isChecked} />
        </VStack>
      </RadioBox>
    </label>
  );
};

export const ButtonRadioImg = (props: ButtonRadioImgProps) => {
  const { label, image, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: state.isReadOnly ? "blue.200" : "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        cursor={state.isReadOnly ? "not-allowed" : "pointer"}
        {...getCheckboxProps()}
        _checked={checkedStyles}
        id={id}
        // h="35rem"
        h="28rem"
        px="4"
      >
        <Stack w="full" h="full" justify="space-between" spacing={0}>
          <Box
            flexGrow="1"
            maxH="65%"
            width="auto"
            rounded="md"
            overflow="hidden"
            bg="gray.100"
          >
            <Image
              opacity={state?.isReadOnly ? 0.5 : 1}
              src={image}
              alt={label}
              objectFit="contain"
              w="full"
              h="full"
              rounded="md"
            />
          </Box>
          {/* <Stack justify="space-between" align="center" h="12rem" w="full"> */}
          <Stack justify="space-between" align="center" w="full">
            <Stack align="center">
              <Text fontWeight="bold" color="inherit" fontSize="lg">
                {label}
              </Text>
              <StyleCardPopover
                title={label}
                imgUrl={image}
                description={description}
              />
            </Stack>
            <Box alignSelf="center">
              <RadioboxIcon
                isDisabled={state.isReadOnly}
                checked={state.isChecked}
              />
            </Box>
          </Stack>
        </Stack>
      </RadioBox>
    </label>
  );
};

export const ButtonCheckboxImg = (props: ButtonRadioImgProps) => {
  const { label, image, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useCheckbox(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: state.isReadOnly ? "blue.200" : "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        {...getCheckboxProps()}
        _checked={checkedStyles}
        id={id}
        // h="30rem"
        h="28rem"
        px="4"
      >
        <Stack w="full" h="full" justify="space-between" spacing={0}>
          <Image
            opacity={state?.isReadOnly ? 0.5 : 1}
            src={image}
            alt={label}
            objectFit="contain"
            flexGrow="1"
            // h="auto"
            maxH="65%"
            width="auto"
            rounded="md"
          />
          <Stack justify="space-between" align="center" w="full">
            <Stack align="center">
              <Text fontWeight="extrabold" color="inherit" fontSize="xl">
                {label}
              </Text>
              {/* If you want to add description as well (that has been removed on Eric's demand), you can pick from bottom in legacy code */}
              <StyleCardPopover
                title={label}
                imgUrl={image}
                description={description}
              />
            </Stack>
            <CheckboxIcon
              isDisabled={state.isReadOnly}
              colorScheme="red"
              checked={state.isChecked}
            />
          </Stack>
        </Stack>
      </RadioBox>
    </label>
  );
};

//LEGACY: Solution to truncate description text
{
  /* <Tooltip hasArrow placement="top" label={description}>
      <Text
        textAlign="center"
        // noOfLines={3}
        fontSize="sm"
        color="inherit"
        overflow="hidden"
        textOverflow="ellipsis"
        display="-webkit-box"
        style={{
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
        }}
      >
        {description}
      </Text>
    </Tooltip> */
}

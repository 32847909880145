import { useQuery } from "react-query";
import { getOrderRevisions } from "services/revisionService";
import { orderRevisions } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";

const useOrderRevisions = (orderId: string) => {
  const token = useAccessToken();
  return useQuery({
    queryKey: orderRevisions(orderId),
    queryFn: () => getOrderRevisions(orderId, token),
  });
};

export default useOrderRevisions;

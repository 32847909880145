import { FC, ReactNode } from "react";
import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import { Offer } from "types/offer.types";
import OfferCard from "components/sections/GigsSections/OfferCard";
import Alert from "components/features/Alert";

interface Props {
  data: Offer[];
  error: string;
  isLoading: boolean;
}
const RenderOffers: FC<Props> = ({ data, error, isLoading }) => {
  if (!!isLoading) return <OffersSkeletonLoading />;
  if (!!error)
    return (
      <Alert
        maxW="30rem"
        status="error"
        description="Unable to fetch offer! Try reload or contact support"
      />
    );
  if (data.length === 0)
    return (
      <Alert status="warning" description="No offer found!" maxW="30rem" />
    );
  return (
    <Layout>
      {data.map((offer) => {
        return <OfferCard key={offer.id} id={offer.id} offer={offer} />;
      })}
    </Layout>
  );
};

const OffersSkeletonLoading = () => {
  return (
    <Layout>
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SimpleGrid w="full" gap="6" columns={{ base: 1, md: 3, lg: 4 }} px={2}>
      {children}
    </SimpleGrid>
  );
};

export default RenderOffers;

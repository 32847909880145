/////////////////////
// Notification (appPages) UI
/////////////////////

// Basic Imports
import React, { useEffect } from "react";

// Design Imports
import {
  Text,
  CenterProps,
  Stack,
  Heading,
  Divider,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import parse from "html-react-parser";
//import { Link } from "react-router-dom";

// Layout and Section Imports

// Data Imports

import { formatDate } from "utils/date";
import colors from "settings/theme/colors";
//import { ROUTES } from "settings/constants/routes";
import useAuth from "hooks/useAuth";
import { MdDelete } from "react-icons/md";
import { deleteNotifications } from "services/notificationService";
import { useState } from "react";
// import { RenderLink } from "./RenderLink";

// Interfaces
interface NotificationBodyProps extends CenterProps {
  onClickNotification?: (notification: any) => void;
  onDeleteNotification: (id: string | string[]) => void;
  data: any[];
}

export const NotificationBody = React.forwardRef(
  (props: NotificationBodyProps) => {
    return (
      <Stack w="full" spacing={2} pb={12}>
        {props.data.map((item) => {
          return (
            <NotificationCard
              key={item._id}
              data={item}
              onClickNotification={props.onClickNotification}
              onDeleteNotification={props.onDeleteNotification}
            />
          );
        })}
      </Stack>
    );
  }
);

interface NotificationCardProps extends NotificationBodyProps {
  data: any;
}
export function NotificationCard({
  data,
  onClickNotification,
  onDeleteNotification,
}: NotificationCardProps) {
  const { token }: any = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  //Commenting if this logic works than remove it
  // const getNotificationLink = (notification) => {
  //   if (notification.resourceType === "project")
  //     return `${ROUTES.PROJECTS_PAGE}/${notification.resourceId}`;

  //   return "#";
  // };
  const { user: loggedInUser }: any = useAuth();

  function checkIfRead(userIds) {
    return userIds.some((user) => user.userId === loggedInUser._id);
  }

  return (
    <Stack position="relative">
      <Stack
        onClick={() =>
          onClickNotification({
            notificationId: data._id,
            isRead: checkIfRead(data.readBy),
          })
        }
        // as={RenderLink}
        // data={data.metaData}
        borderLeft={!checkIfRead(data.readBy) ? "4px solid #2b6cb0" : ""}
        w="full"
        p="2"
      >
        <Stack spacing={1}>
          <Heading size={{ base: "xs", md: "sm" }} color={colors.blue}>
            {data?.title || "Notification"}
          </Heading>
        </Stack>
        <Text fontSize={{ base: "12", md: "16" }}>
          {parse(data?.message || "")}
        </Text>

        <HStack justify="space-between">
          <Text
            fontSize={{ base: "11", md: "15" }}
            color="GrayText"
            fontStyle="italic"
          >
            {formatDate(new Date(data.updatedAt))}
          </Text>
        </HStack>
      </Stack>
      <Divider />

      <IconButton
        size="lg"
        variant="link"
        colorScheme="red"
        position="absolute"
        right={2}
        bottom={5}
        aria-label="delete-notification"
        icon={<MdDelete />}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const { data: deleteNotificationRes } = await deleteNotifications(
            [data._id.toString()],
            token
          );
          setIsLoading(false);

          if (
            !!deleteNotificationRes &&
            deleteNotificationRes.message === "deleted"
          ) {
            onDeleteNotification(data._id);
          }
        }}
      />
    </Stack>
  );
}

///////////////////////////////
// Billing Page
// Rev history
//  2022.04.25 - Updated file comment template
///////////////////////////////

// Basic Imports

// Design Imports
import { Box, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
// import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { giveRemainingDaysForSubscription } from "services/stripeService";

// Layout and Section Imports

//Service import

// Data Imports
// import Legacy from "./Legacy";

// Custom Hooks and Services

// Interfaces
interface Props {
  currPlan: string;
  amount: number;
  subscription: any;
}

// Functions

const CurrentPlanInfo: FC<Props> = ({ currPlan, amount, subscription }) => {
  const [remainingDays, setRemainingDays] = useState(0);

  const getDays = async () => {
    const days = await giveRemainingDaysForSubscription(
      subscription?.currentPeriodEnd,
      subscription?.canceledAt
    );
    setRemainingDays(days);
  };

  useEffect(() => {
    if (subscription?.status === "canceled") {
      getDays();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  return (
    <Box as="section">
      <Box>
        <Stack>
          <HStack alignItems="flex-start" spacing={3}>
            <Icon
              as={currPlan === "none" ? FaInfoCircle : FaCheckCircle}
              fontSize="4xl"
              color={currPlan === "none" ? "red.400" : "green.400"}
            />
            <Stack spacing={2}>
              <Stack spacing={0}>
                <Text fontWeight="semibold">CURRENT PLAN</Text>
                <Text
                  color="linkedin.600"
                  fontSize="lg"
                  fontWeight="semibold"
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  {currPlan === "none" ? "Not Subscribed" : currPlan}
                </Text>
                {currPlan === "none" ? (
                  <HStack>
                    <Icon as={MdDateRange} />
                    <Text
                      color="gray.600"
                      fontSize="sm"
                      fontWeight="semibold"
                      textTransform="uppercase"
                      letterSpacing="wide"
                    >
                      {remainingDays + " Days remaining"}
                    </Text>
                  </HStack>
                ) : null}
              </Stack>
              <Stack spacing={0}>
                {currPlan !== "none" && (
                  <Stack spacing={0}>
                    {!!amount ? (
                      <Text>${(amount / 100).toFixed(2)}/month + Tax</Text>
                    ) : null}
                    {/* <Text>Renews on May 25, 2023 (beta)</Text> */}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default CurrentPlanInfo;

import { useQuery } from "react-query";
import { offerRequirementsKeys } from "settings/constants/queryKeys";
import { Requirement } from "types/requirement.types";
import useAccessToken from "./useAccessToken";
import { getOfferRequirements } from "services/offerService";

const useOfferRequirements = (offerId: string) => {
  const accessToken = useAccessToken();
  return useQuery<Requirement[]>({
    queryKey: offerRequirementsKeys(offerId),
    queryFn: () => getOfferRequirements(offerId, accessToken),
    refetchOnWindowFocus: false,
  });
};

export default useOfferRequirements;

import axios from "api/axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAssetsByProjectId(projectId, token) {
  try {
    const res = await axios.get(`${API_URL}/projects/${projectId}/assets`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const assets = res.data.data.assets as any[];
    return { assets, res };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

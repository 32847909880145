import {
  Box,
  Stack,
  StackDivider,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import useAuth from "hooks/useAuth";
import useCustomToast from "hooks/useCustomToast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateCommunicationService } from "services/userProfileService";

const CommunicationsPage = () => {
  const { user, token }: any = useAuth();
  const { errorToast } = useCustomToast();
  const [preferences, setPreferences] = useState<{
    projects: boolean;
    newsletters: boolean;
    promotional: boolean;
  }>({ projects: false, newsletters: false, promotional: false });
  const queryClient = useQueryClient();
  const updateUserMutationQuery = useMutation({
    mutationFn: async (formData: {
      projects: boolean;
      newsletters: boolean;
      promotional: boolean;
    }) => {
      const { data, error } = await updateCommunicationService(token, formData);

      if (!!error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }

      return data;
    },
    onSuccess: async (updatedUser) => {
      queryClient.setQueryData(["authUser", token], () => {
        setPreferences(updatedUser.data.user.preferences.notifications);

        return { user: updatedUser.data.user };
      });
    },
  });

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) return;

    const notificationOptions = user?.preferences?.notifications;
    setPreferences({
      projects: notificationOptions?.projects || false,
      newsletters: notificationOptions?.newsletters || false,
      promotional: notificationOptions?.promotional || false,
    });
  }, [user]);

  return (
    <Box
      as="section"
      py={{ base: "4", md: "8" }}
      border="1px solid"
      borderColor="#e2e8f0"
    >
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderRadius="lg"
        p={{ base: "4", md: "6" }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Notifications
            </Text>
            <Text fontSize="sm" color="muted">
              Receive notifications from HomeRender
            </Text>
          </Stack>
          <NotificationOption
            type="Application Notifications"
            description="Receive email for application/project updates"
            isActive={preferences?.projects}
            onChange={async (value) => {
              setPreferences({ ...preferences, projects: value });
              updateUserMutationQuery.mutate({
                projects: value,
                newsletters: preferences.newsletters,
                promotional: preferences.promotional,
              });
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

function NotificationOption({
  type,
  description,
  isActive,
  isDisabled = false,
  onChange,
}) {
  return (
    <Stack justify="space-between" direction="row" spacing="4">
      <Stack spacing="0.5" fontSize="sm">
        <Text color="emphasized" fontWeight="medium">
          {type}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
      <Switch
        onChange={(e) => onChange(e.target.checked)}
        isChecked={isActive}
        isDisabled={isDisabled}
      />
    </Stack>
  );
}

export default CommunicationsPage;

import { FC } from "react";
import {
  Button,
  Card,
  CardHeader,
  Circle,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useAccessToken from "hooks/useAccessToken";
import { GrDeliver } from "react-icons/gr";
import { useMutation, useQueryClient } from "react-query";
import { onUpdateOrderStatus } from "services/orderService";
import ConfirmationModal from "components/ui/Modals/ConfirmationModal";
import { orderKeys } from "settings/constants/queryKeys";
import useCustomToast from "hooks/useCustomToast";

interface Props {
  orderId: string;
}
const OrderDeliveredAction: FC<Props> = ({ orderId }) => {
  const token = useAccessToken();
  const queryClient = useQueryClient();
  const confirmationModal = useDisclosure();
  const { errorToast } = useCustomToast();
  const acceptanceMutation = useMutation({
    mutationFn: () => onUpdateOrderStatus(orderId, "completed", token),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) }),
    onError: () => {
      errorToast({
        title: "Failed to mark order as complete.",
        description:
          "Something went wrong, please try again or contact support",
      });
    },
  });

  return (
    <Card variant="outline">
      <CardHeader>
        <HStack justify="space-between" align="center">
          <HStack w="full" justify="space-between">
            <HStack>
              <Circle bg="pink.100" p={3}>
                <Icon color="pink.700" as={GrDeliver} />
              </Circle>
              <Stack spacing={0}>
                <Text fontWeight="semibold">
                  The seller has just delivered your order.
                </Text>
              </Stack>
            </HStack>

            <Stack>
              <Button
                size="sm"
                isLoading={acceptanceMutation.isLoading}
                onClick={confirmationModal.onOpen}
              >
                Accept delivery
              </Button>

              <ConfirmationModal
                confirmButtonColorScheme="blue"
                description="Once confirmed, this order will be marked as completed, and no further revisions will be possible."
                isOpen={confirmationModal.isOpen}
                onOpen={confirmationModal.onOpen}
                onClose={confirmationModal.onClose}
                onConfirm={() => acceptanceMutation.mutate()}
              />
            </Stack>
          </HStack>
        </HStack>
      </CardHeader>
    </Card>
  );
};

export default OrderDeliveredAction;

/////////////////////
// ImageCarousel UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports
import { KeenSliderOptions, useKeenSlider } from "keen-slider/react"; // using version 6.6.10

// Data Imports

// Interfaces

// Functions

export const ImageCarousel = React.forwardRef<HTMLDivElement, FlexProps>(
  function ImageCarousel(props, ref) {
    return (
      <Flex
        ref={ref}
        className="chakra-ImageCarousel"
        overflow="hidden"
        position="relative"
        userSelect="none"
        style={{ justifyContent: "start" }}
        alignItems="center"
        {...props}
      />
    );
  }
);

export const ImageCarouselSlide = (props: BoxProps) => (
  <Box
    className="chakra-ImageCarousel__slide "
    position="relative"
    overflow="hidden"
    width="100%"
    minH="100%"
    {...props}
  />
);

export const ImageCarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    variant="unstyled"
    boxSize="auto"
    minW="auto"
    display="inline-flex"
    fontSize="2xl"
    color={mode("gray.600", "gray.400")}
    _hover={{
      color: mode("blue.500", "blue.300"),
      _disabled: { color: mode("gray.600", "gray.400") },
    }}
    _active={{ color: mode("blue.600", "blue.400") }}
    _focus={{ boxShadow: "none" }}
    _focusVisible={{ boxShadow: "outline" }}
    {...props}
  />
);

export const useImageCarousel = (options?: KeenSliderOptions) => {
  const defaultOptions = { selector: ".chakra-ImageCarousel__slide" };
  return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options });
};

import {
  VStack,
  SimpleGrid,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  Stack,
} from "@chakra-ui/react";
import { BiSolidMagicWand } from "react-icons/bi";
import { FC } from "react";
import OfferCard from "components/sections/GigsSections/OfferCard";
import { Offer } from "types/offer.types";

interface OfferSection {
  offers: Offer[] | undefined;
}
const OffersSection: FC<OfferSection> = ({ offers }) => (
  <Stack spacing={5} pt={10} pb={5}>
    <Text fontSize="2xl" fontWeight="bold" color="black">
      My offers
    </Text>
    {offers && offers.length > 0 ? (
      <SimpleGrid gap="6" columns={{ base: 1, md: 4 }}>
        {offers.map((offer, i) => (
          <OfferCard key={i} offer={offer} />
        ))}
      </SimpleGrid>
    ) : (
      <Alert
        status="info"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        bg="gray.200"
        rounded="md"
      >
        <VStack>
          <BiSolidMagicWand fontSize="40px" />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            No offers found
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            There are no offers in this profile
          </AlertDescription>
        </VStack>
      </Alert>
    )}
  </Stack>
);

export default OffersSection;

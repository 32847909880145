export const NEW_PROJECT_PAGE = {
  label: "New Project",
  href: "/projects/new-project",
};
export const PROJECTS_PAGE = {
  label: "Projects",
  href: "/projects",
};

export const PAGE_AFTER_LOGIN = "/projects"; // Logic is being used in 'useFormState.tsx'

import { FC, ReactNode } from "react";
import { Heading, Skeleton, Stack } from "@chakra-ui/react";
import RatingsProgressbar from "./RatingsProgressbar";
import ReviewCard from "./ReviewCard";
import useOfferReviews from "hooks/useOfferReviews";

interface Props {
  offerId: string;
}
const ReviewsSection: FC<Props> = ({ offerId }) => {
  const { data: reviews, isLoading } = useOfferReviews(offerId);
  const buyerReviews = reviews?.ratings.filter(
    (rating) => rating.createdBy.role === "customer"
  );

  if (!!isLoading) return <Loader />;
  return (
    <Layout>
      <RatingsProgressbar
        isLoading={isLoading}
        averageRating={reviews?.averageRating || 0}
        ratingDistributions={reviews?.ratingDistributions || []}
        reviewsCount={reviews?.totalRatingsCount || 0}
      />
      {buyerReviews && (
        <Stack mt={4}>
          {buyerReviews.map((rating) => (
            <ReviewCard key={rating.id} rating={rating} />
          ))}
        </Stack>
      )}
    </Layout>
  );
};

const Loader = () => {
  return (
    <Layout>
      <Stack>
        <Skeleton maxW="30rem" h="1.5rem" />
      </Stack>
      <Stack>
        <Skeleton w="full" h="1rem" />
        <Skeleton w="full" h="rem" />
        <Skeleton w="full" h="1rem" />
        <Skeleton w="full" h="1rem" />
        <Skeleton w="full" h="1rem" />
      </Stack>
      <Stack mt={4}>
        <Skeleton w="full" h="8rem" />
        <Skeleton w="full" h="8rem" />
        <Skeleton w="full" h="8rem" />
        <Skeleton w="full" h="8rem" />
      </Stack>
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack as="section" spacing={4}>
      <Heading
        textTransform="capitalize"
        as="h2"
        fontWeight="bold"
        fontSize="xl"
      >
        Reviews
      </Heading>
      {children}
    </Stack>
  );
};

export default ReviewsSection;

/////////////////////
// TabLink (appPages) UI
/////////////////////

// Basic Imports
import { FC } from "react";
import {
  chakra,
  HTMLChakraProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props extends HTMLChakraProps<"a"> {
  href: string;
}

// Functions
export const TabLink: FC<Props> = (props) => {
  return (
    // CHANGED: (NextJS Link) helps switching pages without hard reload!

    <chakra.a
      as={Link}
      to={props.href}
      replace
      fontWeight="semibold"
      px="4"
      py="3"
      color={mode("gray.600", "gray.400")}
      borderBottom="2px solid transparent"
      transition="all 0.2s"
      _hover={{
        borderColor: mode("gray.400", "gray.600"),
      }}
      _activeLink={{
        color: mode("blue.600", "blue.400"),
        borderColor: "currentColor",
      }}
      {...props}
    />
  );
};

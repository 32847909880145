import { FC } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { saveAs } from "file-saver";
import {
  Text,
  Box,
  Image,
  Skeleton,
  IconButton,
  HStack,
  BoxProps,
} from "@chakra-ui/react";
import { SwiperPropsCustom, SwiperSlidePropsCustom } from "./types";
import { FaDownload, FaEdit } from "react-icons/fa";
import PDFViewer from "libraries/PDFViewer/PDFViewer";
//import calculateAspectRatio from 'calculate-aspect-ratio';

const SwiperApp: FC<SwiperPropsCustom> = ({
  slides,
  isLoading = false,
  styles,
  onEdit,
}) => {
  const boxStyles: BoxProps = {
    my: 4,
    mx: 1,
    height: "fit-content",
    ...styles,
  };

  if (isLoading)
    return <Skeleton rounded="md" height={boxStyles.height} {...boxStyles} />;
  return (
    <Box
      as={Swiper}
      navigation={true}
      modules={[Navigation]}
      userSelect="none"
      style={{
        // @ts-ignore
        "--swiper-navigation-color": "black",
        "--swiper-pagination-color": "#fff",
      }}
      {...boxStyles}
    >
      {slides.map((slide: SwiperSlidePropsCustom, index: number) => {
        if (!slide.imgUrl) return null;
        return (
          <SwiperSlide key={index}>
            <Text
              fontSize="lg"
              color="muted"
              fontWeight="bold"
              textColor="black"
            >
              {slide.alt}
            </Text>
            <Box cursor="pointer">
              {slide.format != "pdf" ? (
                <Image
                  src={slide.imgUrl}
                  height="100%"
                  width="100%"
                  border="2px"
                  rounded="md"
                  objectFit="contain"
                />
              ) : (
                <PDFViewer url={slide.imgUrl} width={""} />
              )}
            </Box>
            <HStack position="absolute" top="10" right="5">
              <IconButton
                onClick={() => saveAs(slide.imgUrl, slide.name)}
                aria-label="download"
                colorScheme="gray"
                shadow="base"
                icon={<FaDownload size="16" />}
                size="sm"
              />
              {slide.format != "pdf" && (
                <IconButton
                  key={index}
                  aria-label="edit"
                  icon={<FaEdit size={20} />}
                  size="sm"
                  onClick={() => onEdit && onEdit(slide)}
                />
              )}
            </HStack>
          </SwiperSlide>
        );
      })}
    </Box>
  );
};

export default SwiperApp;

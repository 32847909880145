import { FC, useEffect, useRef, useState } from "react";
import { IconButton, Stack } from "@chakra-ui/react";
import MessageItem from "./MessageItem";
import MessagesLoading from "./MessagesLoading";
import MessagesWrapper from "./MessagesWrapper";
import useOrderMessages from "hooks/useOrderMessages";
import Alert from "components/features/Alert";
import ChevronDownIcon from "components/ui/Icons/ChevronDownIcon";

interface Props {
  orderId: string;
}

const ListMessages: FC<Props> = ({ orderId }) => {
  const { data: messages, isLoading, error } = useOrderMessages(orderId);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages) {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setShowScrollButton(!atBottom);
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!!isLoading)
    return (
      <MessagesWrapper ref={messageListRef} handleScroll={handleScroll}>
        <MessagesLoading />
      </MessagesWrapper>
    );

  if (!messages || !!error)
    return (
      <Alert
        status="error"
        title="Messages not found! Try hard reload or contact support."
      />
    );

  if (messages.length === 0)
    return (
      <MessagesWrapper ref={messageListRef} handleScroll={handleScroll}>
        <Alert
          status="info"
          title="No message yet, you can start sending message to buyer!"
        />
      </MessagesWrapper>
    );

  return (
    <MessagesWrapper ref={messageListRef} handleScroll={handleScroll}>
      <Stack spacing={{ base: 6, md: 8 }}>
        {messages.map((message) => (
          <MessageItem
            key={message.id} // Add a unique key for each item
            content={message.text}
            attachments={message.attachments}
            createdAt={message.createdAt}
            createdBy={{
              avatar: message.createdBy.avatar,
              fullName:
                message.createdBy.fullName ||
                `${message.createdBy.firstName} ${message.createdBy.lastName}`,
              role: message.createdBy.role,
            }}
          />
        ))}
        <div ref={messageEndRef} />
      </Stack>
      {showScrollButton && (
        <IconButton
          position="absolute"
          bottom="4"
          right="4"
          icon={<ChevronDownIcon />}
          aria-label="Scroll to bottom"
          onClick={scrollToBottom}
          bg="blue.500"
          color="white"
          _hover={{ bg: "blue.600" }}
          borderRadius="full"
          boxShadow="lg"
        />
      )}
    </MessagesWrapper>
  );
};

export default ListMessages;

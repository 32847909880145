/////////////////////
// PlanSummary Component
/////////////////////

// Basic Imports

// Design Imports
import { Box, Button } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Services Imports
import { RootStateOrAny, useSelector } from "react-redux";

import uniqid from "uniqid";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { SELECTIONS, FLOORPLAN } from "settings/constants/billingPlans";
import {
  LIVE_PAYMENT,
  STARTER_PLAN_PRICE_ID_USD,
  PROFESSIONAL_PLAN_PRICE_ID_USD,
  BUSINESS_PLAN_PRICE_ID_USD,
  STARTER_PLAN_PRICE_ID_CAD,
  PROFESSIONAL_PLAN_PRICE_ID_CAD,
  BUSINESS_PLAN_PRICE_ID_CAD,
  STARTER_PLAN_PRICE_ID_USD_TEST,
  PROFESSIONAL_PLAN_PRICE_ID_USD_TEST,
  BUSINESS_PLAN_PRICE_ID_USD_TEST,
  STARTER_PLAN_PRICE_ID_CAD_TEST,
  PROFESSIONAL_PLAN_PRICE_ID_CAD_TEST,
  BUSINESS_PLAN_PRICE_ID_CAD_TEST,
  STRIPE_PUBLISHABLE_KEY,
} from "settings/site-credentials";
import { storeHcIntoLocalStorage } from "services/authService";
import useAxiosPrivate from "hooks/useAxiosPrivate";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  }

  return stripePromise;
};

// Interfaces

// Functions
export const PlanSummary = (props: any) => {
  const { planType } = props;
  const currencyType = useSelector(
    (state: RootStateOrAny) => state.currency.currencyType
  );
  const planDuration = useSelector(
    (state: RootStateOrAny) => state.plan.planDuration
  );
  const axios = useAxiosPrivate();

  const stripeHc = uniqid();
  storeHcIntoLocalStorage(stripeHc);

  const redirectToCheckout = async (plan) => {
    const stripe = await getStripe();

    const response = await axios.post(
      "/subscriptions/create-checkout-session",
      {
        plan: planType.toLowerCase(),
        currency: currencyType.toLowerCase(),
        billingPeriod: planDuration.toLowerCase(),
      }
    );

    await stripe.redirectToCheckout({
      sessionId: response.data.data.sessionId,
    });
  };

  const getSelectedPlanId = () => {
    let currency = currencyType;
    let planName = planType.toLowerCase();
    let selectedPriceID = null;
    if (currency === "USD") {
      if (planName === SELECTIONS) {
        selectedPriceID = LIVE_PAYMENT
          ? STARTER_PLAN_PRICE_ID_USD
          : STARTER_PLAN_PRICE_ID_USD_TEST;
      } else if (planName === FLOORPLAN) {
        selectedPriceID = LIVE_PAYMENT
          ? PROFESSIONAL_PLAN_PRICE_ID_USD
          : PROFESSIONAL_PLAN_PRICE_ID_USD_TEST;
      } else {
        selectedPriceID = LIVE_PAYMENT
          ? BUSINESS_PLAN_PRICE_ID_USD
          : BUSINESS_PLAN_PRICE_ID_USD_TEST;
      }
    } else if (currencyType === "CAD") {
      if (planName === SELECTIONS) {
        selectedPriceID = LIVE_PAYMENT
          ? STARTER_PLAN_PRICE_ID_CAD
          : STARTER_PLAN_PRICE_ID_CAD_TEST;
      } else if (planName === FLOORPLAN) {
        selectedPriceID = LIVE_PAYMENT
          ? PROFESSIONAL_PLAN_PRICE_ID_CAD
          : PROFESSIONAL_PLAN_PRICE_ID_CAD_TEST;
      } else {
        selectedPriceID = LIVE_PAYMENT
          ? BUSINESS_PLAN_PRICE_ID_CAD
          : BUSINESS_PLAN_PRICE_ID_CAD_TEST;
      }
    }

    return selectedPriceID;
  };

  return (
    <Box textAlign="right">
      <Button
        colorScheme="blue"
        size="lg"
        fontSize="md"
        mt={5}
        onClick={() => redirectToCheckout(getSelectedPlanId())}
      >
        Subscribe
      </Button>
    </Box>
  );
};

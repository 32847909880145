import { FC } from "react";
import { Button, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FiRefreshCcw } from "react-icons/fi";
import { OfferPrice } from "types/offer.types";
import { formatCurrency } from "utils/currency";
import ClockIcon from "icons/ClockIcon";
import CheckIcon from "icons/CheckIcon";
import { useMutation } from "react-query";
import { createCheckoutSession } from "services/billingService";
import useAccessToken from "hooks/useAccessToken";
import useCustomToast from "hooks/useCustomToast";

interface RenderPricingDetailsProps {
  data: OfferPrice | undefined;
}

const RenderPricingDetails: FC<RenderPricingDetailsProps> = ({ data }) => {
  const accessToken = useAccessToken();
  const { errorToast } = useCustomToast();
  const checkoutSession = useMutation({
    mutationFn: async () => {
      const paymentUrl = await createCheckoutSession({
        offerId: data.offerId,
        priceId: data.id,
        accessToken,
      });

      window.location.href = paymentUrl;
      return undefined;
    },
    onError: () =>
      errorToast({ title: "Unable to purchase order, contact support" }),
  });

  return (
    <Stack px={2} spacing={4} w="full">
      <HStack fontWeight="semibold" justify="space-between">
        <Text>{data?.title}</Text>
        <Text>{formatCurrency(data?.amount || 0)}</Text>
      </HStack>

      <Text noOfLines={3} h="4.5rem">
        {data?.description}
      </Text>

      <HStack spacing={6}>
        <HStack fontWeight="semibold">
          <ClockIcon />
          <Text>{data?.duration} Days</Text>
        </HStack>
        <HStack fontWeight="semibold">
          <Icon as={FiRefreshCcw} />
          <Text>{data?.numOfRevisions} Revisions</Text>
        </HStack>
      </HStack>

      <Stack>
        {data?.features.map((feature: any) => (
          <HStack key={feature.label}>
            <CheckIcon color="blue.500" fontSize="xl" />
            <Text>{feature.label}</Text>
          </HStack>
        ))}
      </Stack>

      <Stack>
        <Button
          isLoading={checkoutSession.isLoading}
          colorScheme="blue"
          onClick={() => checkoutSession.mutate()}
        >
          Purchase
        </Button>
      </Stack>
    </Stack>
  );
};

export default RenderPricingDetails;

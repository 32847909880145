import { Badge, Box, HStack, Link, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Logo } from "../logo/Logo2";
import { ProgressGuideLogo } from "../logo/ProgressGuideLogo";

interface AppLogoProps {
  isBetaVersion?: boolean;
}
const AppLogo: FC<AppLogoProps> = ({ isBetaVersion }) => {
  const logoSize = useBreakpointValue({ base: '5px', md: 'xs' }, { ssr: false })
  return (
    <VStack position="relative">
      <Box >
        <Link as={RouterLink} to="/">
          <Logo h={{ base: 4, sm: 8, md: 10 }} />
        </Link>
        {!!isBetaVersion && (
          <Box position="absolute" right={-6} top={0}>
            <Badge
              colorScheme="red"
              rounded="lg"
              variant="solid"
              position="relative"
              transform="translate3d(-50%, -50%, 0)"
            >
              <Text color="white">Beta</Text>
            </Badge>
          </Box>
        )}
      </Box>
      <HStack mt='-8px!important' pl={5}>
        <Text color="white" fontSize={logoSize} fontWeight="bold" mr="-1">BY</Text>
        <Box>
          <ProgressGuideLogo color="white" />
        </Box>
      </HStack>
    </VStack>
  );
};

export default AppLogo;

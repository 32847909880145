import { FC } from "react";
import { Skeleton, Stack, Tag, Text, Wrap } from "@chakra-ui/react";

interface Props {
  labels: string[];
  isLoading: boolean;
}
const OfferTags: FC<Props> = ({ labels, isLoading }) => {
  return (
    <Stack as="section">
      <Text as="h2" fontWeight="bold" fontSize="2xl">
        Tags
      </Text>
      {isLoading ? (
        <TagSkeletons />
      ) : (
        <Wrap>
          {labels.map((label) => (
            <Tag colorScheme="blue" key={label}>
              {label}
            </Tag>
          ))}
        </Wrap>
      )}
    </Stack>
  );
};

const TagSkeletons = () => {
  return (
    <Wrap>
      <Skeleton w="70px" h="25px" rounded="md" />
      <Skeleton w="70px" h="25px" rounded="md" />
      <Skeleton w="70px" h="25px" rounded="md" />
      <Skeleton w="70px" h="25px" rounded="md" />
    </Wrap>
  );
};

export default OfferTags;

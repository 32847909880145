/////////////////////
// Draft Card (Dashboard)
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Box,
  Circle,
  Divider,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
  Center,
  Progress,
  Td,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import PageContent from "components/ui/appPages/PageContent";

import { GoProject } from "react-icons/go";

import { RiBarChartHorizontalFill, RiDraftLine } from "react-icons/ri";
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";
import { ROUTES } from "settings/constants/routes";
import { getProjectLink } from "utils/getProjectLink";
import LinkableText from "components/features/LinkableText";
import Alert from "components/features/Alert";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  projectData: any[];
  isLoading?: boolean;
}

// Functions

const DraftSection: FC<Props> = ({ projectData, isLoading }) => {
  const breakPointSize = useBreakpointValue({ base: "sm", lg: "md" });
  const breakPointDisplayBN = useBreakpointValue({ base: "block", lg: "none" });
  const breakPointDisplayNB = useBreakpointValue({ base: "none", lg: "block" });
  const shouldShowPercentage = useBreakpointValue({ base: false, lg: true });

  if (!!isLoading) return <SkeletonLoading />;
  return (
    <PageContent position="relative">
      <Box w="full" maxH="400" overflow="auto">
        <HStack color={mode("gray.500", "gray.400")} pb="4">
          <Circle bg="purple.400" color="white" size="6">
            <RiDraftLine />
          </Circle>
          <Text fontWeight="medium">Draft Projects</Text>
        </HStack>
        <Divider />
        {projectData.length === 0 ? (
          <Alert
            showCloseIcon={false}
            my="0"
            status="warning"
            title="Nothing to show!"
            size="sm"
          />
        ) : (
          <Table
            size={breakPointSize}
            fontSize={{ base: "xs", lg: "md" }}
            variant="simple"
          >
            <Thead>
              <Tr>
                <Th>
                  <HStack>
                    <GoProject display={breakPointDisplayBN} />
                    <Text display={breakPointDisplayNB}> Project</Text>
                  </HStack>
                </Th>
                {shouldShowPercentage && (
                  <Th>
                    <HStack>
                      <RiBarChartHorizontalFill display={breakPointDisplayBN} />
                      <Text display={breakPointDisplayNB}>% Information</Text>
                    </HStack>
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody overflow="auto">
              {projectData.map((project) => {
                return (
                  project.state === "draft" && (
                    <Tr key={project.id}>
                      <Td>
                        <LinkableText
                          to={getProjectLink(project.state, project.id)}
                        >
                          <Text>{project.name}</Text>
                        </LinkableText>
                      </Td>
                      {shouldShowPercentage && (
                        <Td>
                          <Progress
                            hasStripe
                            isAnimated
                            value={project.information.percent}
                            colorScheme={project.information.color}
                          />
                        </Td>
                      )}
                    </Tr>
                  )
                );
              })}
            </Tbody>
          </Table>
        )}
      </Box>
      <Center>
        <Text as={Link} to={ROUTES.PROJECTS_DRAFT} color="blue.500">
          See more
        </Text>
      </Center>
    </PageContent>
  );
};

export default DraftSection;

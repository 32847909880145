import { FC, ReactNode } from "react";
import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import { Profile } from "types/profile.types";
import ProfileCard from "components/sections/ProfileCard";
import Alert from "components/features/Alert";

interface Props {
  data: Profile[];
  error: string;
  isLoading: boolean;
}
const RenderProfiles: FC<Props> = ({ data, error, isLoading }) => {
  if (!!isLoading) return <OffersSkeletonLoading />;
  if (!!error)
    return (
      <Alert
        maxW="30rem"
        status="error"
        description="Unable to fetch offer! Try reload or contact support"
      />
    );
  if (data.length === 0)
    return (
      <Alert status="warning" description="No offer found!" maxW="30rem" />
    );
  return (
    <Layout>
      {data.map((profile: Profile) => {
        return <ProfileCard key={profile.id} profile={profile} />;
      })}
    </Layout>
  );
};

const OffersSkeletonLoading = () => {
  return (
    <Layout>
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SimpleGrid w="full" gap="6" columns={{ base: 1, md: 2, lg: 4 }}>
      {children}
    </SimpleGrid>
  );
};

export default RenderProfiles;

/////////////////////
// Profile Page
/////////////////////

// Basic Imports


// Design Imports
import {
  BoxProps,
} from "@chakra-ui/react";

// Layout and Section Imports
import Form from "./Form";
import Alert from "components/features/Alert";
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Hooks
import useProfilePage from "./useProfilePage";

const ProfilePage = (props: BoxProps) => {
  const { userLoading, userError, formData } = useProfilePage();

  if (!!userLoading) return (
    <SkeletonLoading h={{ base: "60vh", md: "40rem"}} />
  )
  if (!!userError) return (
    <Alert status="error" title="Something went wrong..." />
  )
  return (
    <Form  
      defaultValues={formData}
    />
  );
};

export default ProfilePage;

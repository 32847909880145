import { useQuery } from "react-query";
import { getGigById } from "services/gigsService";
import useAuth from "./useAuth";

const useGig = ({ offerId }: { offerId: string }) => {
  const { auth }: any = useAuth();
  const accessToken = auth.accessToken;

  const gigQuery = useQuery(
    ["offers", offerId],
    () => getGigById(offerId, accessToken),
    {
      enabled: !!offerId,
    }
  );

  return gigQuery;
};

export default useGig;

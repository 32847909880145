/////////////////////
// Use Stripe Hook
/////////////////////

// Basic Imports
import { useQuery } from "react-query";

// Design Imports

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces

// Functions
const useStripe = (axiosReqFn: any) => {
  const { data, error, isLoading, isFetching }: any = useQuery(
    "plans",
    axiosReqFn,
    { initialData: {}, refetchOnWindowFocus: false }
  );
  const plan = data;

  return {
    error,
    isLoading: isLoading || isFetching,
    plans: plan ? plan : {},
  };
};

export default useStripe;

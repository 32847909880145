import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const whiteBlue = defineStyle({
  bg: "white",
  color: "blue.500",
  _hover: {
    bg: "blue.500",
    color: "white",
  },
});

export const buttonTheme = defineStyleConfig({
  variants: { whiteBlue },
  defaultProps: {
    colorScheme: "blue",
  },
});

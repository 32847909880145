/////////////////////
// RadioCardGroup Component
/////////////////////

// Basic Imports
import React, { useState } from "react";

// Design Imports
import {
  Box,
  BoxProps,
  Circle,
  SimpleGrid,
  Stack,
  StackProps,
  Tag,
  TagLabel,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useStyleConfig,
} from "@chakra-ui/react";

import { BsCheckCircleFill } from "react-icons/bs";

// Layout and Section Imports

// Data Imports

// Services Imports
import {
  changePlanDuration,
  changePlanPrice,
  changePlanType,
  setCurrentPlan,
  setSelectedPlanId,
} from "app/planReducer";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import _ from "lodash";

// Interfaces
interface RadioCardGroupProps<T> extends Omit<StackProps, "onChange"> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
  plans?: any[];
}

// Functions
export const RadioCardGroup = <T extends string>(
  props: RadioCardGroupProps<T>
) => {
  const { children, name, defaultValue, value, onChange, plans, ...rest } =
    props;
  // const stripePlans = useSelector(
  //   (state: RootStateOrAny) => state.plan.stripePlans
  // );
  // const planDuration = useSelector(
  //   (state: RootStateOrAny) => state.plan.planDuration
  // );

  const dispatch = useDispatch();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange: (value: T) => {
      const currentPlan = plans[Number(value)];
      const { id, name, price, duration } = currentPlan;
      dispatch(setSelectedPlanId(id));
      dispatch(setCurrentPlan(_.toString(value)));
      dispatch(changePlanDuration(duration));
      dispatch(changePlanType(getPlanType(name)));
      dispatch(changePlanPrice(price));
    },
  });

  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<RadioCardProps>>(React.isValidElement)
        .map((card) => {
          return React.cloneElement(card, {
            // @ts-ignore
            radioProps: getRadioProps({
              value: card.props.value,
              defaultValue: defaultValue,
              disabled: card.props.disabled,
            }),
          });
        }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, getRadioProps]
  );
  const getPlanType = (planType: string) => {
    let plan = planType?.toLowerCase()?.replaceAll(" ", "") || "";
    if (plan.includes("starter")) {
      return "starter";
    } else if (plan.includes("professional")) {
      return "professional";
    } else if (plan.includes("business")) {
      return "business";
    }
  };
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2, md: 1, xl: 3 }}
      {...getRootProps(rest)}
    >
      {cards}
    </SimpleGrid>
  );
};

interface CustomRadioProps extends UseRadioProps {
  defaultValue: any;
}

// Interfaces
interface RadioCardProps extends BoxProps {
  value: string | number;
  defaultValue: string | number;
  radioProps?: CustomRadioProps;
  disabled?: boolean;
}

// Functions
export const RadioCard = (props: RadioCardProps) => {
  //const dispatch = useDispatch();
  const [checked, setChecked] = useState(null);
  const currentPlan = useSelector(
    (state: RootStateOrAny) => state.plan.currentPlan
  );

  console.log({ checked, currentPlan });

  const { radioProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useRadio(radioProps);
  const id = useId(undefined, "radio-button");

  const styles = useStyleConfig("RadioCard", props);
  const inputProps = getInputProps();
  // @ts-ignore
  const { value }: any = inputProps;
  console.log({ value, state });
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();
  return (
    <Box
      border={
        // @ts-ignore
        value !== radioProps.defaultValue && state.isChecked
          ? "4px"
          : // @ts-ignore
          value !== radioProps?.defaultValue
          ? "2px"
          : "4px"
      }
      borderColor={
        // @ts-ignore
        checked === currentPlan ? "blue.500" : "gray"
        // value !== radioProps?.defaultValue
        // ? "gray.500"
        // : "green.500"
      }
      borderRadius="2xl"
      position="relative"
      as="label"
      height="fit-content"
      cursor="pointer"
      {...labelProps}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      {radioProps?.defaultValue &&
        radioProps?.defaultValue === radioProps?.value && (
          <Tag
            size={"2xl"}
            borderRadius="full"
            variant="solid"
            p={2}
            w={"150px"}
            right={"32%"}
            top={"-20px"}
            position="absolute"
            display="flex"
            justifyContent="center"
            // colorScheme="green"
          >
            <TagLabel>Current Plan</TagLabel>
          </Tag>
        )}

      <input
        {...inputProps}
        aria-labelledby={id}
        onClick={() => setChecked(_.toString(value))}
        // disabled={
        //   // @ts-ignore
        //   value === currentPlan ? true : false
        // }
      />
      <Box sx={styles} {...checkboxProps} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          <Box className="krishna">
            {
              // @ts-ignore
              checked === currentPlan ? (
                <Circle size="4">
                  <BsCheckCircleFill
                    size="25"
                    color="white.500"
                    fill="#2b6cb0"
                  />
                </Circle>
              ) : (
                // @ts-ignore
                value !== radioProps.defaultValue && (
                  <Circle borderWidth="2px" size="4" />
                )
              )
            }
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

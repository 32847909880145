/////////////////////
// Turnstile widget
/////////////////////

// Layout and Section Imports
import { Turnstile } from "@marsidev/react-turnstile";
import { CLOUDFLARE_SITEKEY } from "settings/site-credentials";

interface TurnsTile {
    setVerified: (verified: boolean) => void;
}

const TurnTileWidget = ({ setVerified }: TurnsTile) => {
    return (
        <Turnstile
            siteKey={CLOUDFLARE_SITEKEY}
            onSuccess={() => setVerified(true)}
            onError={() => setVerified(false)}
            options={{
                theme: 'light',
                size: 'normal'
            }}
        />
    );
};

export default TurnTileWidget;

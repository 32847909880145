export async function convertUrlToFile(
  url: string,
  name: string,
  format: string
) {
  // let mimeType = `image/${format}`; // TODO: "mimeType" field should be fetched straight from backend.
  let mimeType = `${format}`; // TODO: "mimeType" field should be fetched straight from backend.

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: Status ${response.status}`);
    }
    const blob = await response.blob(); // Retrieve the content as Blob
    const file = new File([blob], name, { type: mimeType }); // Create a File object
    return file;
  } catch (error) {
    console.error("Error converting URL to File:", { error });
    return null; // or throw the error, depending on your error handling
  }
}

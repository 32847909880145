import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { getOrderMessages } from "services/messageService";
import { orderMessagesKeys } from "settings/constants/queryKeys";
import { OrderMessage } from "types/message.types";
// import { orderMessagesKeys } from "@/settings/queryKeys";
import useSound from "use-sound";
import useAccessToken from "./useAccessToken";
// import { OrderMessage } from "@/interface/message.types";

const useOrderMessages = (orderId: string) => {
  const token = useAccessToken();
  const queryResult = useQuery({
    queryKey: orderMessagesKeys(orderId),
    queryFn: () => getOrderMessages(orderId, token),
    refetchInterval: 5000,
    refetchIntervalInBackground: true, // This option allow user to receive notification bell even if on different tab
  });

  /** This is the notification bell sound functionality */
  const previousMessagesCount = useRef<number | null>(null);
  const [play] = useSound("/sound.wav");

  useEffect(() => {
    if (queryResult.data && previousMessagesCount.current !== null) {
      const newMessagesCount = queryResult.data.length;

      if (newMessagesCount > previousMessagesCount.current) {
        const newMessages = queryResult.data.slice(
          previousMessagesCount.current
        );
        const hasCustomerMessage = newMessages.some(
          (message: OrderMessage) => message.createdBy.role === "talent"
        );

        if (hasCustomerMessage) {
          // Play the notification sound
          play();
        }
      }
    }

    // Update the previous messages count
    previousMessagesCount.current = queryResult.data?.length || null;
  }, [queryResult.data, play]);

  return queryResult;
};

export default useOrderMessages;

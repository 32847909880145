/////////////////////
// Button Radio Group UI
/////////////////////

// Basic Imports
import React from "react";

// Design Imports
import {
  Stack,
  SimpleGrid,
  useRadioGroup,
  UseRadioGroupProps,
  useCheckboxGroup,
  UseCheckboxProps,
} from "@chakra-ui/react";

import { ButtonCheckboxImg, ButtonRadio, ButtonRadioImg } from "./ButtonRadio";

// Layout and Section Imports

// Data Imports

// Interfaces
interface ButtonRadioGroupProps extends UseRadioGroupProps {
  options: Array<{
    label: string;
    value: string;
    description: string;
    icon: React.ReactElement;
  }>;
  isReadOnly?: boolean;
}
interface ButtonGroupImgProps extends UseRadioGroupProps {
  options: Array<{
    label: string;
    value: string;
    description: string;
    image: string;
  }>;
  isReadOnly?: boolean;
}
interface ButtonCheckboxGroupImgProps extends UseCheckboxProps {
  options: Array<{
    label: string;
    value: string;
    description: string;
    image: string;
  }>;
  value?: any;
  atLeastOne?: boolean;
  onChange: any;
  isReadOnly?: boolean;
}

// Functions

// Components
export const ButtonRadioGroup = (props: ButtonRadioGroupProps) => {
  const { options, ...rest } = props;
  const { getRadioProps, getRootProps } = useRadioGroup(rest);
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing="3"
      {...getRootProps()}
    >
      {options.map((option) => (
        <ButtonRadio
          key={option.value}
          icon={option.icon}
          description={option.description}
          label={option.label}
          // @ts-ignore
          {...getRadioProps({ value: option.value })}
        />
      ))}
    </Stack>
  );
};
export const ButtonRadioGroupImg = (props: ButtonGroupImgProps) => {
  const { options, ...rest } = props;
  const { getRadioProps, getRootProps } = useRadioGroup(rest);

  return (
    <SimpleGrid spacing="5" columns={{ base: 1, lg: 3 }} {...getRootProps()}>
      {options &&
        options.map((option) => {
          return (
            <ButtonRadioImg
              isReadOnly={props.isReadOnly}
              key={option.value}
              image={option.image}
              description={option.description}
              label={option.label}
              // @ts-ignore
              {...getRadioProps({ value: option.value })}
            />
          );
        })}
    </SimpleGrid>
  );
};
export const ButtonCheckboxGroupImg = (props: ButtonCheckboxGroupImgProps) => {
  const { options, value, onChange, atLeastOne, ...rest } = props;
  const { getCheckboxProps } = useCheckboxGroup({
    value,
    onChange: (values: string[]) => {
      // If alteast one is turned on
      if (atLeastOne && values.length === 0) return;

      onChange(values);
    },
  });

  return (
    <SimpleGrid spacing="5" columns={{ base: 1, lg: 3 }}>
      {options &&
        options.map((option) => {
          return (
            <ButtonCheckboxImg
              isReadOnly={props.isReadOnly}
              key={option.value}
              image={option.image}
              description={option.description}
              label={option.label}
              {...getCheckboxProps({ value: option.value })}
              {...rest}
            />
          );
        })}
    </SimpleGrid>
  );
};

import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { Offer, OfferPrice } from "types/offer.types";
import { Category, SubCategory } from "types/gigCategory";
import { Rating } from "types/gigRatings";
import { Tag } from "types/gigTag";
import { FAQ } from "types/faq.types";

export type AllGigsResponse = { success: boolean; gigs: Offer[] };
export async function getAllGigs(
  accessToken: string,
  searchText: string = ""
): Promise<AllGigsResponse> {
  try {
    const url = searchText
      ? `${API_URL}/offers?search=${encodeURIComponent(searchText)}`
      : `${API_URL}/offers`;

    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, gigs: data.data.offers };
  } catch (error) {
    console.log("Error while fetching gigs :: ", { error });
    return { success: false, gigs: [] };
  }
}

export type GigByIdResponse = { success: boolean; gig: Offer | null };
export async function getGigById(
  id: string,
  accessToken: string
): Promise<GigByIdResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/offers/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, gig: data.data.offer };
  } catch (error) {
    return { success: false, gig: null };
  }
}

export type GigPricesResponse = { success: boolean; prices: OfferPrice[] };
export async function getGigPrices(
  gigId: string,
  accessToken: string
): Promise<GigPricesResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/offers/${gigId}/prices`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, prices: data.data.prices };
  } catch (error) {
    return { success: false, prices: [] };
  }
}

export type GigRatingsResponse = { success: boolean; ratings: Rating[] };
export async function getGigRatings(
  gigId: string,
  accessToken: string
): Promise<GigRatingsResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/offers/${gigId}/ratings`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, ratings: data.data.ratings };
  } catch (error) {
    return { success: false, ratings: [] };
  }
}

export type GigCategoryResponse = {
  success: boolean;
  category: Category | null;
};
export async function getGigCategoryById({
  categoryId,
  accessToken,
}: {
  categoryId: string;
  accessToken: string;
}): Promise<GigCategoryResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/categories/${categoryId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, category: data.data.category };
  } catch (error) {
    return { success: false, category: null };
  }
}

export type GigSubcategoriesResponse = {
  success: boolean;
  subCategories: SubCategory[];
};
export async function getGigSubcategories({
  categoryId,
  accessToken,
}: {
  categoryId: string;
  accessToken: string;
}): Promise<GigSubcategoriesResponse> {
  try {
    const { data } = await axios.get(
      `${API_URL}/categories/${categoryId}/subcategories`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    return { success: true, subCategories: data.data.subCategories };
  } catch (error) {
    return { success: false, subCategories: [] };
  }
}

export type GigSubcategoryResponse = {
  success: boolean;
  subCategory: SubCategory | null;
};
export async function getGigSubcategoryById({
  subcategoryId,
  accessToken,
}: {
  subcategoryId: string;
  accessToken: string;
}): Promise<GigSubcategoryResponse> {
  try {
    const { data } = await axios.get(
      `${API_URL}/subcategories/${subcategoryId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    return { success: true, subCategory: data.data.category };
  } catch (error) {
    return { success: false, subCategory: null };
  }
}

export type GigTagResponse = { success: boolean; tag: Tag | null };
export async function getTagById(
  tagId: string,
  accessToken: string
): Promise<GigTagResponse> {
  try {
    const { data } = await axios.get(`${API_URL}/tags/${tagId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { success: true, tag: data.data.tag };
  } catch (error) {
    console.error("Error while fetching tag :: ", error);
    return { success: false, tag: null };
  }
}
export async function getTagsByIds(
  tagIds: string[],
  accessToken: string
): Promise<Tag[]> {
  try {
    // Use Promise.all to fetch all tags concurrently
    const tagPromises = tagIds.map((tagId) => getTagById(tagId, accessToken));
    const tagResponses = await Promise.all(tagPromises);

    // Filter out unsuccessful fetches and null tags, then map to the tag objects
    const tags = tagResponses
      .filter((response) => response.success && response.tag !== null)
      .map((response) => response.tag!); // '!' asserts that tag is not null due to the filter above

    return tags;
  } catch (error) {
    console.error("Error while fetching tags :: ", error);
    return [];
  }
}

export const getOfferFaqs = async (offerId: string): Promise<FAQ[]> => {
  const response = await axios.get(`${API_URL}/offers/${offerId}/faqs`);
  return response.data.data.faqs;
};

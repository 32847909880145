/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Box,
  Center,
  Flex,
  Image,
  Link as ChakraLink,
  Skeleton,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React from "react";
import { DO_SPACE } from "settings/constants/digitalOcean";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  children: JSX.Element;
}

// Functions
const AuthLayout: FC<Props> = ({ children }) => {
  const [loaded, setLoaded] = React.useState(false);
  return (
    <Box
      as="section"
      bg={mode("gray.50", "gray.800")}
      h="100vh"
      pos="relative"
      px={{ base: "6", lg: "12" }}
    >
      <Box
        display={{ base: "none", lg: "block" }}
        pos={{ lg: "absolute" }}
        insetY={{ lg: "0" }}
        insetEnd={{ lg: "0" }}
        w={{ lg: "50%" }}
        overflowX="hidden"
        height={{ base: "96", lg: "full" }}
        zIndex={2}
      >
        <Skeleton
          isLoaded={loaded}
          zIndex={2}
          objectFit="cover"
          flex="1"
          height="full"
        >
          <Image
            src={`${DO_SPACE.CND_ENDPOINT}/HR/APP/Login/modern_living_room_fitrkt.jpg`}
            opacity="100%"
            pos="absolute"
            zIndex={2}
            objectFit="cover"
            flex="1"
            height="full"
            onLoad={() => setLoaded(true)}
          />
        </Skeleton>
      </Box>
      <Box w={{ base: "full", lg: "50%" }} h={{ md: "100vh" }}>
        <Center justifyContent="center" h={{ md: "100vh" }}>
          <Stack w="full" align="center">
            <Flex
              h={{ md: "90vh" }}
              justify="center"
              overflowY={{ md: "auto" }}
              px={{ base: "4", md: "8" }}
              className="customScrollbar"
            >
              {children}
            </Flex>
            <Text fontSize="sm" align="center">
              © {new Date().getFullYear()}{" "}
              <ChakraLink href="https://homerender.io" isExternal>
                HomeRender Inc.
              </ChakraLink>{" "}
              All rights reserved.
            </Text>
          </Stack>
        </Center>
      </Box>
    </Box>
  );
};

export default AuthLayout;

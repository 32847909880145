import { format, formatDistanceToNow } from "date-fns";

export function formatDate(date: Date) {
  return formatDistanceToNow(date, {
    addSuffix: true,
    includeSeconds: true,
  });
}

export function formatSimpleDate(date: Date) {
  return format(date, "MMM dd, yyyy");
}

import { FC } from "react";
import {
  HStack,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
} from "@chakra-ui/react";

interface TabItem {
  label: string;
  isActive: boolean;
}
interface Props {
  data: TabItem[];
  onChangeIndex: (index: number) => void;
}
const PricingCardTabs: FC<Props> = ({ data, onChangeIndex }) => {
  const currIndex = data.findIndex((i) => i.isActive);
  if (data.length < 2) return null; // TODO: If there is only 1 pricing package then don't need to show that
  return (
    <HStack w="full">
      <Tabs
        position="relative"
        variant="unstyled"
        w="full"
        justifyContent="space-between"
        index={currIndex}
        onChange={onChangeIndex}
      >
        <TabList defaultValue={0} justifyContent="space-between">
          {data.map((item) => (
            <Tab key={item.label} color={item.isActive ? "blue.500" : "gray"}>
              {item.label}
            </Tab>
          ))}
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
      </Tabs>
    </HStack>
  );
};

export const SkeletonLoading = () => {
  return (
    <HStack>
      <Skeleton h="2.5rem" w="full" />
      <Skeleton h="2.5rem" w="full" />
      <Skeleton h="2.5rem" w="full" />
    </HStack>
  );
};

export default PricingCardTabs;

import { Container, Flex, Stack } from "@chakra-ui/react";
import ContentSection from "./ContentSection";
import useGig from "hooks/useGig";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import Alert from "components/features/Alert";
import { useParams } from "react-router-dom";
import OfferPricingCard from "./OfferPricingCard";

const OfferPage = () => {
  const params = useParams();
  const { data, isLoading, error } = useGig({ offerId: params.offerId });

  if (!!isLoading)
    return (
      <Container>
        <SpinnerLoading />
      </Container>
    );

  if (!!error)
    return (
      <Container maxW="container.xl" centerContent>
        <Alert
          showCloseIcon={false}
          status="error"
          title="Something went wrong while fetching gigs."
          w="max"
        />
      </Container>
    );

  return (
    <Stack spacing={4} bg="white">
      <Container maxW="container.xl" my={10} px={{ base: 5, md: 10 }}>
        {data && (
          <Flex gap={10} direction={{ md: "row-reverse", base: "column" }}>
            <Stack width={{ md: "40rem", base: "full" }} spacing={4}>
              <OfferPricingCard gigId={data?.gig.id} />
            </Stack>
            <ContentSection data={data.gig} />
          </Flex>
        )}
      </Container>
    </Stack>
  );
};

export default OfferPage;

import {
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  title: string;
  description: string;
  imgUrl: string;
}
const StyleCardPopover: FC<Props> = ({ title, description, imgUrl }) => {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button variant="ghost" color="blue.500">
          See details
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={{ base: "25rem", md: "40rem" }} shadow="dark-lg">
          <ScaleFade initialScale={0.9} in={true}>
            <PopoverArrow />
            <PopoverHeader fontWeight="bold" fontSize="lg">
              {title}
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Stack>
                <Image src={imgUrl} rounded="md" border="1px" />
                <Text fontSize="lg">{description}</Text>
              </Stack>
            </PopoverBody>
          </ScaleFade>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default StyleCardPopover;

/////////////////////
// Style Card Content used on Style Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";
import _ from "lodash";

// Design Imports
import { Badge, Box, Text } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports
import { StyleCardProps } from "data/projectWizardData/styleCardData";
import {
  ButtonRadioGroupImg,
  ButtonCheckboxGroupImg,
} from "../InputButtons/ButtonGroups";

// Interfaces and types
interface SubCardProps {
  option: StyleCardProps;
  onChange?: any;
  cardType?: string;
  selectedItem?: any;
  styleMetaData?: StyleCardProps[];
  showSelectedOnly?: boolean;
  isReadOnly?: boolean;
}
const SubStyleCardForSteps: FC<SubCardProps> = ({
  cardType,
  option,
  onChange,
  selectedItem,
  showSelectedOnly,
  isReadOnly,
}) => {
  const onlySelectedItems = _.intersectionBy(
    option.items,
    selectedItem,
    "value"
  );

  return (
    <Box maxW={{ base: "xl", md: "7xl" }} mx="auto" px={{ base: "6", md: "8" }}>
      {(option.title || option.description) && (
        <Box textAlign="center" mb="10">
          {option.title && (
            <Badge mt="8" px="3" py="1" variant="solid" colorScheme="blue">
              {option.title}
            </Badge>
          )}

          {option.description && (
            <Text maxW="2xl" mx="auto" my="3">
              {option.description}
            </Text>
          )}
        </Box>
      )}
      <Box mx="auto">
        {cardType === "radio" ? (
          <ButtonRadioGroupImg
            isReadOnly={isReadOnly}
            value={selectedItem?.[0]?.value || ""}
            options={showSelectedOnly ? onlySelectedItems : option.items}
            onChange={(value) => {
              const selectedItem = {
                ...option,
                items:
                  option?.items.filter((item) => item.value === value) || [],
              };
              onChange(selectedItem);
            }}
          />
        ) : (
          <ButtonCheckboxGroupImg
            isReadOnly={isReadOnly}
            value={selectedItem?.map((item) => item.value) || []}
            options={showSelectedOnly ? onlySelectedItems : option.items}
            atLeastOne={true}
            onChange={(value: string[]) => {
              const results = _.intersectionWith(
                option.items,
                value,
                (comparator1, comparator2) => comparator1.value === comparator2
              );
              onChange({ ...option, items: results });
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default SubStyleCardForSteps;

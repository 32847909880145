/**
 * constructPath generates a URL path based on the provided segments.
 *
 * @param {...string | undefined} segments - Any number of string arguments representing parts of the URL path.
 * If a segment is empty, null, or undefined, that part is not included in the final path.
 *
 * @returns {string} - The constructed URL path as a string. Each part of the path is separated by a '/',
 * and there is no trailing '/' at the end of the path.
 *
 * @example
 * const urlPath = constructPath(process.env.REACT_APP_CLOUDINARY_FOLDER, user._id, projectId, "0 Client Upload");
 * console.log(urlPath); // Prints the constructed path, excluding any parts with empty values.
 *
 * @function
 */
export default function constructPath(
  ...segments: (string | undefined)[]
): string {
  let url = "";
  for (const segment of segments) {
    if (segment) {
      // if segment is not empty, undefined, or null
      url += `${segment}/`;
    }
  }
  return url.slice(0, -1); // remove the trailing slash
}

import { useQuery } from "react-query";
import useAuth from "./useAuth";
import { getProfileById } from "services/profilesService";

const useProfile = ({ profileId }: { profileId: string }) => {
  const { auth }: any = useAuth();
  const accessToken = auth.accessToken;

  const profileQuery = useQuery(
    ["profiles", profileId],
    () => getProfileById(profileId, accessToken),
    {
      enabled: !!profileId,
    }
  );

  return profileQuery;
};

export default useProfile;

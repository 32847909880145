import { useQuery } from "react-query";
import { orderKeys } from "settings/constants/queryKeys";
import useAccessToken from "./useAccessToken";
import { getOrderById } from "services/orderService";

const useOrder = (id: string) => {
  const token = useAccessToken();
  return useQuery({
    queryKey: orderKeys(id),
    queryFn: () => getOrderById(id, token),
  });
};

export default useOrder;

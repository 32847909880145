/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { useEffect } from "react";

// Design Imports

// Layout and Section Imports

// Data Imports
import { SET_COMPLETION_STAGE } from "context/project/ProjectTypes";

// Custom Hooks and Services
import { getObjectFulFillmentPercentage } from "utils/object";

// Interfaces

// Functions
const useProjectCompletionStage = (state, dispatch) => {
  // INFO: For calculating the percentage of project completion
  useEffect(() => {
    let stage = 0;
    const {
      projectInfo,
      selectedAssets,
      selectedOutputs,
      selectedStyles,
      supportingDocuments,
    } = state.projectWizard;

    if (Object.keys(projectInfo)) {
      // It will check how many input fields has been filled
      const percentage: number = getObjectFulFillmentPercentage(projectInfo);
      stage += Math.round((percentage * 20) / 100);
    }

    if (selectedAssets.length > 0) stage += 20;
    if (selectedOutputs.length > 0) stage += 20;
    if (selectedStyles?.length > 0) stage += 20;
    if (supportingDocuments.length > 0) stage += 20;

    dispatch({ type: SET_COMPLETION_STAGE, payload: stage.toString() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.projectWizard]);

  return {
    completionStage: state.completionStage,
  };
};

export default useProjectCompletionStage;

import {
  API_URL,
  AUTHORIZED_TOKEN,
  REACT_APP_STRIPE_FINAL_PLAN,
  STRIPE_SECRET_KEY,
} from "settings/site-credentials";
import axios from "axios";
import { differenceInDays } from "date-fns";
import _ from "lodash";

const tokenAuth = AUTHORIZED_TOKEN;
// const pdStarterUSD = process.env.REACT_APP_STRIPE_PRODUCT_SELECTIONS;
// const pdProfessionalUSD = process.env.REACT_APP_STRIPE_PRODUCT_FLOORPLANS;
// const pdBusinessUSD = process.env.REACT_APP_STRIPE_PRODUCT_RENDERINGS;
// const pdStarterUSD = process.env.REACT_APP_STARTER_PLAN_USD_PD_ID;
// const pdProfessionalUSD = process.env.REACT_APP_PROFESSIONAL_USD_PD_ID;
// const pdBusinessUSD = process.env.REACT_APP_BUSINESS_PD_USD_ID;
// const pdStarterCAD = process.env.REACT_APP_STARTER_PLAN_CAD_PD_ID;
// const pdProfessionalCAD = process.env.REACT_APP_PROFESSIONAL_CAD_PD_ID;
// const pdBusinessCAD = process.env.REACT_APP_BUSINESS_PD_CAD_ID;
const finalPlanBuisness = REACT_APP_STRIPE_FINAL_PLAN;

export async function fetchAllStripePlans(token: string) {
  if (!API_URL && tokenAuth !== token) {
    throw new Error("Invalid environmental variable.");
  }
  let config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
    },
  };
  try {
    // All requests are now complete
    let [
      finalPlan,
      // professionalUSD,
      // businessUSD,
      // starterCAD,
      // professionalCAD,
      // businessCAD,
    ] = await axios.all([
      axios.get(
        `https://api.stripe.com/v1/products/${finalPlanBuisness}`,
        config
      ),
      // await axios.get(
      //   `https://api.stripe.com/v1/products/${pdProfessionalUSD}`,
      //   config
      // ),
      // axios.get(`https://api.stripe.com/v1/products/${pdBusinessUSD}`, config),
      // await axios.get(
      //   `https://api.stripe.com/v1/products/${pdStarterCAD}`,
      //   config
      // ),
      // await axios.get(
      //   `https://api.stripe.com/v1/products/${pdProfessionalCAD}`,
      //   config
      // ),
      // await axios.get(
      //   `https://api.stripe.com/v1/products/${pdBusinessCAD}`,
      //   config
      // ),
    ]);

    // let res = {
    //   finalPlan:
    //   // starterUSD: starterUSD,
    //   // professionalUSD: professionalUSD,
    //   // businessUSD: businessUSD,
    //   // starterCAD: starterCAD,
    //   // professionalCAD: professionalCAD,
    //   // businessCAD: businessCAD,
    // };
    return getPlanCards(finalPlan?.data);
  } catch (err) {
    return null;
  }
}

const getPlanCards = (plan: any) => {
  if (plan) {
    const metaData = plan.metadata;
    const price = Object.keys(metaData).map((key) => {
      return {
        price: metaData[key],
        ...plan,
        duration: _.split(key, "_")[0],
        currency: _.split(key, "_")[1],
      };
    });
    return price;
  }
  return null;
};

export async function giveRemainingDaysForSubscription(
  currentPeriodEnd: number,
  canceledAt: number
) {
  const result = differenceInDays(
    new Date(currentPeriodEnd * 1000), //currentPeriodEnd
    new Date(canceledAt * 1000) //canceledAt
  );
  if (result < 1) return 0;
  return result;
}

/////////////////////
// PageHeader (appPages) UI
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  BoxProps,
  Container,
  Heading,
  Stack,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props extends BoxProps {
  title: string;
}

// Functions
export default function PageHeader({ title, children, ...restProps }: Props) {
  return (
    <Box
      //as="pageheader"
      bg={mode("white", "gray.700")}
      pt="8"
      shadow="base"
      {...restProps}
    >
      <Container maxW="7xl">
        <Heading size="lg" mb="3" color={mode("black", "gray.400")}>
          {title}
        </Heading>
        <Stack
          direction="row"
          spacing="4"
          overflowX="scroll"
          className="hideScollbar"
        >
          {children}
        </Stack>
      </Container>
    </Box>
  );
}

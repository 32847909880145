import { useState, useRef, FC, useEffect } from "react";
import {
  Box,
  Tag,
  Input,
  TagCloseButton,
  TagLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";

interface Props {
  defaultValues?: string[];
  onAddEmail: (emails: string[]) => void;
}
const EmailTagsInput: FC<Props> = ({ defaultValues, onAddEmail }) => {
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (!defaultValues) return;
    setEmails(defaultValues);
  }, [defaultValues]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (input && /\S+@\S+\.\S+/.test(input)) {
        setEmails([...emails, input]);
        onAddEmail([...emails, input]);
        setInput("");
      }
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
    onAddEmail(emails.filter((_, i) => i !== index));
    inputRef.current.focus();
  };

  return (
    <FormControl id="email">
      <FormLabel>Additional Email</FormLabel>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        p="2"
      >
        {emails.map((email, index) => (
          <Tag key={index} mr="1" borderRadius="md" size="lg">
            <TagLabel>{email}</TagLabel>
            <TagCloseButton onClick={() => removeEmail(index)} />
          </Tag>
        ))}
        <Input
          ref={inputRef}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={
            emails.length
              ? ""
              : "Other email(s) that you want to email for notification aside from default account email"
          }
          border="none"
          flex="1"
          _focus={{
            boxShadow: "none", // This might help to remove any lingering focus styles
          }}
        />
      </Box>
      <FormHelperText textAlign="end">
        Press enter or comma to add additional email.
      </FormHelperText>
    </FormControl>
  );
};

export default EmailTagsInput;

"use client";

import { FC } from "react";
import {
  Card,
  Center,
  UseRadioProps,
  useRadio,
  Text,
  Wrap,
  useRadioGroup,
  Stack,
  StackProps,
} from "@chakra-ui/react";

export type RadioButtonOption = { label: string; value: string };
interface Props {
  label?: string;
  options: RadioButtonOption[];
  defaultValue: string;
  onChange: (value: string) => void;
  styles?: StackProps;
}
const RadioButtons: FC<Props> = ({
  label,
  options,
  defaultValue,
  onChange,
  styles,
}) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue,
    onChange,
  });

  return (
    <Stack spacing={2} {...styles}>
      {<Text fontWeight="medium">{label}</Text>}
      <Wrap {...getRootProps()} align="center" justify="center">
        {options.map((mood) => (
          <CustomRadio
            key={mood.value}
            label={mood.label}
            {...getRadioProps({ value: mood.value })}
          />
        ))}
      </Wrap>
    </Stack>
  );
};

interface CustomRadioProps extends UseRadioProps {
  label: string;
}
const CustomRadio: FC<CustomRadioProps> = (props) => {
  const { label, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

  return (
    <Card
      as="label"
      {...htmlProps}
      variant={state.isChecked ? "filled" : "outline"}
      px={3}
      py={2}
      bg={state.isChecked ? "blue.500" : "inherit"}
      cursor="pointer"
      fontWeight="semibold"
      borderColor={state.isChecked ? "white" : "inherit"}
    >
      <input {...getInputProps({})} hidden />
      <Center {...getRadioProps()}>
        <Text
          color={state.isChecked ? "white" : "inherit"}
          {...getLabelProps()}
        >
          {label}
        </Text>
      </Center>
    </Card>
  );
};

export default RadioButtons;

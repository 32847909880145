import { assets } from "data/projectWizardData";
import _ from "lodash";
import removeMongoDbIds from "utils/removeMongoDbIds";

export const project_wizard_schema = {
  id: "",
  projectInfo: {
    name: "Untitled",
    description: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US",
    bedrooms: "1",
    bathrooms: "1",
    stories: "1",
    size: "",
    width: "",
    depth: "",
    emails: []
  },
  selectedAssets: [],
  selectedOutputs: [],
  selectedStyles: [],
  supportingDocuments: [],
  hasProjectInitialized: false,
  completionStage: "0",
  projectStatus: "draft",
};

export function render_project_info__db_project(projectInfo) {
  return {
    name: projectInfo?.name || "",
    description: projectInfo?.description || "",
    emails: projectInfo?.emails || [],
    location: {
      city: projectInfo?.city || "",
      country: projectInfo?.country || "",
      addressLine1: projectInfo?.addressLine1 || "",
      addressLine2: projectInfo?.addressLine2 || "",
      province: projectInfo?.state || "",
      postalCode: projectInfo?.postalCode || "",
    },
    details: {
      bedrooms: projectInfo?.bedrooms || "",
      bathrooms: projectInfo?.bathrooms || "",
      stories: projectInfo?.stories || "",
      size: projectInfo?.size || "",
      width: projectInfo?.width || "",
      depth: projectInfo?.depth || "",
    },
  };
}

export function render_db_project__project_info(project) {
  const renderCountry = (country) => {
    if (!country) return "";
    if (country === "Canada") return "CA";
    return country;
  };

  return {
    name: project.name,
    streetAddress: "",
    description: project.description,
    emails: project.emails,
    addressLine1: project?.location?.addressLine1 || "",
    addressLine2: project?.location?.addressLine2 || "",
    city: project?.location?.city || "",
    country: renderCountry(project?.location?.country),
    state: project?.location?.province || "",
    postalCode: project?.location?.postalCode || "",
    bedrooms: project.details.bedrooms,
    bathrooms: project.details.bathrooms,
    stories: project.details.stories,
    size: project.details.size,
    width: project.details.width,
    depth: project.details.depth,
  };
}

export function render_project_wizard_schema_from_db_project(project) {
  const renderProject = {
    id: project._id,
    projectInfo: render_db_project__project_info(project),
    selectedAssets: _.intersectionBy(assets, project.selectedStyles, "title"),
    selectedOutputs: removeMongoDbIds(project.selectedOutputs),
    selectedStyles: removeMongoDbIds(project.selectedStyles).map((style) => ({
      ...style,
      types: removeMongoDbIds(style.types),
    })),
    // selectedStyles: project.selectedStyles,
    supportingDocuments: project.attachments || [],
    projectStatus: project.status,
  };

  return renderProject;
}

export function render_supporting_document__db_project(attachment) {
  return {
    id: attachment._id,
    name: attachment?.name || "Unknown",
    format: attachment.format,
    description: attachment.description,
    url: attachment.cdnUrl,
  };
}

/////////////////////
// ImageGallery UI (part of Chat)
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  AspectRatio,
  Box,
  Circle,
  HStack,
  IconButton,
  IconButtonProps,
  Image,
  Skeleton,
  Stack,
  StackProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

// Layout and Section Imports
import {
  ImageCarousel,
  ImageCarouselSlide,
  useImageCarousel,
} from "./ImageCarousel";
import PlaceholderImage from "components/sections/GigsSections/Image/PlaceholderImage";
import { Attachment } from "types/attachments.types";

// Data Imports

export type ElementType<T extends ReadonlyArray<unknown>> =
  T extends ReadonlyArray<infer ElementType> ? ElementType : never;

// Interfaces
interface ImageGalleryProps {
  images: Attachment[];
  aspectRatio?: number;
  rootProps?: StackProps;
}

// Functions
export const ImageGallery = (props: ImageGalleryProps) => {
  const { images, aspectRatio = 16 / 9, rootProps } = props;
  const [currentSlide, setCurrentSlide] = React.useState(0);
  images.sort((a, b) => (b.featured === true ? 1 : -1));

  const [ref, slider] = useImageCarousel({
    slides: {
      perView: 1, // Ensure only one image per slide
    },
    slideChanged: (slider) => {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const hasPrevious = currentSlide !== 0;
  const hasNext = currentSlide < images.length - 1;

  return (
    <Stack spacing="4" {...rootProps}>
      <Box
        position="relative"
        sx={{
          _hover: {
            "> button": {
              display: "inline-flex",
            },
          },
        }}
        rounded="md"
        overflow="hidden"
        border="1px"
        borderColor="gray.300"
      >
        <AspectRatio ratio={aspectRatio} maxW="100%">
          {images.length > 0 ? (
            <ImageCarousel ref={ref}>
              {images.map((image, i) => {
                return (
                  <ImageCarouselSlide key={i} id={image.id}>
                    <Image
                      src={image.cdnUrl}
                      objectFit="cover"
                      alt={image.name}
                      fallback={<Skeleton />}
                    />
                  </ImageCarouselSlide>
                );
              })}
            </ImageCarousel>
          ) : (
            <PlaceholderImage />
          )}
        </AspectRatio>

        {/* Left Icon */}
        {hasPrevious && (
          <ImageCarouselIconButton
            pos="absolute"
            left="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => {
              slider.current?.prev();
            }}
            icon={<IoChevronBackOutline />}
            aria-label="Previous Slide"
          />
        )}

        {/* Right Icon */}
        {hasNext && (
          <ImageCarouselIconButton
            pos="absolute"
            right="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
          />
        )}

        {/* Ref Icons */}
        <HStack
          position="absolute"
          width="full"
          justify="center"
          bottom="0"
          py="4"
        >
          {images.map((_, index) => (
            <Circle
              key={index}
              border="1px"
              size="3"
              shadow="lg"
              cursor="pointer"
              bg={currentSlide === index ? "blue.500" : "white"}
              onClick={() => {
                setCurrentSlide(index);
                slider.current?.moveToIdx(index);
              }}
            />
          ))}
        </HStack>
      </Box>
    </Stack>
  );
};

const ImageCarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    display="none"
    fontSize="lg"
    isRound
    boxShadow="base"
    bg={mode("white", "gray.800")}
    _hover={{
      bg: mode("gray.100", "gray.700"),
    }}
    _active={{
      bg: mode("gray.200", "gray.600"),
    }}
    _focus={{ boxShadow: "inerhit" }}
    _focusVisible={{ boxShadow: "outline" }}
    {...props}
    color="blue.500"
  />
);

/////////////////////
// Login Form
/////////////////////

// Basic Imports
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import {
  Button,
  Checkbox,
  Flex,
  HStack,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";

import { GoogleIcon } from "../../../ui/common/ProviderIcons";

// Layout and Section Imports
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuthForm, { LoginFormTypes } from "../useAuthForm";
import { useRef, useState } from "react";
import { API_URL } from "settings/site-credentials";
import FormInputField from "components/forms/ReactHookForm/FormInputField";
import ConsentCheckbox from "../ConsentCheckbox";
import TurnTileWidget from "pages/AuthPage/TurnsTile/TurnTile";

// Interface

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
  password: yup.string().min(6).max(20).label("Password"),
});

export const LoginForm = (props: StackProps) => {
  const methods = useForm<LoginFormTypes>({ resolver: yupResolver(schema) });
  const { user, handleSignin } = useAuthForm();
  const formContainer = useRef(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [verified, setVerified] = useState(false);

  const getDisabledStatus = () => {
    if (!!isAgreed && !!verified) return false;

    return true;
  };

  return (
    <Stack spacing="6" {...props}>
      <FormHeader title="Login to your account" />
      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(handleSignin)}>
          <Stack ref={formContainer} spacing="5">
            <FormInputField
              type="email"
              name="email"
              label="Email"
              placeholder="e.g., john@email.com"
            />
            <FormInputField
              name="password"
              type="password"
              label="Password"
              placeholder="Password length between 6 and 20 characters"
            />
          </Stack>
          <Flex justify="space-between" alignItems="center">
            <HStack spacing="1" justify="center">
              <Text color="muted">Don't have an account?</Text>
              <Button
                as={Link}
                to={ROUTES.SIGN_UP}
                variant="link"
                colorScheme="blue"
              >
                Sign up
              </Button>
            </HStack>

            <Button
              as={Link}
              to={ROUTES.FORGOT}
              variant="link"
              colorScheme="blue"
              size="sm"
            >
              Forgot password
            </Button>
          </Flex>
          <Stack spacing={2}>
            <Checkbox defaultChecked>Remember me</Checkbox>
            <ConsentCheckbox isChecked={isAgreed} onChange={setIsAgreed} />
            <TurnTileWidget setVerified={setVerified} />
          </Stack>
          <Stack spacing="4">
            <Button
              // isDisabled={getDisabledStatus()} // TODO: Enable this feature when ready for production
              variant="primary"
              color="white"
              bg="blue.500"
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={user.isLoading}
            >
              Sign in
            </Button>

            <Button
              variant="secondary"
              border="1px"
              borderColor="gray.200"
              isDisabled={getDisabledStatus()}
              leftIcon={<GoogleIcon boxSize="5" />}
              iconSpacing="3"
              onClick={() => {
                window.open(`${API_URL}/auth/google`, "_self");
              }}
            >
              Sign in with Google
            </Button>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </Stack>
  );
};

/////////////////////
// Toggle Button UI (used in Toggle Button Groups)
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Button,
  ButtonProps,
  Text,
  UseRadioProps,
  useId,
  useRadio,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
export interface ToggleButtonProps extends ButtonProps {
  value: string;
  radioProps?: UseRadioProps;
}

// Functions
export const ToggleButton = (props: ToggleButtonProps) => {
  const { value, radioProps, isDisabled, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps } =
    useRadio(radioProps);
  const id = useId(undefined, "toggle-button");

  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();

  return (
    <Box as="label" cursor="pointer" {...labelProps}>
      <input {...inputProps} aria-labelledby={id} />
      <Button
        as="div"
        id={id}
        size="xs"
        color={mode("gray.600", "whiteAlpha.700")}
        _checked={{
          color: mode("white", "whiteAlpha.900"),
          bg: mode("blue.400", "blue.300"),
        }}
        isDisabled={isDisabled}
        {...checkboxProps}
        {...rest}
      >
        <Text color={mode("inherit", "inherit")}>{value}</Text>
      </Button>
    </Box>
  );
};

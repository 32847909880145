/////////////////////
// Plan Page
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Flex,
  SimpleGrid,
  //  useColorModeValue as mode,
} from "@chakra-ui/react";

import { PricingCard } from "./PricingCards";

// Layout and Section Imports

// Data Imports
import { RootStateOrAny, useSelector } from "react-redux";

// Interfaces

// Functions

const PlanPage = () => {
  const planDuration = useSelector(
    (state: RootStateOrAny) => state.plan.planDuration
  );
  return (
    <Box as="section" py="0">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "2", md: "2" }}
      >
        <Flex
          align="center"
          justify={{ base: "center", lg: "flex-start" }}
          my="10"
        >
          {/* <DurationSwitch defaultValue="yearly" /> */}
        </Flex>
        <SimpleGrid mt="10" columns={{ base: 1, lg: 3 }} spacing="10">
          <PricingCard
            bg="gray.700"
            color="white"
            data={{
              popular: true,
              name: "Starter",
              save: 0,
              // TODO: Change description
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
              price: 499,
              duration: planDuration,
              benefits: [
                "2D Floor Plans",
                "Blueprint Edits",
                "Drafting Services",
                "Landscaping Plans",
                "Brochures",
                "Feature Sheets",
              ],
            }}
          />
          <PricingCard
            bg="blue.600"
            color="white"
            data={{
              popular: false,
              name: "Professional",
              save: 0,
              // TODO: Change description
              description:
                "At erat pellentesque adipiscing commodo elit at imperdiet.",
              price: 999,
              duration: planDuration,
              benefits: [
                "Everything from Starter Plan",
                "3D Floor Plans",
                "3D Interior Renderings",
                "3D Exterior Renderings",
                "3D Modelling",
                "Virtual Staging",
              ],
            }}
          />
          <PricingCard
            bg="gray.700"
            color="white"
            data={{
              popular: false,
              name: "Business",
              save: 0,
              // TODO: Change description
              description:
                "Proin sagittis nisl rhoncus mattis rhoncus urna neque.",
              price: 1999,
              duration: planDuration,
              benefits: [
                "Everything from Pro Plan",
                "Multiple Designers",
                "Separate Work Queue",
                "Prioritized Support",
              ],
            }}
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default PlanPage;

import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { FAQ } from "types/faq.types";
import { Offer } from "types/offer.types";
import { Requirement } from "types/requirement.types";
import { Review } from "types/review.types";

export async function getAllOffers(
  accessToken: string,
  searchText: string = "",
  categoryId: string = ""
): Promise<Offer[]> {
  let url = `${API_URL}/offers`;
  const params = new URLSearchParams();
  if (searchText) {
    params.append("search", encodeURIComponent(searchText));
  }

  if (categoryId) {
    params.append("category", categoryId);
  }

  const queryString = params.toString();
  if (queryString) {
    url = `${url}?${queryString}`;
  }

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data.data.offers;
}

export async function getOfferReviews(
  offerId: string,
  accessToken: string
): Promise<Review> {
  const response = await axios.get(`${API_URL}/offers/${offerId}/reviews`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.reviews;
}

export const getOfferFaqs = async (
  offerId: string,
  accessToken: string
): Promise<FAQ[]> => {
  const response = await axios.get(`${API_URL}/offers/${offerId}/faqs`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.faqs;
};

export const getOfferRequirements = async (
  offerId: string,
  accessToken: string
): Promise<Requirement[]> => {
  const response = await axios.get(
    `${API_URL}/offers/${offerId}/requirements`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response.data.data.requirements;
};

import { Badge } from "@chakra-ui/react";
import { FC } from "react";
import { OrderStatus } from "types/order.types";

interface Props {
  status: OrderStatus;
}
const RenderOrderStatus: FC<Props> = ({ status }) => {
  if (status === "cancelled") return <Badge colorScheme="red">{status}</Badge>;
  if (status === "completed")
    return <Badge colorScheme="green">{status}</Badge>;
  if (status === "delivered")
    return <Badge colorScheme="green">{status}</Badge>;
  if (status === "pending") return <Badge colorScheme="orange">{status}</Badge>;
  if (status === "in_progress")
    return <Badge colorScheme="blue">In Progress</Badge>;
  return <Badge colorScheme="blue">{status}</Badge>;
};

export default RenderOrderStatus;

import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonProps,
} from "@chakra-ui/react";

interface Props {
  title?: string;
  description?: string;
  isOpen: boolean;
  confirmButtonColorScheme?: ButtonProps["colorScheme"];
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
}
const ConfirmationModal: FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  confirmButtonColorScheme = "red",
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title || "Are you sure ?"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {description || "Once confirmed, you cannot undo those changes."}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={2} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={confirmButtonColorScheme}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModal;

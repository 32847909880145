/////////////////////
// Sign Up Page
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";
import { SignupForm } from "components/forms/AuthForm/SignupForm";
import { Container, Text } from "@chakra-ui/react";
import { UNDER_ATTACK } from "settings/site-credentials";

// Data Imports

// Interfaces

// Custom Hooks and Services

// Functions
const SignUpPage = () => {
  if (UNDER_ATTACK) {
    return (
      <Container centerContent>
        <Text> We will be back soon!</Text>
      </Container>
    );
  }

  return (
    <AuthLayout>
      <SignupForm
        // px={{ base: "4", md: "8" }}
        // py={{ base: "6", md: "26" }}
        width="full"
        maxW="md"
      />
    </AuthLayout>
  );
};

export default SignUpPage;

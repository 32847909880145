import axios from "axios";
import { API_URL } from "settings/site-credentials";

export function getBillingInvoices() {
  return null;
}

export async function createCheckoutSession({
  priceId,
  offerId,
  accessToken,
}: {
  priceId: string;
  offerId: string;
  accessToken: string;
}) {
  const { data } = await axios.post(
    `${API_URL}/billings/create-checkout-session`,
    {
      priceId,
      offerId,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data.data.session;
}

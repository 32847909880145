import axios from "api/axios";
// import { ActiveImage } from "components/sections/ProjectsSection/Steps/ESupportingDocuments/UploadingForm";
import { API_URL } from "settings/site-credentials";
import { CreateAttachmentService } from "types/attachments.types";
import { Attachment, UpdateAttachmentService } from "types/attachments.types";

export interface MediaResponse {
  fileName: string;
  type: string;
  url: string;
  size: number;
}

interface CreateProjectAttachmentService {
  mediaResponse: File;
  mediaDescription: string;
  projectId: string;
  userToken: string;
  folderName?: string;
}

//${userId}/${resourceType}/${resourceId}/${new Date().toISOString()}-${file.originalname}
export async function createAttachmentService({
  mediaResponse,
  mediaDescription,
  projectId,
  userToken,
  folderName,
}: CreateProjectAttachmentService) {
  const formData = new FormData();
  if (mediaDescription) {
    formData.append("description", mediaDescription);
  }
  if (projectId) {
    formData.append("projectId", projectId);
  }
  if (folderName) {
    formData.append("folderName", folderName);
  }
  formData.append("files", mediaResponse);

  try {
    const { data } = await axios.post(`${API_URL}/attachments`, formData, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

// This function is latest one that is being used in useGalleryForm
export async function createAttachment(
  {
    mediaResponse,
    folderName,
    resourceId,
    isFeatured,
    resourceType,
  }: CreateAttachmentService,
  accessToken: string
): Promise<Attachment> {
  const formData = new FormData();

  if (folderName) {
    formData.append("folderName", folderName);
  }
  if (resourceId) {
    formData.append("resourceId", resourceId);
  }
  if (isFeatured) {
    formData.append("featured", String(isFeatured));
  }
  formData.append("files", mediaResponse);
  if (resourceType) formData.append("referenceType", String(resourceType));

  const response = await fetch(`${API_URL}/attachments`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`, // Assuming 'userToken' is already defined
    },
  });

  const resData = await response.json(); // Assuming the server responds with JSON.
  return resData.data.attachment;
}

export async function createMultipleAttachments(
  attachments: CreateAttachmentService[],
  accessToken: string,
  resourceType?: string
): Promise<{ success: boolean; attachments: Attachment[] }> {
  let promises = attachments.map((file) => {
    return createAttachment(
      {
        mediaResponse: file.mediaResponse,
        folderName: file.folderName,
        resourceId: file.resourceId,
        isFeatured: file.isFeatured,
        resourceType,
      },
      accessToken
    );
  });

  try {
    const allAttachmentsRes = await Promise.all(promises);

    return {
      success: true,
      // attachments: allAttachmentsRes.map((att) => att.data.data.attachment),
      attachments: allAttachmentsRes,
    };
  } catch (error) {
    console.log({ error });
    return { success: false, attachments: [] };
  }
}

export async function fetchAttachmentByIdService(attachmentId, userToken) {
  try {
    const { data } = await axios.get(`${API_URL}/attachments/${attachmentId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function deleteAttachmentByIdService(attachmentId, userToken) {
  try {
    const { data } = await axios.delete(
      `${API_URL}/attachments/${attachmentId}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function getOfferAttachments(
  offerId: string,
  accessToken: string
): Promise<Attachment[]> {
  const response = await axios.get(`${API_URL}/offers/${offerId}/attachments`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.attachments;
}

export async function getAttachmentById(
  id: string,
  accessToken: string
): Promise<Attachment> {
  const response = await axios.get(`${API_URL}/attachments/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.attachment;
}

export async function getMultipleAttachmentsByIds(
  ids: string[],
  accessToken: string
): Promise<Attachment[]> {
  let promises = ids.map((id) => {
    return getAttachmentById(id, accessToken);
  });

  const allAttachmentsRes = await Promise.all(promises);
  return allAttachmentsRes;
}

export async function deleteAttachment(
  id: string,
  accessToken: string
): Promise<Attachment> {
  const { data } = await axios.delete(`${API_URL}/attachments/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return data.data;
}
export async function deleteMultipleAttachments(
  ids: string[],
  accessToken: string
) {
  let promises = ids.map((id) => {
    return deleteAttachment(id, accessToken);
  });

  try {
    const allAttachmentsRes = await Promise.all(promises);
    return {
      success: true,
      attachments: allAttachmentsRes,
    };
  } catch (error) {
    console.log({ error });
    return { success: false, attachments: [] };
  }
}

export async function updateAttachment(
  attachmentId: string,
  body: Partial<Attachment>,
  accessToken: string
): Promise<Attachment> {
  const res = await axios.put(`${API_URL}/attachments/${attachmentId}`, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return res.data.data.attachment;
}

export async function updateMultipleAttachments(
  attachments: UpdateAttachmentService[],
  accessToken: string
) {
  let promises = attachments.map((file) => {
    return updateAttachment(file.attachmentId, file.body, accessToken);
  });

  return Promise.all(promises);
}

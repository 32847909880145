/////////////////////
// New Project Page
/////////////////////

// Basic Imports
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Design Imports
import { useSteps } from "components/ui/steps/useSteps";
import { useToast } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports
import { useProject } from "context/project/ProjectProvider";
import { project_table_schema } from "schemas/project_table";
import useFetchProjects from "hooks/useFetchProjects";
import { fetchDraftProjectsAxiosRequest } from "services/projectsService";
import { REACT_QUERY_KEYS } from "settings/constants/queryKeys";

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { updateArray } from "utils/arrays";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS } from "settings/constants/toastMessages";
import { ProjectStatusesProps } from "types/project";
import { STATUS } from "settings/constants/project";

// Interfaces
interface Props {
  projectId?: string;
  shouldFetchDraftProjects?: boolean;
}
export interface BtnLoadingProps {
  btn:
    | ""
    | "revert"
    | "save"
    | "delete"
    | "draft"
    | "close"
    | "step"
    | ProjectStatusesProps;
  isLoading: boolean;
}

// Functions
const useNewProjectPage = (props?: Props) => {
  const [btnLoading, setBtnLoading] = useState<BtnLoadingProps>({
    btn: "",
    isLoading: false,
  });
  const { auth }: any = useAuth();
  const {
    nextStep,
    prevStep,
    activeStep,
    reset: resetSteps,
    setStep,
  } = useSteps({ initialStep: 0 });
  const { projects, isLoading, onChangePriorities, setProjects } =
    useFetchProjects(
      REACT_QUERY_KEYS.DRAFT_PROJECTS,
      () => fetchDraftProjectsAxiosRequest(auth?.accessToken || ""),
      { enabled: !!props?.shouldFetchDraftProjects }
    );

  const navigate = useNavigate();
  const toast = useToast();
  const { errorToast } = useCustomToast();
  const projectContext = useProject();
  const projectId = props?.projectId || "";

  // useEffect triggers on changing project wizard
  useEffect(() => {
    // If project closes or deletes then default step should be first one
    if (!projectContext.projectWizard.hasProjectInitialized) resetSteps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext.projectWizard.hasProjectInitialized]);

  // Handlers
  const renderProjectPage = (status) => {
    if (status === STATUS.DELETE || status === STATUS.ACTIVE || !status)
      return "/projects";
    return `/projects/${status}`;
  };

  const addDraftProjectIntoDB = async (props?: {
    btnPressed?: "draft" | "close" | "step";
    shouldRedirect?: boolean;
  }) => {
    const btnPressed = props?.btnPressed;

    setBtnLoading({ btn: btnPressed || "draft", isLoading: true });
    const { data, error, statusCode } =
      await projectContext.onSaveProjectWizardAsDraft({
        shouldTrackActivity: !!projectId,
      });
    setBtnLoading({ btn: btnPressed || "draft", isLoading: false });

    if (!!error) {
      errorToast({ title: "Something went wrong..." });
      return;
    }

    const project = data.data.project;

    if (!!props?.shouldRedirect) {
      return navigate(`${renderProjectPage(project.status)}`);
    }

    // 200 --> Updated successfully
    if (statusCode === 200) {
      setProjects((projects) => {
        const targetIndex = projects.findIndex((p) => p.id === project._id);
        const updatedProjects = updateArray(
          projects,
          targetIndex,
          project_table_schema(project)
        );
        return updatedProjects;
      });
      return;
    }
    // 201 --> Created successfully
    if (statusCode === 201) {
      setProjects((projects) => [project_table_schema(project), ...projects]);
    }
  };

  const submitProjectIntoDB = async (props?: {
    projectStatus?: ProjectStatusesProps;
    shouldRedirect?: boolean;
    shouldDefaultProjectStatus?: boolean;
  }) => {
    const projectStatus = props?.projectStatus;
    const shouldRedirect = props?.shouldRedirect || false;
    const shouldDefaultProjectStatus =
      props?.shouldDefaultProjectStatus || false;

    setBtnLoading({ btn: projectStatus || "save", isLoading: true });
    const { data, error } = await projectContext.onSubmitProjectWizard({
      projectStatus: shouldDefaultProjectStatus
        ? projectContext.projectWizard.projectStatus
        : projectStatus,
      shouldTrackActivity: !!projectId,
    });
    setBtnLoading({ btn: projectStatus || "save", isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    if (!!shouldRedirect) {
      projectContext.onCloseProjectWizard();
      navigate(renderProjectPage(data.data.project.status));
    }
  };

  const deleteProjectFromDB = async (props?: { shouldRedirect: boolean }) => {
    setBtnLoading({ btn: "delete", isLoading: true });
    const { data, error } = await projectContext.onDeleteProjectWizard();
    setBtnLoading({ btn: "delete", isLoading: false });

    if (!!props?.shouldRedirect) {
      return navigate("/projects");
    }

    if (!!error) {
      errorToast({ title: ERRORS.DELETE_PROJECT });
      return;
    }

    if (!!data && !error) {
      const project = data.data;

      const filteredProjects = projects.filter((p) => p.id !== project.id);
      setProjects(filteredProjects);
    }
  };

  const saveUpdatedProject = async () => {
    setBtnLoading({ btn: "save", isLoading: true });
    const { error } = await projectContext.onSaveUpdatedProjectWizard({
      shouldTrackActivity: !!props.projectId,
    });
    setBtnLoading({ btn: "save", isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.UPDATE_PROJECT });
      return;
    }
  };

  return {
    btnLoading,
    setBtnLoading,
    stepState: { nextStep, prevStep, activeStep, reset: resetSteps, setStep },
    auth,
    draftProjects: { projects, isLoading, onChangePriorities, setProjects },
    projectContext,
    toast,
    navigate,
    handlers: {
      addDraftProjectIntoDB,
      submitProjectIntoDB,
      deleteProjectFromDB,
      saveUpdatedProject,
    },
  };
};

export default useNewProjectPage;

/////////////////////
// Use Auth Form
/////////////////////

// Basic Imports
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Design Imports
import { useBreakpointValue } from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { changeEmail } from "app/userReducer";

// Layout and Section Imports

// Data Imports
import { fetchProjectsService } from "services/projectsService";
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuth from "hooks/useAuth";

import {
  forgotPassword,
  getUserAccountDetails,
  resetPassword,
  sendVerificationEmail,
  signin,
  signup,
  storeCustomerEmailIntoLocalStorage,
  storeCustomerTokenIntoLocalStorage,
  verification,
} from "services/authService";

import { WrapIntoTryCatch } from "utils/wrapIntoTryCatch";
import { logoutUserServices } from "services/userProfileService";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";

// Interfaces
export type SignUpUserTypes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean;
  error: string;
};
interface OptionTypes {
  hideToast?: boolean;
}
export interface LoginFormTypes {
  email: string;
  password: string;
}

export interface SignupFormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export interface ForgotFormTypes {
  email: string;
}
export interface ResetFormTypes {
  password: string;
  confirmPassword: string;
}

// Functions
const useAuthForm = () => {
  // @ts-ignore
  const { setAuth, user: userDetails, auth } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { errorToast, successToast } = useCustomToast();
  const navigate = useNavigate();
  const [hasUserSignedUp, setHasUserSignedUp] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isEmailSentForReset, setIsEmailSentForReset] = useState(false);
  const { token } = useParams();

  // State management (component level)
  const [user, setUser] = useState<SignUpUserTypes>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isLoading: false,
    error: null,
  });

  /**
   * Utility handlers
   */
  const resetUser = () => {
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      error: null,
    });
    setHasUserSignedUp(false);
    setIsUserVerified(false);
    setIsEmailSentForReset(false);
  };
  const setEmail = (email: string) => setUser({ ...user, email });
  const dispatch = useDispatch();
  // const setPassword = (password: string) => setUser({ ...user, password });
  const setLoading = (isLoading: true | false) =>
    setUser({ ...user, isLoading });
  // const setFirstName = (firstName: string) => setUser({ ...user, firstName });
  // const setLastName = (lastName: string) => setUser({ ...user, lastName });
  // const setConfirmPassword = (confirmPassword: string) =>
  // setUser({ ...user, confirmPassword });
  const setError = (error: string) => setUser({ ...user, error });
  const resetError = () => setUser({ ...user, error: "" });

  const callWaiter = async (fn: Function, options?: OptionTypes) => {
    setLoading(true);
    const { response, error } = await fn();
    setLoading(false);

    // 2.2 Error (show error message)
    if (!!error) {
      if (!options?.hideToast) {
        errorToast({ title: error.message });
      }

      return { error };
    }

    return { response };
  };
  const onBoardingUser = async ({ accessToken }) => {
    // After login user should be navigated to the projects, if there is no project then just hit wizard page
    const { data: projects, error: projectsError } = await fetchProjectsService(
      accessToken
    );
    const projectsLength =
      (!projectsError && projects.data.projects.length) || 0;
    successToast({ title: SUCCESS.LOGGED_IN });

    // Fetch user account details using logged in auth token
    const { data, error } = await WrapIntoTryCatch(() =>
      getUserAccountDetails(accessToken)
    );
    if (!!error) {
      // toast({ title: "Something went wrong...", status: "error" });
      errorToast({ title: ERRORS.GENERIC });
      return;
    }
    // Check if user data available otherwise show doesn't let it go ahead
    const userAccount = data?.data?.user || null;
    if (!userAccount) return errorToast({ title: ERRORS.ACCOUNT });

    //Setting user email in redux store
    dispatch(changeEmail(userAccount.email));
    storeCustomerEmailIntoLocalStorage(userAccount.email);
    setEmail(userAccount.email);
    setAuth((prev) => ({ ...prev, accessToken }));
    storeCustomerTokenIntoLocalStorage(accessToken);
    resetUser();
    // @ts-ignore
    // fetchUser();

    if (userAccount?.subscription?.plan === "none") {
      navigate(ROUTES.ACCOUNT_BILLING, { replace: true });
      return;
    }

    const link =
      projectsLength > 0 ? ROUTES.PROJECTS_ACTIVE : ROUTES.PROJECTS_NEW;
    navigate(link, { replace: true });
  };

  /***
   * Form handlers
   */
  // const handleSignin = async (e: FormEvent) => {
  // e.preventDefault();
  const handleSignin = async (formData: LoginFormTypes) => {
    const { response, error } = await callWaiter(() =>
      signin({
        email: formData.email,
        password: formData.password,
      })
    );

    if (!!error) return;

    // It will remove all old values in localstorage
    logoutUserServices();
    onBoardingUser({ accessToken: response?.data?.token });
  };

  // const handleSignup = async (e: FormEvent) => {
  // e.preventDefault();
  const handleSignup = async (formData: SignupFormTypes) => {
    // If password doesn't match with confirm password.
    if (formData.password !== formData.confirmPassword) {
      setError("Confirm password doesn't match!");
      return;
    }
    // If password matches with confirm password then let it go next
    if (formData.password === formData.confirmPassword) resetError();

    const { error } = await callWaiter(() =>
      signup({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      })
    );

    if (!!error) return;

    setHasUserSignedUp(true);
  };

  const handleVerifyAccount = async (token: string) => {
    const { response, error } = await verification({ token });

    // Show error toast and alert
    if (!!error && !response) {
      return false;
    }

    return true;
  };

  const handleSendVerificationEmail = async () => {
    const { response, error } = await sendVerificationEmail({
      email: userDetails.email,
      authToken: auth.accessToken,
    });

    // Show error toast and alert
    if (!!error && !response) {
      return { status: error.statusCode };
    }

    return { status: 200 };
  };

  // const handleResetPassword = async (token: string) => {
  const handleResetPassword = async (formData: ResetFormTypes) => {
    if (formData.password !== formData.confirmPassword) {
      setError("Confirm password doesn't match!");
      return;
    }

    const { response, error } = await callWaiter(
      () =>
        resetPassword({
          token,
          newPassword: formData.password,
          confirmPassword: formData.confirmPassword,
        }),
      { hideToast: true }
    );

    // Show error toast and alert
    if (!!error && !response) {
      errorToast({ title: ERRORS.INVALID_TOKEN });

      return;
    }

    successToast({
      title: SUCCESS.PASSWORD_RESET,
      description: "Login using newly created password.",
    });
    navigate(ROUTES.LOGIN_IN, { replace: true });
  };

  // const handleForgotPassword = async () => {
  const handleForgotPassword = async (formData: ForgotFormTypes) => {
    const { error } = await callWaiter(() =>
      forgotPassword({
        email: formData.email,
      })
    );

    if (!!error) {
      return;
    }

    setIsEmailSentForReset(true);
  };

  return {
    isMobile,
    hasUserSignedUp,
    setHasUserSignedUp,
    isEmailSentForReset,
    setIsEmailSentForReset,
    handleSignup,
    handleSignin,
    handleForgotPassword,
    handleVerifyAccount,
    handleSendVerificationEmail,
    handleResetPassword,
    isUserVerified,
    setError,
    resetError,
    user,
    emailSentLoading: user.isLoading || false,
  };
};

export default useAuthForm;

/////////////////////
// Reset (Password) Form
/////////////////////

// Basic Imports
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import { Button, Stack, StackProps } from "@chakra-ui/react";

// Layout and Section Imports
import Alert from "components/features/Alert";
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuthForm, { ResetFormTypes } from "../useAuthForm";
import FormInputField from "components/forms/ReactHookForm/FormInputField";

// Interfaces

// Functions

const schema = yup.object().shape({
  password: yup.string().min(6).max(20).label("Password"),
  confirmPassword: yup.string().min(6).max(20).label("Confirm password"),
});

export const ResetForm = (props: StackProps) => {
  const methods = useForm<ResetFormTypes>({ resolver: yupResolver(schema) });
  const { user, setError, handleResetPassword } = useAuthForm();
  return (
    <Stack spacing="8" {...props}>
      <FormHeader
        title="Reset Password"
        description="Already have an account?"
        link={{
          label: "Sign in",
          to: ROUTES.LOGIN_IN,
        }}
      />
      {!!user.error && (
        <Alert status="error" onClose={() => setError("")} title={user.error} />
      )}

      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(handleResetPassword)}>
          <Stack spacing="5">
            <FormInputField
              name="password"
              type="password"
              label="Password"
              placeholder="Enter your password"
            />
            <FormInputField
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              placeholder="Confirm your password"
            />
          </Stack>
          <Stack spacing="4">
            <Button
              variant="primary"
              color="white"
              bg="blue.500"
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={user.isLoading}
            >
              Submit
            </Button>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </Stack>
  );
};

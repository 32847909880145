import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  SimpleGrid,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import React, { ForwardRefRenderFunction } from "react";

interface Props extends TextareaProps {
  label: string;
  name: string;
  errorMessage?: string;
  currLength?: number;
  isDisabled?: boolean;
}
const TextareaField: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
  { label, name, errorMessage, currLength, isDisabled, ...restProps },
  ref
) => {
  return (
    <FormControl isInvalid={!!errorMessage} isDisabled={isDisabled}>
      <FormLabel>{label}</FormLabel>
      <Textarea ref={ref} name={name} {...restProps} />

      <SimpleGrid
        columns={{ base: 1, md: restProps.maxLength ? 2 : 1 }}
        spacing={{ base: 1, md: 2 }}
      >
        <GridItem colSpan={1}>
          <FormErrorMessage w="full">{errorMessage}</FormErrorMessage>
        </GridItem>
        {restProps.maxLength && (
          <GridItem colSpan={1} display="flex" justifyContent="flex-end">
            <FormHelperText justifySelf="flex-end">
              ({currLength ? `${currLength}/` : ""}
              {restProps.maxLength}) characters max.
            </FormHelperText>
          </GridItem>
        )}
      </SimpleGrid>
    </FormControl>
  );
};
export default React.forwardRef(TextareaField);

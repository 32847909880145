import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";
import { FaClock, FaRegClock } from "react-icons/fa6";

interface Props extends IconProps {
  variant?: "default" | "outline";
}
const ClockIcon: FC<Props> = ({ variant = "default", ...rest }) => {
  if (variant === "outline") return <Icon as={FaRegClock} {...rest} />;
  return <Icon as={FaClock} {...rest} />;
};

export default ClockIcon;

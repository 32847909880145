/////////////////////
// Draggable Table Row
/////////////////////

// Basic Imports

// Design Imports
import { Td, Tr } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Interfaces

// Functions
export const DraggableTableRow = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
    isOver: isDraggingOver,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <Tr rounded="md" ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <Td bg="rgba(127, 207, 250, 0.3)" colSpan={row.cells.length}>
          &nbsp;
        </Td>
      ) : (
        row.cells.map((cell) => {
          if (cell.column.id === "priority") {
            return (
              <Td bg="white" {...cell.getCellProps()}>
                <span>
                  {cell.render("Cell", {
                    sortingAttributes: attributes,
                    sortingListeners: listeners,
                    isDraggingOver,
                  })}
                </span>
              </Td>
            );
          }
          return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
        })
      )}
    </Tr>
  );
};

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import { FilePreview, ImageEditor, Dropzone } from "hr-design-system";
import { useProject } from "context/project/ProjectProvider";
import useAuth from "hooks/useAuth";
import React, { FC, useEffect, useRef, useState } from "react";
import { BiEdit, BiPlus, BiSend } from "react-icons/bi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "styles/react-quill.css";
import useCustomToast from "hooks/useCustomToast";
import { createAttachmentService } from "services/attachmentsService";

interface Props {
  uploadLoading: boolean;
  onUploadComment: (data: any) => Promise<any>;
  fileToEdit?: any | null;
  onCloseEditing?: () => void;
}
interface UploadedFile {
  file: File;
  url: string;
}
const UploadSection: FC<Props> = React.memo(
  ({ uploadLoading, onUploadComment, fileToEdit, onCloseEditing }) => {
    const [comment, setComment] = useState({ html: "", text: "" });
    const { projectWizard } = useProject();
    const projectId = projectWizard?.id;
    const { auth, user }: any = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dropzoneDisclosure = useDisclosure();
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const { errorToast } = useCustomToast();
    const [attachmentUploading, setAttachmentUploading] = useState(false);

    const quillRef = useRef<ReactQuill>(); // Create a ref object

    const clearQuillContent = () => {
      setComment({ text: "", html: "" });
      if (quillRef.current) {
        let quillInstance = quillRef.current.getEditor();
        quillInstance.setText(""); // Clears the editor content
      }
    };

    useEffect(() => {
      if (fileToEdit) {
        onOpen();
      }
    }, [fileToEdit]);
    return (
      <>
        <Stack
          as="form"
          rounded="md"
          spacing={3}
          minW="100%"
          my={3}
          border="1px"
          borderColor="gray.300"
          p="4"
          onSubmit={async (e) => {
            e.preventDefault();
            setAttachmentUploading(true);
            let promises = uploadedFiles.map((file) => {
              return createAttachmentService({
                mediaResponse: file.file,
                mediaDescription: "", // Assuming the description is empty for all files
                projectId: projectId,
                userToken: auth.accessToken,
                folderName: `${process.env.REACT_APP_CLOUDINARY_FOLDER}/${user._id}/${projectId}/1 Client Comments`,
              });
            });

            try {
              const allAttachmentsRes = await Promise.all(promises);

              const { error } = await onUploadComment({
                commentText: comment.text,
                commentHtml: comment.html,
                attachments: allAttachmentsRes.map(
                  (att) => att.data.data.attachment._id
                ),
              });

              if (!!error)
                throw new Error("Something wrong while uploading comment");
            } catch (error) {
              console.log({ error });
              errorToast({ title: error.message });
            } finally {
              clearQuillContent();
              setUploadedFiles([]);
              setAttachmentUploading(false);
            }
          }}
        >
          <Stack spacing={12}>
            <Box minH="10rem">
              <ReactQuill
                ref={quillRef}
                className="my-editor"
                style={{ height: "10rem" }} // Set the height of the editor to match the parent's height (Box)
                theme="snow"
                onChange={(htmlValue) => {
                  const prepareComment = comment;

                  if (quillRef.current) {
                    let quillInstance = quillRef.current.getEditor(); // Get the Quill instance
                    let plainText = quillInstance.getText();

                    prepareComment.text = plainText;
                  }

                  prepareComment.html = htmlValue;

                  setComment(prepareComment);
                }}
              />
            </Box>
            <Box>
              <Wrap>
                {uploadedFiles.map((file: UploadedFile) => {
                  return (
                    <FilePreview
                      fileURL={file.url}
                      fileType={file.file?.type || ""}
                      fileName={file.file.name}
                      allowRemove
                      allowClickToEnlarge
                      onRemove={(url) => {
                        const filteredFiles = uploadedFiles.filter(
                          (file) => file.url !== url
                        );

                        setUploadedFiles(filteredFiles);
                      }}
                    />
                  );
                })}
              </Wrap>
            </Box>
          </Stack>
          <Flex pt={{ base: 20, md: 5 }}>
            <HStack>
              <Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="upload"
                    icon={<BiPlus />}
                    rounded="full"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<BiPlus size={20} />}
                      onClick={() => {
                        dropzoneDisclosure.onOpen();
                      }}
                    >
                      Upload attachment
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={onOpen} icon={<BiEdit size={20} />}>
                      Edit and download
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
            <Spacer />
            <HStack align="right" justify="right" spacing={3}>
              <IconButton
                isLoading={attachmentUploading || uploadLoading}
                colorScheme="blue"
                aria-label="send"
                icon={<BiSend />}
                type="submit"
              />
            </HStack>
          </Flex>
        </Stack>

        {dropzoneDisclosure.isOpen && (
          <Dropzone
            wrapInModal={true}
            onClose={dropzoneDisclosure.onClose}
            onUpload={(files) => {
              if (Array.isArray(files)) {
                setUploadedFiles(
                  files.map((file) => ({
                    file,
                    url: URL.createObjectURL(file),
                  }))
                );
              } else {
                setUploadedFiles([
                  { file: files, url: URL.createObjectURL(files) },
                ]);
              }
              dropzoneDisclosure.onClose();
            }}
          />
        )}

        {isOpen && (
          <ImageEditor
            defaultUploadFile={{
              path: fileToEdit?.secure_url || "",
              name: fileToEdit?.name || "",
            }}
            wrapInModal={true}
            onUpload={async (file) => {
              const convertedFile = new File([file.blobFile], file.fileName, {
                type: file.blobFile.type,
              });

              setUploadedFiles([
                ...uploadedFiles,
                {
                  file: convertedFile,
                  url: URL.createObjectURL(convertedFile),
                },
              ]);

              onClose();
              onCloseEditing();
            }}
            onModalClose={() => {
              onClose();
              onCloseEditing();
            }}
          />
        )}
      </>
    );
  }
);

export default UploadSection;

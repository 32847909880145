/////////////////////
// Logo2 (uses iconColor for words)
/////////////////////
/* Eric: Hide .io
<path fill="#000000" d="M2366.94,376V327.76h22.68V376Z"/>
<path fill="#000000" d="M2440.29,139.26V376h-22.68V139.26Z"/>
<path fill="#000000" d="M2570.68,196.3V317.48c0,40.75-16.66,61.65-50.67,61.65-36.5,0-48.9-23.73-48.9-61.29V196.3c0-39,17.36-60.23,50.32-60.23C2556.15,136.07,2570.68,160.87,2570.68,196.3Zm-76.54-.7V319.25c0,28.35,10.28,39.33,26.93,39.33,17.36,0,26.58-10.62,26.58-40.39V196c0-26.57-9.22-39.33-26.93-39.33C2502.65,156.62,2494.14,168.67,2494.14,195.6Z"/>
*/

// Basic Imports
import { chakra, HTMLChakraProps } from '@chakra-ui/react'

// Design Imports


// Layout and Section Imports


// Data Imports


// Interfaces


// Functions
export const ProgressGuideLogo = (props: HTMLChakraProps<'svg'> & { iconColor?: string }) => {
    const { iconColor = 'currentColor', ...rest } = props
    return (
        <chakra.svg aria-hidden viewBox="0 0 578.86 118.79" fill="none" h="6" flexShrink={0} {...rest}>

            <path
                stroke="currentColor"
                strokeMiterlimit={10}
                d="M35.4,59.2a9.24,9.24,0,0,1,2.71-6.37L52.83,38.11a9.23,9.23,0,0,1,3.87-2.32l3-11.69a20.55,20.55,0,0,0-14.89,6L30.11,44.83a20.38,20.38,0,0,0-4.36,6.45H3.94L24.3,56.53l11.1,2.86h0v-.2Z"
                fill={iconColor}
            />
            <path
                stroke="currentColor"
                strokeMiterlimit={10}
                d="M30.78,74,45.5,88.68A20.38,20.38,0,0,0,52,93v21.81L57.2,94.49l2.87-11.1h0a9.29,9.29,0,0,1-6.52-2.71L38.78,66a9.11,9.11,0,0,1-2.31-3.87l-11.7-3a20.55,20.55,0,0,0,6,14.89Z"
                fill={iconColor}
            />
            <path
                stroke="currentColor"
                strokeMiterlimit={10}
                d="M66,38.11,80.68,52.83A9.05,9.05,0,0,1,83,56.7l11.7,3a20.54,20.54,0,0,0-6-14.89L74,30.11a20.38,20.38,0,0,0-6.45-4.36V3.94L62.26,24.3,59.4,35.4h.26A9.28,9.28,0,0,1,66,38.11Z"
                fill={iconColor}
            />
            <path
                stroke="currentColor"
                strokeMiterlimit={10}
                d="M74.63,88.68,89.35,74a20.38,20.38,0,0,0,4.36-6.45h21.81L95.16,62.26,84.06,59.4h0v.18A9.27,9.27,0,0,1,81.35,66L66.63,80.68A9.15,9.15,0,0,1,62.76,83l-3,11.69a20.5,20.5,0,0,0,14.89-6Z"
                fill={iconColor}
            />
            <path
                stroke="currentColor"
                strokeMiterlimit={10}
                d="M54.88,42.61a6.41,6.41,0,0,1,9,0l5.75,5.75h2.46l-8.21-8.2a6.41,6.41,0,0,0-9,0L40.16,54.88a6.39,6.39,0,0,0-1.74,5.74,6.3,6.3,0,0,1,1.74-3.29L54.88,42.61Zm-10.36,25,8.25,8.25V59.3H44.52ZM74.27,53V50.51l-.22-.21H66V75.91l8.25-8.25V53Zm-19,25.44a5.86,5.86,0,0,0,4.08,1.65h.94l.84-.26a5.89,5.89,0,0,0,2.39-1.39V54.8H55.27V78.41Zm20.94-23.5,2.42,2.42a6.3,6.3,0,0,1,1.74,3.29,6.39,6.39,0,0,0-1.74-5.74l-2.42-2.43v2.46ZM55.27,59.3h-2.5V75.91l2.5,2.5ZM66,54.8h-2.5V78.41l2.5-2.5Z"
                fill={iconColor}
            />
            <path
                d="M141,39.7c12.6,0,20.16,6.84,20.16,17.14S153.64,74,141,74h-3v14.4a1.11,1.11,0,0,1-1.15,1H124a1.09,1.09,0,0,1-1-1V40.78a1.1,1.1,0,0,1,1-1.08Zm.64,21.38a4.25,4.25,0,1,0,0-8.49h-3.74v8.49Z"
                fill={iconColor}
            />
            <path
                d="M205.33,88.23c.36.65-.14,1.15-.79,1.15H189.13a1.87,1.87,0,0,1-1.65-1.15L180,72.53h-.87V88.37a1.11,1.11,0,0,1-1.15,1H165.3a1.09,1.09,0,0,1-1.08-1V40.78a1.1,1.1,0,0,1,1.08-1.08h19c11.09,0,18.43,6.48,18.43,16.27a15.21,15.21,0,0,1-8.86,13.76Zm-22-27.94c2.45,0,4.11-1.51,4.11-3.81s-1.66-3.82-4.11-3.82h-4.25v7.63Z"
                fill={iconColor}
            />
            <path
                d="M232.91,90.39c-15.34,0-25.56-10.37-25.56-25.85s10.22-26,25.56-26,25.56,10.37,25.56,26S248.24,90.39,232.91,90.39Zm0-37.3C227,53.09,223,57.7,223,64.54S227,76,232.91,76s9.86-4.61,9.86-11.45S238.81,53.09,232.91,53.09Z"
                fill={iconColor}
            />
            <path
                d="M305.41,58.92a1.1,1.1,0,0,1,1.08,1v22.9a2.38,2.38,0,0,1-.86,1.73c-4.32,3.67-10.95,5.83-17.86,5.83-15.55,0-25.92-10.37-25.92-25.92s10.37-25.92,25.92-25.92a29.75,29.75,0,0,1,15.12,4.17,1.12,1.12,0,0,1,.65,1.3l-3.6,10.58a1.1,1.1,0,0,1-1.37.58,22.79,22.79,0,0,0-10.37-2.52c-6.62,0-11.09,4.75-11.09,11.81s4.47,12,11.09,12c2.67,0,4.47-.5,5.26-1.37V70.88h-6.63c-.64,0-1.08-.29-1.15-.79l-.93-9.94a1,1,0,0,1,1.15-1.23ZM293.89,74v0Z"
                fill={iconColor}
            />
            <path
                d="M352.36,88.23c.36.65-.15,1.15-.8,1.15H336.15a1.88,1.88,0,0,1-1.65-1.15L327,72.53h-.86V88.37a1.12,1.12,0,0,1-1.16,1H312.32a1.09,1.09,0,0,1-1.08-1V40.78a1.1,1.1,0,0,1,1.08-1.08h19c11.09,0,18.43,6.48,18.43,16.27a15.2,15.2,0,0,1-8.85,13.76Zm-22-27.94c2.45,0,4.11-1.51,4.11-3.81s-1.66-3.82-4.11-3.82h-4.24v7.63Z"
                fill={iconColor}
            />
            <path
                d="M389.58,76.42a1,1,0,0,1,1.08.94v11a1.1,1.1,0,0,1-1.08,1H356.1c-.51,0-1-.57-1.16-1.08V40.71a.91.91,0,0,1,1-1h32.76a1.12,1.12,0,0,1,1.16,1V51.58a1.08,1.08,0,0,1-1.16,1.08H369.92v5.69h15.27a.86.86,0,0,1,.93.93l-1,10.09a1.25,1.25,0,0,1-1.22.93h-14v6.12Z"
                fill={iconColor}
            />
            <path
                d="M398.65,74.26c.21-.72.72-.93,1.29-.57a27.23,27.23,0,0,0,10,3.45c2.31,0,3.82-1.08,3.82-2.73,0-4.25-20.24-4-20.24-19.73,0-9.65,7.28-16.06,18.22-16.06a31.25,31.25,0,0,1,16.13,5.18c.58.36.79.94.58,1.3l-4.54,10.15c-.22.72-.72.94-1.3.58-3.52-2-7.63-3.53-10.15-3.75-2.23,0-3.67,1-3.67,2.6,0,4.24,20.38,4,20.38,19.8,0,9.57-7.42,16-18.51,16-6.05,0-12.38-1.87-16-4.75-.58-.43-.8-.93-.58-1.3Z"
                fill={iconColor}
            />
            <path
                d="M437.17,74.26c.21-.72.72-.93,1.29-.57a27.11,27.11,0,0,0,10,3.45c2.31,0,3.82-1.08,3.82-2.73,0-4.25-20.24-4-20.24-19.73,0-9.65,7.28-16.06,18.22-16.06A31.27,31.27,0,0,1,466.4,43.8c.58.36.79.94.58,1.3l-4.54,10.15c-.22.72-.72.94-1.3.58-3.52-2-7.63-3.53-10.15-3.75-2.23,0-3.67,1-3.67,2.6,0,4.24,20.38,4,20.38,19.8,0,9.57-7.42,16-18.51,16-6.05,0-12.38-1.87-16-4.75-.58-.43-.8-.93-.58-1.3Z"
                fill={iconColor}
            />
            <path
                d="M491.38,89.38h-3.67a38,38,0,0,1-.29-4.32c-1.15,3.67-3.89,5-7.05,5-6.56,0-8.72-4.9-8.72-12.46V53.16c0-7.92,3.24-12.52,10-12.52,8,0,9.65,5.61,9.65,12v1.15H486.7V52.52c0-3.6-.36-7.71-5-7.71-3.53,0-5.33,2.23-5.33,8.07V77.72c0,4.89,1.3,8.21,5,8.21,4.68,0,5.47-4.4,5.47-10.88V67.28h-5.61v-4h10.22Z"
                fill={iconColor}
            />
            <path
                d="M502.11,41.28V78.87c0,4,1.44,6.91,5.18,6.91s5.19-2.59,5.19-6.91V41.28h4.68V78.73c0,7.41-3.39,11.3-9.94,11.3s-9.72-3.67-9.72-11.16V41.28Z"
                fill={iconColor}
            />
            <path
                d="M528.75,41.28v48.1h-4.61V41.28Z"
                fill={iconColor}
            />
            <path
                d="M536,41.28h8.42c10.37,0,11.45,6.7,11.45,14V74.41c0,7.41-1.15,15-11.88,15h-8Zm4.61,44.07h3c6.05,0,7.56-3.31,7.56-9.94V54.68c0-5.84-1.08-9.36-7.49-9.36h-3.09Z"
                fill={iconColor}
            />
            <path
                d="M577.42,65.77h-10.8V85.21h12.24l-.65,4.17H562V41.28h16.06v4.25H566.62V61.59h10.8Z"
                fill={iconColor}
            />



        </chakra.svg>
    )
}
/*"currentColor"*/
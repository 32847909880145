import { Center, Icon } from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";

const PlaceholderImage = () => (
  <Center bg="gray.200" w="full" h="full">
    <Icon fontSize="4xl" color="gray.500" as={MdImageNotSupported} />
  </Center>
);

export default PlaceholderImage;

import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { OrderMessage } from "types/message.types";

export async function getOrderMessages(
  orderId: string,
  accessToken: string
): Promise<OrderMessage[]> {
  const response = await axios.get(`${API_URL}/orders/${orderId}/messages`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.messages;
}

export async function createOrderMessage(
  body: Partial<Omit<OrderMessage, "attachments"> & { attachments: string[] }>,
  accessToken: string
): Promise<OrderMessage> {
  const response = await axios.post(`${API_URL}/messages`, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.data.message;
}

import { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { Attachment } from "types/attachments.types";
import { AttachmentToUpdateState, UploadedFile } from "types/gallery.types";
import useCustomToast from "./useCustomToast";
import {
  createMultipleAttachments,
  deleteMultipleAttachments,
  updateMultipleAttachments,
} from "services/attachmentsService";
import useAccessToken from "./useAccessToken";
import { convertUrlToFile } from "utils/convertUrlToFile";

type UseGalleryFormProps = {
  defaultData?: Attachment[];
  resourceId?: string;
  resourceType?: string;
};
const useGalleryForm = ({
  defaultData,
  resourceId,
  resourceType,
}: UseGalleryFormProps) => {
  const dropzoneDisclosure = useDisclosure();
  const accessToken = useAccessToken();

  const prevData =
    defaultData?.map((att) => ({
      id: att.id,
      url: att.cdnUrl,
      name: att.name,
      format: att.format,
      isFeatured: att.featured,
    })) || [];
  const [prevUploadedFiles, setPrevUploadedFiles] =
    useState<UploadedFile[]>(prevData);
  const { errorToast, successToast } = useCustomToast();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [attachmentsToUpdate, setAttachmentsToUpdate] = useState<
    AttachmentToUpdateState[]
  >([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<string[]>([]);
  const areUnsavedChanges =
    uploadedFiles.length > 0 ||
    attachmentsToDelete.length > 0 ||
    attachmentsToUpdate.length > 0;

  useEffect(() => {
    if (!prevData) return;
    setPrevUploadedFiles(prevData);
  }, [defaultData]);

  useEffect(() => {
    const intersected = _.intersectionBy(prevUploadedFiles, prevData, "id");
    const filteredData = intersected.filter(
      (file) =>
        !_.isEqual(
          file,
          prevData.find((f) => f.id === file.id)
        )
    );

    setAttachmentsToUpdate(filteredData);
  }, [prevUploadedFiles, attachmentsToDelete]);

  const handleSave = async () => {
    let finalAttachments: string[] = prevUploadedFiles
      .filter((file) => !attachmentsToDelete.includes(file.id)) // Filter out deleted attachments
      .map((file) => file.id); // Start with current attachments minus deleted ones

    let deletedAttachments: Attachment[] = [];
    let updatedAttachments: Attachment[] = [];
    let uploadedAttachments: Attachment[] = [];

    if (attachmentsToDelete.length > 0) {
      const deleted = await deleteAttachments();
      if (deleted) {
        deletedAttachments = deleted;
      }
    }
    if (attachmentsToUpdate.length > 0) {
      const updated = await updateAttachments();
      if (updated) {
        updatedAttachments = updated;
      }
    }
    if (uploadedFiles.length > 0) {
      const uploaded = await uploadFiles();
      if (uploaded) {
        uploadedAttachments = uploaded;
        finalAttachments = [
          ...finalAttachments,
          ...uploaded.map((att) => att.id),
        ];
      }
    }

    return {
      deletedAttachments,
      updatedAttachments,
      uploadedAttachments,
      finalAttachments,
    };
  };

  // Helper function to delete attachments
  const deleteAttachments = async (): Promise<Attachment[] | undefined> => {
    try {
      setIsUploading(true);
      const { success, attachments } = await deleteMultipleAttachments(
        attachmentsToDelete,
        accessToken
      );
      if (!success) throw new Error("");
      setAttachmentsToDelete([]);
      successToast({ title: "Deleted files successfully!" });
      return attachments;
    } catch (error) {
      errorToast({
        title: "Failed to update attachments!",
        description: "Something went wrong during the update.",
      });
      return undefined;
    } finally {
      setIsUploading(false);
    }
  };

  // Helper function to update attachments
  const updateAttachments = async (): Promise<Attachment[] | undefined> => {
    try {
      setIsUploading(true);
      const filesToUpdate = attachmentsToUpdate.map((file) => ({
        attachmentId: file.id,
        body: { featured: file.isFeatured },
      }));

      const updateAttachments = await updateMultipleAttachments(
        filesToUpdate,
        accessToken
      );

      successToast({ title: "Files updated successfully!" });
      return updateAttachments;
    } catch (error) {
      errorToast({
        title: "Failed to update attachments!",
        description: "Something went wrong during the update.",
      });
      return undefined;
    } finally {
      setIsUploading(false);
    }
  };

  // Helper function to upload new files
  const uploadFiles = async (): Promise<Attachment[] | undefined> => {
    try {
      setIsUploading(true);
      const attachmentsIntoFilesPromises = uploadedFiles.map((item) =>
        convertUrlToFile(item.url, item.name, item.format)
      );

      const files = await Promise.all(attachmentsIntoFilesPromises);
      const validFiles = files.filter((file): file is File => file !== null);

      const { success, attachments } = await createMultipleAttachments(
        validFiles.map((file, index) => ({
          mediaResponse: file,
          folderName: "Homerender/Talent",
          resourceId,
          isFeatured: index === uploadedFiles.findIndex((f) => !!f.isFeatured),
        })),
        accessToken,
        resourceType
      );
      if (!success) throw new Error("");

      setUploadedFiles([]);
      successToast({ title: "Uploaded files successfully!" });

      return attachments;
    } catch (error) {
      errorToast({
        title: "Unable to upload file!",
        description: "Something went wrong while uploading attachment.",
      });

      return undefined;
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveAttachment = (fileId: string) => {
    const isPrevUploaded = prevUploadedFiles.some((data) => data.id === fileId);
    const isCurrUploaded = uploadedFiles.some((data) => data.id === fileId);

    if (isPrevUploaded) {
      setAttachmentsToDelete((prevState) => [...prevState, fileId]);
      setPrevUploadedFiles((prevState) => {
        const updatedFiles = prevState.filter((prev) => prev.id !== fileId);
        // Mark the first remaining file as featured if the current featured file is removed
        const hasFeaturedFile = updatedFiles.some((f) => f.isFeatured);
        if (!hasFeaturedFile && updatedFiles.length > 0) {
          updatedFiles[0].isFeatured = true;
        }
        return updatedFiles;
      });
    }

    if (isCurrUploaded) {
      setUploadedFiles((prevState) => {
        const remainingFiles = prevState.filter((file) => file.id !== fileId);
        // Mark the first remaining file as featured if the current featured file is removed
        const hasFeaturedFile = remainingFiles.some((f) => f.isFeatured);
        if (!hasFeaturedFile && remainingFiles.length > 0) {
          remainingFiles[0].isFeatured = true;
        }
        return remainingFiles;
      });
    }
  };

  const handleFileUpload = (files: File[] | File) => {
    const isFeaturedAlready = [...prevUploadedFiles, ...uploadedFiles].some(
      (file) => file.isFeatured
    );

    if (Array.isArray(files)) {
      setUploadedFiles([
        ...uploadedFiles,
        ...files.map((file: File, index) => ({
          id: URL.createObjectURL(file),
          name: file.name,
          url: URL.createObjectURL(file),
          format: file.type,
          isFeatured: isFeaturedAlready ? false : index === 0, // If not already featured then mark first file as featured
        })),
      ]);
    } else if (files) {
      const url = URL.createObjectURL(files);
      setUploadedFiles([
        ...uploadedFiles,
        {
          id: url,
          name: files.name,
          url,
          format: files.type,
          isFeatured: isFeaturedAlready ? false : true, // If not already featured then mark first file as featured
        },
      ]);
    } else {
      errorToast({
        title: "Unable to upload file!",
        description: "Something went wrong while uploading attachment.",
      });
    }
    dropzoneDisclosure.onClose();
  };

  const handleMarkFeatured = (attachmentId: string) => {
    // First, ensure only one attachment is marked as featured
    const updatedPrev = prevUploadedFiles.map((file) => ({
      ...file,
      isFeatured: file.id === attachmentId,
    }));
    const updatedCurrent = uploadedFiles.map((file) => ({
      ...file,
      isFeatured: file.id === attachmentId,
    }));

    setPrevUploadedFiles(updatedPrev);
    setUploadedFiles(updatedCurrent);
  };

  const renderUnsavedChangesText = (): string => {
    if (areUnsavedChanges) {
      if (attachmentsToDelete.length > 0 && uploadedFiles.length > 0)
        return `Unsaved changes (${attachmentsToDelete.length} deletes & ${uploadedFiles.length} uploads)`;
      if (uploadedFiles.length > 0)
        return `Unsaved changes (${uploadedFiles.length} uploads)`;
      if (attachmentsToDelete.length > 0)
        return `Unsaved changes (${attachmentsToDelete.length} deletes)`;
    }
    return "";
  };

  return {
    // isLoading,
    uploadedFiles,
    prevUploadedFiles,
    attachmentsToUpdate,
    dropzoneDisclosure,
    attachmentsToDelete,
    areUnsavedChanges,
    isUploading,
    unsavedChangesText: renderUnsavedChangesText(),
    handleSave,
    setUploadedFiles,
    errorToast,
    handleRemoveAttachment,
    handleFileUpload,
    handleMarkFeatured,
  };
};

export default useGalleryForm;

/////////////////////
// RadioPricePage Page
/////////////////////

// Basic Imports
//import * as React from "react";

// Design Imports
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import { RadioCard, RadioCardGroup } from "./RadioCards";
import { PricingCard } from "./PricingCards";
import { PlanSummary } from "./PlanSummary";

// Data Imports

// Custom Hooks and Services
import { fetchAllStripePlans } from "services/stripeService";
import useAuth from "hooks/useAuth";
import { RootStateOrAny, useSelector } from "react-redux";

//import { setStripePlans } from "app/planReducer";
import useStripe from "hooks/useStripe";
import React from "react";
import _ from "lodash";
import { LIVE_FEATURES } from "settings/site-credentials";
import {
  priceSaving,
  pricingDescription,
  pricingFeatures,
} from "data/pricingData";
import { FaCheckCircle } from "react-icons/fa";

// Interfaces

// Functions
export const RadioPricePage = (props) => {
  // const planDuration = useSelector(
  //   (state: RootStateOrAny) => state.plan.planDuration
  // );
  const currencyType = useSelector(
    (state: RootStateOrAny) => state.currency.currencyType
  );
  const selectedPlanPrice = useSelector(
    (state: RootStateOrAny) => state.plan.selectedPlanPrice
  );
  const planType = useSelector((state: RootStateOrAny) => state.plan.planType);
  const selectedPlanId = useSelector(
    (state: RootStateOrAny) => state.plan.selectedPlanId
  );
  const currentPlan = useSelector(
    (state: RootStateOrAny) => state.plan.currentPlan
  );
  const [activePlans, setActivePlans] = React.useState<Array<any>>(null);
  const { auth }: any = useAuth();
  //const dispatch = useDispatch();
  const {
    plans,
    isLoading,
  }: // error
  any = useStripe(() => fetchAllStripePlans(auth.accessToken));

  React.useEffect(() => {
    if (plans.length > 0) {
      if (currencyType === "USD") {
        // @ts-ignore
        setActivePlans(plans.filter((p) => p.currency === "usd"));
      } else if (currencyType === "CAD") {
        // @ts-ignore
        setActivePlans(plans.filter((p) => p.currency === "cad"));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, currencyType]);
  console.log({ LIVE_FEATURES });
  return (
    <Box as="section" bg="bg-surface">
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <Container maxW="100%">
          {!LIVE_FEATURES && (
            <Box className="samFeatures">
              <Box
                bg={mode("gray.800", "gray.700")}
                px={{ base: 5, md: 20 }}
                py={5}
                borderRadius="2xl"
                my={5}
              >
                <Heading
                  fontSize="xl"
                  mb="4"
                  fontWeight="bold"
                  textAlign="center"
                  color="white"
                >
                  Features
                </Heading>
                <SimpleGrid
                  columns={{ base: 1, lg: 4 }}
                  spacing={{ base: "5", lg: "5" }}
                  my={5}
                >
                  {pricingFeatures.map((feature, idx) => (
                    <Box
                      key={idx}
                      display="flex"
                      alignItems="start"
                      color="white"
                      gap="2"
                    >
                      <Box mt={1} as={FaCheckCircle} color="white.400" />
                      {feature}
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            </Box>
          )}
          <RadioCardGroup
            defaultValue={currentPlan}
            spacing="4"
            plans={activePlans}
          >
            {activePlans &&
              activePlans.map((plan, index) => {
                return (
                  <RadioCard
                    defaultValue={"0"}
                    key={index}
                    value={index}
                    p={2}
                    borderRadius="sm"
                  >
                    <PricingCard
                      color="black"
                      data={{
                        popular: index === 0 ? true : false,
                        name: LIVE_FEATURES
                          ? plan.statement_descriptor
                          : plan.duration + " plan",
                        description: LIVE_FEATURES
                          ? plan.name
                          : pricingDescription[index],
                        price: plan.price,
                        duration: plan.duration,
                        benefits: plan?.description?.split(",") || "",
                        save: LIVE_FEATURES ? 0 : priceSaving[index],
                      }}
                    />
                  </RadioCard>
                );
              })}
          </RadioCardGroup>
          {selectedPlanPrice && selectedPlanId ? (
            <PlanSummary
              value={selectedPlanPrice}
              productId={selectedPlanId}
              planType={planType}
            ></PlanSummary>
          ) : (
            <></>
          )}
        </Container>
      )}
    </Box>
  );
};

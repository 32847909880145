/////////////////////
// Form / Auth Layout Page
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Heading,
  Stack,
  useBreakpointValue,
  Text,
  Button,
} from "@chakra-ui/react";
import { Logo } from "components/ui/logo/Logo2";
import { Link } from "react-router-dom";

// Layout and Section Imports

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Functions

// Hooks

// Interfaces
interface Props {
  title: string;
  description?: string;
  showLogo?: boolean;
  link?: {
    label: string;
    to: string;
  };
}
const FormHeader: FC<Props> = ({ title, showLogo = true }) => {
  return (
    <Stack spacing="6">
      {showLogo ? <Logo h={10} /> : null}
      <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
        <Stack
          backgroundSize="cover"
          rounded="xl"
          position="relative"
          bg="#1A202C"
          p={4}
          my={4}
        >
          <Text
            fontSize="sm"
            fontWeight="medium"
            textAlign="center"
            color="gray.200"
          >
            This authentication page is exclusively for our customers. If you
            are a talent, please visit{" "}
            <Button
              as={Link}
              to={ROUTES.TALENT_PORTAL}
              variant="link"
              colorScheme="blue"
            >
              HomeRender Talent
            </Button>
          </Text>
        </Stack>

        <Heading size={useBreakpointValue({ base: "xs", md: "sm" })} mt={4}>
          {title}
        </Heading>
      </Stack>
    </Stack>
  );
};

export default FormHeader;

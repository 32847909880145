/////////////////////
// Completed Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";

// Data Imports
import { fetchCompletedProjectsAxiosRequest } from "services/projectsService";
import useFetchProjects from "hooks/useFetchProjects";
import { REACT_QUERY_KEYS } from "settings/constants/queryKeys";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { TABLE_NAME } from "settings/constants/QueryTable";

// Functions

const CompletedProjects: FC = () => {
  const { auth, userLoading: isAuthLoading }: any = useAuth();
  const { projects, isLoading, onChangePriorities, setProjects } =
    useFetchProjects(REACT_QUERY_KEYS.COMPLETED_PROJECTS, () =>
      fetchCompletedProjectsAxiosRequest(auth?.accessToken || "")
    );

  if (!!isLoading || !!isAuthLoading) return <SkeletonLoading />;
  return (
    <Fragment>
      <DragDropTable
        columns={columns({ tableName: TABLE_NAME.COMPLETED_TABLE })}
        data={projects}
        setData={setProjects}
        updateDataOnPriority={onChangePriorities}
      />
    </Fragment>
  );
};

export default CompletedProjects;

/////////////////////
// Auth Service
// HC is just a crypto generated string which tells the react component that the payment is done or canceled
// to show up the modal when a user click cancel on stripe payment page or subscribed as stripe page is
// totally stateless so we have to query out the params
/////////////////////

// Basic Imports
import { store } from "app/store";
import axios from "axios";
import {
  API_URL,
  AUTHORIZED_TOKEN,
  CUSTOMER_EMAIL,
  CUSTOMER_HC,
  CUSTOMER_TOKEN_NAME,
} from "settings/site-credentials";

// Design Imports

// Layout and Section Imports

// Data Imports

// Functions

// Custom Hooks and Services

// Interfaces
interface SignupType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  // role: "admin" | "member" | "customer"
}

interface SigninTypes {
  email: string;
  password: string;
}

interface ResetTypes {
  email: string;
}
interface VerificationTypes {
  token: string;
}
interface SendVerificationTypes {
  authToken: string;
  email: string;
}
interface ResetPasswordTypes {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

// Functions
export const getUserAccountDetails = (authToken?: string) =>
  axios.get(`${API_URL}/users/account`, {
    headers: { Authorization: `Bearer ${authToken || AUTHORIZED_TOKEN}` },
  });

export async function signin({ email, password }: SigninTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/login`,
      JSON.stringify({ email, password }),
      {
        headers: { "Content-Type": "application/json" },
        // withCredentials: true,
      }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}
export async function signinWithGoogle() {
  try {
    const response = await axios.get(`${API_URL}/auth/google/`);
    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}
export async function verification({ token }: VerificationTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/account/verify`,
      {
        verificationToken: token,
      },
      {
        headers: { "Content-Type": "application/json" },
        // withCredentials: true,
      }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}
export async function sendVerificationEmail({
  email,
  authToken,
}: SendVerificationTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/account/verify/send-email`,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message:
            "There was an error communicating with the server! Please try again later.",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials!" } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}

export async function resetPassword({
  token,
  newPassword,
  confirmPassword,
}: ResetPasswordTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/account/password/reset/`,
      JSON.stringify({
        passwordResetToken: token,
        newPassword,
        confirmPassword,
      }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}
export async function forgotPassword({ email }: ResetTypes) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/account/password/reset/send-email`,
      JSON.stringify({ email }),
      {
        headers: { "Content-Type": "application/json" },
        // withCredentials: true,
      }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}

export async function signup({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}: SignupType) {
  try {
    const response = await axios.post(
      `${API_URL}/auth/signup`,
      JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        role: "customer",
        source: "HR_APP",
      }),
      { headers: { "Content-Type": "application/json" } }
    );

    return { response };
  } catch (err) {
    if (!err.response)
      return {
        error: {
          statusCode: 500,
          message: "No server response, please try again later",
        },
      };
    if (err.response?.status === 400)
      return { error: { statusCode: 400, message: "Invalid credentials..." } };
    return { error: { statusCode: 500, message: "Something went wrong..." } };
  }
}

export function storeCustomerTokenIntoLocalStorage(token: string) {
  localStorage.setItem(CUSTOMER_TOKEN_NAME, JSON.stringify(token));
}
export function getCustomerTokenFromLocalStorage() {
  const token = localStorage.getItem(CUSTOMER_TOKEN_NAME);
  if (!token) return "";

  return JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_NAME));
}
export function removeCustomerTokenFromLocalStorage() {
  localStorage.removeItem(CUSTOMER_TOKEN_NAME);
}

export function storeCustomerEmailIntoLocalStorage(token: string) {
  localStorage.setItem(CUSTOMER_EMAIL, JSON.stringify(token));
}
export function getCustomerEmailIntoLocalStorage() {
  const email = localStorage.getItem(CUSTOMER_EMAIL);
  if (!email) return "";

  return JSON.parse(localStorage.getItem(CUSTOMER_EMAIL));
}
export function removeCustomerEmailIntoLocalStorage() {
  localStorage.removeItem(CUSTOMER_EMAIL);
}

export function storeHcIntoLocalStorage(hc: string) {
  localStorage.setItem(CUSTOMER_HC, JSON.stringify(hc));
}
export function getHcIntoLocalStorage() {
  const hc = localStorage.getItem(CUSTOMER_HC);
  if (!hc) return "";

  return JSON.parse(localStorage.getItem(CUSTOMER_HC));
}
export function removeHcIntoLocalStorage() {
  localStorage.removeItem(CUSTOMER_HC);
}

//Method to logout user from app by clearing local-storage
export const handleLogout = async () => {
  // Remove token from localStorage
  removeCustomerTokenFromLocalStorage();
  removeCustomerEmailIntoLocalStorage();
  store.dispatch({ payload: null, type: "RESET" });
  setTimeout(() => {
    window.location.replace("/login");
  }, 2000);
};

import { FC } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import ActivityAccordion from "../RenderActivity/ActivityAccordion";
import ActivityAttachments from "./ActivityAttachments";
import useOrderDeliveries from "hooks/useOrderDeliveries";
import { OrderActivity } from "types/order.types";
import Alert from "components/features/Alert";

interface Props {
  activity: OrderActivity;
  orderId: string;
}
const DeliveryActivity: FC<Props> = ({ activity, orderId }) => {
  return (
    <ActivityAccordion activity={activity}>
      <DeliveryActivityContent
        orderId={orderId}
        resourceId={activity.resourceId}
      />
    </ActivityAccordion>
  );
};

const DeliveryActivityContent = ({
  orderId,
  resourceId,
}: { orderId: string } & Pick<
  OrderActivity,
  "resourceId" | "resourceType"
>) => {
  const { isLoading, data: orderDeliveries } = useOrderDeliveries(orderId);
  const delivery = orderDeliveries?.find(
    (delivery) => delivery._id === resourceId
  );

  if (!!isLoading) return <Spinner />;
  if (!delivery)
    return (
      <Alert
        status="error"
        description="Delivery not found! Try hard reload or contact support"
      />
    );
  return (
    <Stack>
      <Card variant="outline" size="sm" overflow="hidden">
        <CardHeader bg="gray.100" py={2}>
          <Text fontWeight="bold">{delivery.title}</Text>
        </CardHeader>
        <CardBody>
          <Stack spacing={6}>
            <Stack>
              <Text>{delivery.description}</Text>
            </Stack>
            <ActivityAttachments attachmentIds={delivery.attachments} />
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default DeliveryActivity;

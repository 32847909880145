// Basic Imports
import axios from "axios";
import { useQuery } from "react-query";
import { API_URL } from "settings/site-credentials";

// Customer Hooks
import useAuth from "./useAuth";

const useBillingInvoices = (customerId: string) => {
  const { token }: any = useAuth();
  const invoicesQuery = useQuery(
    ["invoices", customerId],
    () =>
      axios.get(`${API_URL}/subscriptions/invoices/${customerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    { enabled: !!customerId && !!token }
  );

  return {
    ...invoicesQuery,
    invoices: invoicesQuery?.data?.data?.data?.invoices || [],
  };
};

export default useBillingInvoices;

import { FC } from "react";
import {
  Avatar,
  Card,
  CardBody,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { formatDistanceToNowStrict } from "date-fns";
import { OrderRating } from "types/rating.types";
import Ratings from "components/ui/common/Ratings";

interface Props {
  rating: OrderRating;
}
const ReviewCard: FC<Props> = ({ rating }) => {
  const createdDate = new Date(rating.createdAt);
  const reviewerName =
    rating.createdBy.fullName ||
    `${rating.createdBy.firstName} ${rating.createdBy.lastName}`;

  return (
    <Card variant="outline">
      <CardBody>
        <Stack spacing={4}>
          <Flex gap={2}>
            <Avatar src={rating.createdBy.avatar} />
            <Stack spacing={0}>
              <Text fontWeight="semibold">{reviewerName}</Text>
              <Text>United States</Text>
            </Stack>
          </Flex>
          <HStack>
            <Ratings size="xs" rating={rating.average} showDigits />
            <Divider
              orientation="vertical"
              height="15px"
              borderColor="gray.400"
            />
            <Text>
              {formatDistanceToNowStrict(createdDate, { addSuffix: true })}
            </Text>
          </HStack>
          <Text>{rating.text}</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ReviewCard;

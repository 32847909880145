/////////////////////
// Forgot (Password) Form
/////////////////////

// Basic Imports
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import { Button, Stack, StackProps, Text } from "@chakra-ui/react";

import FormAlert from "../FormAlert";

// Layout and Section Imports
import useFormState, { ForgotFormTypes } from "../useAuthForm";
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import FormInputField from "components/forms/ReactHookForm/FormInputField";

// Interfaces

// Custom Hooks and Services

// Functions

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

export const ForgotForm = (props: StackProps) => {
  const methods = useForm<ForgotFormTypes>({ resolver: yupResolver(schema) });
  const { user, emailSentLoading, handleForgotPassword, isEmailSentForReset } =
    useFormState();

  if (!!isEmailSentForReset)
    return (
      <Stack>
        <FormAlert
          title="Forgot password"
          description="Please check you email, you have to click on link to redirect for reset password"
        />
        <Text textAlign="center">
          Did't you receive reset password email yet ?
          <Text
            cursor="pointer"
            userSelect="none"
            textDecor="underline"
            fontStyle="italic"
            as="span"
            mx="2"
            onClick={() =>
              handleForgotPassword({ email: methods.getValues()["email"] })
            }
          >
            {emailSentLoading ? "Sending..." : "Resend"}
          </Text>
        </Text>
      </Stack>
    );
  return (
    <Stack spacing="8" {...props}>
      <FormHeader title="Lost your password?" />

      <FormProvider {...methods}>
        <FormWrapper onSubmit={methods.handleSubmit(handleForgotPassword)}>
          <Stack spacing="5">
            <FormInputField
              type="email"
              name="email"
              label="Email"
              placeholder="e.g., john@email.com"
            />
          </Stack>
          <Stack spacing="4">
            <Button
              variant="primary"
              color="white"
              bg="blue.500"
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={user.isLoading}
            >
              Reset Password
            </Button>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </Stack>
  );
};

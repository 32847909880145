export function formatCurrency(
  amount: number,
  currency?: string,
  excludeDecimals: boolean = false
): string {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: currency || "USD",
    maximumFractionDigits: excludeDecimals ? 0 : 2,
  }).format(amount);
}

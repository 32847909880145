/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { FaRegImage } from "react-icons/fa";
// import { HiHashtag } from "react-icons/hi";
// import { GiResize } from "react-icons/gi";
import { MdDriveFolderUpload } from "react-icons/md";
import _ from "lodash";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  task: any;
  index: number;
}

// Functions

const Task: FC<Props> = ({ task, index }) => {
  return (
    <VStack mt={index == 0 ? 0 : 5} alignItems="left" w="full">
      <HStack spacing="2" w="full">
        <VStack
          w="full"
          spacing="4"
          p={4}
          borderRadius="xl"
          borderColor={"gray.200"}
          borderWidth="2px"
        >
          <HStack w="full" justifyContent="space-between">
            <HStack spacing="2">
              <Box>
                <FaRegImage size="20" color="green" />
              </Box>
              <Text noOfLines={1}>{task?.name}</Text>
            </HStack>
            {/* <HStack spacing="2">
              <Box>
                <HiHashtag size="20" color="#526bd8" />
              </Box>
              <Text>{task?.quantity}</Text>
            </HStack>
            <HStack spacing="2">
              <Box>
                <GiResize size="18" color="#6a30de" />
              </Box>
              <Text>{task?.imageSize}</Text>
            </HStack> */}
            <HStack spacing="2">
              <Box>
                <MdDriveFolderUpload size="20" color="#3182ce" />
              </Box>
              <Text>{task?.stage}</Text>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Task;

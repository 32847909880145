import { Spinner, Container } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Container centerContent py="10">
      <Spinner />
    </Container>
  );
};

export default Loader;

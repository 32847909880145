import axios from "axios";
import { API_URL } from "settings/site-credentials";
import { Category } from "types/gigCategory";

export async function getCategories(token: string): Promise<Category[]> {
  const { data } = await axios.get(`${API_URL}/categories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data.data.categories;
}
